import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { Link, Navigate, useLocation } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { goToPrevContactUtilities, goToNextContactUtilities, goToFirstContactUtilities, goToLastContactUtilities, openContactUtilities } from '../utilities/PagingUtilities.js';

// import components
import ProfileDenominationTile from '../tiles/ProfileDenominationTile.js';
import ReturnToSearchResultsButton from '../components/ReturnToSearchResultsButton.js';

class DenominationProfile extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);

        this.state = {
            mainContactId: 0,
            contactIdPosition: 0,
            contactIdsArray: ([]),
        }
    }

    componentDidMount(){
        //this.setActualSection("churches");

        const pathname = this.props.location.pathname;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }
        if(Number.isInteger(Number(id)))this.setState({ mainContactId: parseInt(id) });

        try {
            const getIdsArray = localStorage.getItem('idsArray');
            const idsArray = JSON.parse(getIdsArray);
            this.setState({ contactIdPosition: idsArray.indexOf(parseInt(id))+1 });
            this.setState({ contactIdsArray: idsArray });
        }
        catch(err){
            alert("Error");
        }
    }

    render() {
        return(
                <div className="verticalStack flex" style={{height: "calc(100vh - 80px)", overflowY: "scroll"}}>
                    <div className="horizontalStackCenter myPeopleTableListingBar">
                        <ReturnToSearchResultsButton position={this.state.contactIdPosition} />
                        <div style={{display: this.state.contactIdPosition != 0 ? "" : "none", marginLeft: "40px"}}>
                            <span className="fontPoppinsSemiBold16 clickable onHoverToOrange" onClick={() => goToFirstContactUtilities(this.state.contactIdPosition-1, this.state.contactIdsArray)}>{"<<"}</span>
                            <span className="fontPoppinsSemiBold16 clickable onHoverToOrange" onClick={() => goToPrevContactUtilities(this.state.contactIdPosition-1, this.state.contactIdsArray)} style={{paddingLeft: "20px"}}>{"<"}</span>
                            <span className="fontPoppinsSemiBold16" style={{paddingLeft: "20px"}}>{this.state.contactIdPosition}{"/"}{this.state.contactIdsArray.length}</span>
                            <span className="fontPoppinsSemiBold16 clickable onHoverToOrange" onClick={() => goToNextContactUtilities(this.state.contactIdPosition-1, this.state.contactIdsArray)} style={{paddingLeft: "20px", paddingRight: "20px"}}>{">"}</span>
                            <span className="fontPoppinsSemiBold16 clickable onHoverToOrange" onClick={() => goToLastContactUtilities(this.state.contactIdPosition-1, this.state.contactIdsArray)}>{">>"}</span>
                        </div>
                        <div className="flex"/>
                        <span className="fontPoppinsRegular13Gray onHoverToOrange clickable">{"Show Tile Menu"}</span>
                    </div>

                    <div className="horizontalStack">

                        <div className="verticalStack flex" style={{paddingRight: 10}}>
                            <ProfileDenominationTile onCloseOrOpen={this.setActualOpenedTile} />
                        </div>

                    </div>
                </div>
        );
    }
}

const withLocation = Component => props => {
    const location = useLocation();

    return <Component {...props} location={location} />;
};

export default withLocation(DenominationProfile);