import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import Axios from 'axios';

// import utilities
import { moreRecordsOnPageUtilities, lessRecordsOnPageUtilities, goToPrevPageUtilities, goToNextPageUtilities, goToFirstPageUtilities, goToLastPageUtilities, checkIfOutOfPages } from '../utilities/PagingUtilities.js';
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import MyConversationsTile from '../tiles/MyConversationsTile.js';

function storageEventHandler() {
    this.setState({ recordsOnOnePage: localStorage.getItem("recordsOnOnePageMyConversations") || 25 });
    this.setState({ actualPage: localStorage.getItem("actualPageMyConversations") || 0 });
    this.setState({ sortBy: localStorage.getItem("sortByMyConversations") || 0 });
    this.setState({ sortTp: localStorage.getItem("sortTpMyConversations") || 0 });
}

class MyConversations extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);

        this.state = {
            conversationsData: ([]),
            /*conversationsData: ([{id: 3, first_name:"Jiří", last_name: "Němec", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")},
                            {id: 3, first_name:"Petr", last_name: "Říha", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")},
                            {id: 3, first_name:"Ester", last_name: "Napoleonová", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")},
                            {id: 3, first_name:"Zdeněk", last_name: "Čtvrtý", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")},
                            {id: 3, first_name:"Augustín", last_name: "Všestranný", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")},
                            {id: 3, first_name:"Lucie", last_name: "Křížová", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")},
                            {id: 3, first_name:"Zbyněk", last_name: "Štěteček", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")},
                            {id: 3, first_name:"Jiří", last_name: "Němec", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")},
                            {id: 3, first_name:"Jiří", last_name: "Němec", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")},
                            {id: 3, first_name:"Jiří", last_name: "Němec", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")}]),*/
            recordsOnOnePage: localStorage.getItem("recordsOnOnePageMyConversations") || 25, // number of conversations showing on one page, 25 is default
            totalNumberOfContacts: 0,
            actualPage: localStorage.getItem("actualPageMyConversations") || 0, // starts with pageNumber=0, then 1, 2, 3,...
            sortBy: localStorage.getItem("sortByMyConversations") || "", // name
            sortTp: localStorage.getItem("sortTpMyConversations") || "asc", // asc, desc
        }

    }

    componentDidMount(){
        this.loadData();
        window.addEventListener('storage', storageEventHandler, false);
        this.setActualSection("conversations");
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.searchText !== this.props.searchText) {
            this.setState({ actualPage: 0 });
            localStorage.setItem("actualPageMyConversations", 0);
            this.loadData();
        }
    }

    loadData = () => {
        // if user lowers the rowsPerPage, goes next page and then highers the rowsPerPage it will take him to the last page
        if(checkIfOutOfPages(this.state.actualPage, this.state.recordsOnOnePage, this.state.totalNumberOfContacts)){
            this.goToLastPage();
            return;
        }
        // I should use something like: my_conv=1
        Axios.get('/api/conv/get?my=1&limit='+this.state.recordsOnOnePage+'&offset='+(this.state.actualPage*this.state.recordsOnOnePage)+'&search='+this.props.searchText).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var improvedArray = [];
            for(let oneRow of response.data.result){
                var improvedObject = oneRow;
                oneRow.isOpen = false;
                improvedArray.push(improvedObject);
            }
            this.setState({ conversationsData: improvedArray });
            this.setState({ totalNumberOfContacts: response.data.msg.cnt });
        });
    }

    setConversationsArray = (array) => {
        this.setState({ conversationsData: array });
    }

    goToFirstPage = async () => {
        await this.setState({ actualPage: goToFirstPageUtilities(this.state.actualPage, "actualPageMyConversations") });
        this.loadData();
    }

    goToPrevPage = async () => {
        await this.setState({ actualPage: goToPrevPageUtilities(this.state.actualPage, "actualPageMyConversations") });
        this.loadData();
    }

    goToNextPage = async () => {
        await this.setState({ actualPage: goToNextPageUtilities(this.state.actualPage, this.state.recordsOnOnePage, this.state.totalNumberOfContacts, "actualPageMyConversations") });
        this.loadData();
    }

    goToLastPage = async () => {
        await this.setState({ actualPage: goToLastPageUtilities(this.state.recordsOnOnePage, this.state.totalNumberOfContacts, "actualPageMyConversations") });
        this.loadData();
    }

    moreRecordsOnPage = async () => {
        await this.setState({ recordsOnOnePage: moreRecordsOnPageUtilities(this.state.recordsOnOnePage, "recordsOnOnePageMyConversations") });
        this.loadData();
    }

    lessRecordsOnPage = async () => {
        await this.setState({ recordsOnOnePage: lessRecordsOnPageUtilities(this.state.recordsOnOnePage, "recordsOnOnePageMyConversations") });
        this.loadData();
    }

    render() {
        return (
            <div className="verticalStack flex" style={{height: "calc(100vh - 80px)"}}>
                <div className="horizontalStackCenter myConversationsTableListingBar">
                    <span onClick={this.goToFirstPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange">{"<<"}</span>
                    <span onClick={this.goToPrevPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange" style={{paddingLeft: "20px"}}>{"<"}</span>
                    <span className="fontPoppinsRegular13Gray" style={{paddingLeft: "20px", paddingRight: "12px"}}>{"My Conversations List"}</span>
                    <span className="fontPoppinsSemiBold16">{(this.state.actualPage*this.state.recordsOnOnePage)+1}{"-"}{(this.state.actualPage+1)*this.state.recordsOnOnePage > this.state.totalNumberOfContacts ? this.state.totalNumberOfContacts : (this.state.actualPage+1)*this.state.recordsOnOnePage }</span>
                    <span className="fontPoppinsRegular13Gray" style={{paddingLeft: "6px", paddingRight: "6px"}}>{"of"}</span>
                    <span className="fontPoppinsSemiBold16">{this.state.totalNumberOfContacts}</span>
                    <span onClick={this.goToNextPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange" style={{paddingLeft: "20px", paddingRight: "20px"}}>{">"}</span>
                    <span onClick={this.goToLastPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange">{">>"}</span>
                    <div className="flex"/>
                    <span className="fontPoppinsRegular13Gray">{"Rows Per page:"}</span>
                    <span className="fontPoppinsSemiBold16" style={{paddingLeft: "10px", paddingRight: "10px"}}>{this.state.recordsOnOnePage}</span>
                    <div className="verticalStackCenter">
                        <div onClick={this.moreRecordsOnPage} className="clickable buttonPagingUp"></div>
                        <div style={{height: "3px"}}></div>
                        <div onClick={this.lessRecordsOnPage} className="clickable buttonPagingDown"></div>
                    </div>
                </div>
                <MyConversationsTile conversationsArray={this.state.conversationsData} setConversationsArray={this.setConversationsArray} getDataFromApi={this.loadData} leftMenuOpened={this.props.leftMenuOpened} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions}/>
            </div>
        );
    }
}

export default MyConversations;
