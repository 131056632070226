import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import Axios from 'axios';
import Moment from 'react-moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import assets
import icon_action_down from '../assets/icon_action_down.png';
import icon_expand from '../assets/icon_expand.png';
import icon_add_white from '../assets/icon_add_white.png';
import icon_locked from '../assets/icon_locked.png';

// import components
import EditButtonDropdown from '../components/EditButtonDropdown.js';
import ConversationsModal from '../modals/ConversationsModal.js';
import RoundButton from '../components/RoundButton.js';
import ExpandButton from '../components/ExpandButton.js';

class ConversationsTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clickedConversationId: -1,
            mainContactId: 0,
            mainContactName: "Error",
            showModalAddConversation: false,
            prepareToShowModalAddConversation: false,
            showModalEditConversation: false,
            prepareToShowModalEditConversation: false,
            actualOpenedConversation: -1,
            conversationsList: ([{id: 1, author: "Greg Strock", withContacts: "Jiri Nemec, Mark Howland", date: "June 8, 2020", category: "Discipleship", yrs: 7, descr: "Lorem Ipsum...", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add"},
                         {id: 2, author: "Jiri Nemec", withContacts: "Greg Strock, Jiri Nemec", date: "June 8, 2020", category: "Discipleship", yrs: 10, descr: "Lorem Ipsum...", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add"},
                         {id: 3, author: "Mark Howland", withContacts: "Jiri Nemec, Greg Strock", date: "June 8, 2020", category: "Discipleship", yrs: 4, descr: "Lorem Ipsum...", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add"}]),
            expandAll: false,
            needUpdateVar: true, // I am saving here value from last updateVar I received and then in render if this variable changes from what I get from parent, then I update data
        }
    }

    componentDidMount(){
        this.loadData();
        this.setState({ needUpdateVar: this.props.updateVar });
    }

    loadData = () => {
        const pathname = window.location.href;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))){ // if there is number received from URL
            if(this.props.contactMode == "church"){
                Axios.get('/api/conv/get?church_id='+id+'').then(response => {
                    if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                    var improvedArray = [];
                    for(let oneRow of response.data.result){
                        var improvedObject = oneRow;
                        oneRow.isOpen = false;
                        improvedArray.push(improvedObject);
                    }
                    this.setState({ conversationsList: improvedArray });
                });
            }
            else { // else behave as with person
                Axios.get('/api/conv/get?cont_id='+id+'').then(response => {
                    if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                    var improvedArray = [];
                    for(let oneRow of response.data.result){
                        var improvedObject = oneRow;
                        oneRow.isOpen = false;
                        improvedArray.push(improvedObject);
                    }
                    this.setState({ conversationsList: improvedArray });
                });
            }
            Axios.get('/api/cont/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                if(response.data.result[0].first_name && response.data.result[0].last_name){
                    this.setState({ mainContactName: response.data.result[0].first_name+" "+response.data.result[0].last_name })
                }
                else if(response.data.result[0].short_name){
                    this.setState({ mainContactName: response.data.result[0].short_name })
                }
            });
            this.setState({ mainContactId: id });
        }
        //this.props.update();
    }

    openCloseConversation(idConversation){
        /*if(this.state.actualOpenedConversation == idConversation){ // if sending same conversation again it would close it
            this.setState({ actualOpenedConversation: ""})
        }
        else {
            this.setState({ actualOpenedConversation: idConversation})
        }*/
        var newArray = this.state.conversationsList;
        for(let oneRow of newArray){
            if(oneRow.id == idConversation){
                oneRow.isOpen = !oneRow.isOpen;
            }
        }
        this.setState({ conversationsList: newArray})
    }

    onEdit = async (id) => {
        await this.setState({ clickedConversationId: id });
        this.openModal("EditConversation");
    }

    onDelete = (id) => {
        Axios.delete('/api/conv/del/'+id, {
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.loadData();
        });
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "AddConversation"){
            await this.setState({ prepareToShowModalAddConversation: true });
            this.setState({ showModalAddConversation: true });
        }
        if(modalWindow == "EditConversation"){
            await this.setState({ prepareToShowModalEditConversation: true });
            this.setState({ showModalEditConversation: true });
        }
    }

    closeModal = async (modalWindow, createAnotherNewAfter) => {
        // reload data
        this.loadData();

        // close the modal
        if(modalWindow == "AddConversation"){
            this.setState({ showModalAddConversation: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalAddConversation: false });
        }
        if(modalWindow == "EditConversation"){
            this.setState({ showModalEditConversation: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalEditConversation: false });
        }

        if(createAnotherNewAfter){
            await new Promise(resolve => setTimeout(resolve, 100));
            this.openModal("AddConversation");
        }
    }

    openCloseTile = () => {
        if(this.props.isOpen){
            this.props.setActualOpenedTile("");
        }
        else {
            this.props.setActualOpenedTile("conversations");
        }
    }

    expandCollapseAll(){
        var newArray = this.state.conversationsList;
        for(let oneRow of newArray){
            if(this.state.expandAll){
                oneRow.isOpen = false;
            }
            else {
                oneRow.isOpen = true;
            }
        }
        this.setState({ conversationsList: newArray });
        this.setState({ expandAll: !this.state.expandAll });
    }

    render() {
        if(this.state.needUpdateVar != this.props.updateVar){
            this.setState({ needUpdateVar: this.props.updateVar });
            this.loadData();
        }
        return (
            <div className="conversationsTile">

                <div className="horizontalStack">

                    {/*<div className="circleButtonContainer clickable" style={{marginTop: 5}} onClick={this.openCloseTile}>
                        <img className={this.props.isOpen ? "expandButtonExpanded" : "expandButton"} src={icon_expand}/>
                    </div>*/}

                    <ExpandButton isOpen={this.props.isOpen} style={{marginTop: 5}} onClick={this.openCloseTile} />

                    <div style={{width: "20px"}}></div>

                    <div className="verticalStack flex" style={{display: "table", tableLayout: "fixed", width: "100%"}}>
                        <span className="fontPoppinsSemiBold15">{"Conversations"}</span>
                        <span className={this.props.isOpen ? "fontPoppinsRegular13Gray oneLineThreeDotsTextDeactivated" : "fontPoppinsRegular13Gray oneLineThreeDotsText"}>{"Use this tool for taking notes on conversations and notifying others about follow up needs. Click on the button on the right to start. Add the people involved, the date, and choose the topic of conversation. Below are all the conversations that include this contact."}</span>
                    </div>

                    <div style={{width: "20px"}}></div>

                    <div className="circleButtonContainer circleButtonContainerOrange clickable" style={{marginTop: 5}} onClick={() => this.openModal("AddConversation")}>
                        <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                    </div>

                </div>

                <div className="horizontalStackCenter" style={{display: this.props.isOpen ? "" : "none"}}>

                        {/* I am using here styles from Roles tile */}
                        <table className="rolesTable" style={{marginTop: 10}}>
                            <tr>
                                <td className="rolesTableHeader rolesTableHeaderOther clickable" nowrap="nowrap" onClick={() => this.expandCollapseAll()}>
                                    <img className={this.state.expandAll ? "expandButtonExpanded onHoverToOrange" : "expandButton onHoverToOrange"}  style={{width: "10px", height: "10px"}} src={icon_expand}/>
                                </td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Created by</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap" style={{width: "100%"}}>With</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">When</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Category</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Edit</td>
                            </tr>

                            {this.state.conversationsList.map(function (item) {
                                    return (
                                        <tbody>
                                        <tr className="clickable" onClick={() => this.openCloseConversation(item.id)} style={{backgroundColor: item.isOpen ? "var(--lightest-gray)" : ""}}>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther rolesTableRowExpandImage" nowrap="nowrap">
                                                <img className={item.isOpen ? "expandButtonExpanded" : "expandButton"}  style={{width: "10px", height: "10px"}} src={icon_expand}/>
                                            </td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.cr_by}</td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther responsiveTableDiv" nowrap="nowrap">{item.cont_names}</td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap"><Moment format="MMMM D, YYYY">{item.dt}</Moment></td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.ctg_labels}</td>
                                            <td className="rolesTableGeneralDiv" nowrap="nowrap">
                                                {item.is_denied == 0 ?
                                                <EditButtonDropdown itemId={item.id} onEdit={this.onEdit} onDelete={this.onDelete} text={"Are you sure want to delete this conversation?"}/>
                                                : null }
                                            </td>
                                        </tr>
                                        <div style={{display: item.isOpen ? "" : "none", height: 1}}></div>
                                        <tr style={{display: item.isOpen ? "" : "none", backgroundColor: "var(--lightest-gray)"}}>
                                            <td colSpan="6" style={{padding: 10}}>
                                                <span className="fontPoppinsRegular13Gray">{item.is_private == 1 && item.is_denied == 0 ? "Notes (Private):" : "Notes:"}</span>
                                                <br/>
                                                <div className="responsiveThreeDotsContainer">
                                                    <span className="fontPoppinsRegular13" style={{overflowWrap: "break-word"}}>{item.is_private == 1 && item.is_denied == 1 ? "(Private Notes)" : item.descr}</span>
                                                </div>
                                                <br/><br/>
                                                <span className="fontPoppinsRegular13Gray">{"What can we offer to help meet their needs?:"}</span>
                                                <br/>
                                                <span className="fontPoppinsRegular13">{item.offer_labels}. {item.need_descr}</span>
                                                <br/><br/>
                                                <div className="horizontalStack">
                                                    <div className="verticalStack flex">
                                                        <span className="fontPoppinsRegular13Gray">{"Who was sent a copy of this observation?"}</span>
                                                        <div className="horizontalStack flex" style={{overflowX: "visible", flexWrap: "wrap"}}>
                                                            {item.send_names != null && item.send_names.split(", ").map(function (secondItem) {
                                                                return (
                                                                    <span className="fontPoppinsRegular13Blue" style={{border: "1px solid var(--blue-button-link)", paddingLeft: 5, paddingRight: 5, borderRadius: 10, marginRight: 10, marginTop: 10, whiteSpace: "nowrap"}}>{secondItem}</span>
                                                                )
                                                            }.bind(this))}
                                                        </div>
                                                    </div>

                                                    {item.is_private == 1 && item.is_denied == 1 ? null : <div style={{display: "flex", alignItems: "flex-end"}}>
                                                        <RoundButton title={"Edit"} className={"roundButtonOrange"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.onEdit(item.id)} />
                                                    </div> }
                                                    {item.is_private == 1 && item.is_denied == 1 ? null : <div style={{display: "flex", alignItems: "flex-end"}}>
                                                        <RoundButton title={"Delete"} className={"roundButtonGray"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.onDelete(item.id)} />
                                                    </div> }
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    )
                            }.bind(this))}

                        </table>

                </div>

                {this.state.prepareToShowModalAddConversation ? <ConversationsModal showModal={this.state.showModalAddConversation} closeModal={this.closeModal} openModal={this.openModal} contactId={this.state.mainContactId} contactName={this.state.mainContactName} actionType={"AddConversation"} conversationId={-1} contactMode={this.props.contactMode} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                {this.state.prepareToShowModalEditConversation ? <ConversationsModal showModal={this.state.showModalEditConversation} closeModal={this.closeModal} openModal={this.openModal} contactId={this.state.mainContactId} contactName={this.state.mainContactName} actionType={"EditConversation"} conversationId={this.state.clickedConversationId} contactMode={this.props.contactMode} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }

            </div>
        );
    }
}

export default ConversationsTile;