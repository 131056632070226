import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { Link, Navigate } from "react-router-dom";
import Axios from 'axios';
import Moment from 'react-moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import assets
import churches from '../assets/icon_churches_gray.png';
import icon_locked from '../assets/icon_locked.png';

// import components
import ProfileImageSmall from '../components/ProfileImageSmall.js';
import EditButtonDropdown from '../components/EditButtonDropdown.js';
import ConversationsModalMobile from '../modals_m/ConversationsModalMobile.js';

class MyConversationsTileMobile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            clickedConversationId: -1,
            showModalAddConversation: false,
            prepareToShowModalAddConversation: false,
            showModalEditConversation: false,
            prepareToShowModalEditConversation: false,
        }
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
    }

    openConversation = (event, id, openTile) => {

        this.onEdit(id);
        /*
        var openTileText = "";
        if(openTile != ""){
            openTileText = "?tile="+openTile;
        }

        if(event.ctrlKey || event.metaKey){ window.open("/ChurchProfile/"+id+""+openTileText, '_blank', 'noreferrer'); }
        else { this.setState({ redirectTo: "/ChurchProfile/"+id+""+openTileText }); }
        */
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;
        if(bottom){
            this.props.loadMoreData();
        }
    }

    onEdit = async (id) => {
        await this.setState({ clickedConversationId: id })
        this.openModal("EditConversation");
    }

    onDelete = (id) => {
        Axios.delete('/api/conv/del/'+id, {
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.props.getDataFromApi();
        });
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "AddConversation"){
            await this.setState({ prepareToShowModalAddConversation: true });
            this.setState({ showModalAddConversation: true });
        }
        if(modalWindow == "EditConversation"){
            await this.setState({ prepareToShowModalEditConversation: true });
            this.setState({ showModalEditConversation: true });
        }
    }

    closeModal = async (modalWindow, createAnotherNewAfter) => {
        // reload data
        this.props.getDataFromApi();

        // close the modal
        if(modalWindow == "AddConversation"){
            this.setState({ showModalAddConversation: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalAddConversation: false });
        }
        if(modalWindow == "EditConversation"){
            this.setState({ showModalEditConversation: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalEditConversation: false });
        }

        if(createAnotherNewAfter){
            await new Promise(resolve => setTimeout(resolve, 100));
            this.openModal("AddConversation");
        }
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

        return (
            <div style={{height: "calc(100% - 50px)", width: "100%"}}>
                <div className="myPeopleTileMobile">
                    <div onScroll={this.handleScroll} style={{width: this.props.leftMenuOpened == "true" ? "calc(100% - 240px)" : "calc(100% - 60px)", height: "calc(100% - 130px)", position: "absolute", overflowX: "auto", overflowY: "auto"}}> {/* 240px including the padding */}
                        <table className="myPeopleTableMobile">
                            <tr>
                                <td className="myPeopleTableHeader myPeopleTableActionHeaderMobile" style={{width: 80, minWidth: 80}}>
                                    <div className="horizontalStack" style={{marginLeft: 10}}>When</div>
                                </td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderRole">Created by</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther">With</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther">Category</td>
                                {/*<td className="myPeopleTableHeader myPeopleTableHeaderOther">Edit</td>*/}
                            </tr>

                            {this.props.conversationsArray.map(function (item, index) {
                                if(item.is_denied == 1){
                                    return (
                                        <tr key={index} className="myPeopleTableRow" style={{backgroundColor: "var(--lightest-gray)"}}>

                                            <td className="myPeopleTableFirstFixedColumnMobile" style={{backgroundColor: "var(--lightest-gray)"}}>
                                                <span className="verticalStack fontPoppinsMedium14DarkGray" style={{alignItems: "flex-start", marginLeft: 10}}>
                                                    <Moment format="MMM D," style={{alignItems: "left", justifyContent: "start"}}>{item.dt}</Moment>
                                                    <Moment format="YYYY" style={{alignItems: "left", justifyContent: "start"}}>{item.dt}</Moment>
                                                </span>
                                            </td>

                                            <td className="myPeopleTableGeneralDiv" nowrap="nowrap">
                                                <img style={{width: "16px", height: "16px", marginLeft: 0, marginRight: 5, marginBottom: -3}} src={icon_locked}/>
                                                <span className="fontPoppinsMedium14DarkGray">{item.cr_by}</span>
                                            </td>
                                            <td className="myPeopleTableGeneralDiv" nowrap="nowrap">{item.cont_names}</td>
                                            <td className="myPeopleTableGeneralDiv" nowrap="nowrap">{item.ctg_labels}</td>
                                        </tr>
                                    );
                                }
                                else {
                                    return (
                                        <tr key={index} className="myPeopleTableRow">

                                            <td className="myPeopleTableFirstFixedColumnMobile" onClick={(event) => this.openConversation(event, item.id, "")}>
                                                <span className="verticalStack fontPoppinsMedium14DarkGray" style={{alignItems: "flex-start", marginLeft: 10}}>
                                                    <Moment format="MMM D," style={{alignItems: "left", justifyContent: "start"}}>{item.dt}</Moment>
                                                    <Moment format="YYYY" style={{alignItems: "left", justifyContent: "start"}}>{item.dt}</Moment>
                                                </span>
                                            </td>

                                            <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openConversation(event, item.id, "")}>
                                                <span className="fontPoppinsMedium14DarkGray">{item.cr_by}</span>
                                            </td>
                                            <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openConversation(event, item.id, "")}>{item.cont_names}</td>
                                            <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openConversation(event, item.id, "")}>{item.ctg_labels}</td>
                                        </tr>
                                    );
                                }
                            }.bind(this))}

                            { this.props.showLoadingRow ? <tr>
                                <td className="myPeopleTableGeneralDiv" colspan={7}>
                                    <span>{"Loading more records..."}</span>
                                </td>
                            </tr> : null }

                        </table>
                    </div>
                </div>

                {this.state.prepareToShowModalAddConversation ? <ConversationsModalMobile showModal={this.state.showModalAddConversation} closeModal={this.closeModal} contactId={null} contactName={null} actionType={"AddConversation"} conversationId={-1} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                {this.state.prepareToShowModalEditConversation ? <ConversationsModalMobile showModal={this.state.showModalEditConversation} closeModal={this.closeModal} contactId={null} contactName={null} actionType={"EditConversation"} conversationId={this.state.clickedConversationId} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }

            </div>
        );
    }
}

export default MyConversationsTileMobile;
