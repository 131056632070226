import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { Link, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';
import Moment from 'react-moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { getInitials, showSortIcon } from '../utilities/OtherUtilities.js';

// import assets
import icon_letter from '../assets/icon_letter.png';
import icon_action_down from '../assets/icon_action_down.png';
import person_avatar from '../assets/person_avatar.png';
import icon_close_gray from '../assets/close_gray.png';

// import components
import ProfileImageSmall from '../components/ProfileImageSmall.js';
import SearchBoxEvents from '../components/SearchBoxEvents.js';
import GrayTextButton from '../components/GrayTextButton.js';
import RoundButton from '../components/RoundButton.js';

class MyEventsTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            selectedItems: [],
            showPopupTableActions: false,
            advancedMenuExpanded: false, // default should be false
            // advanced search variables
            adv_op: "and",
            adv_evnt_nm: "", // event name
            adv_area: "", // event area
            adv_nt_org: "", // event national organization
            adv_mnry: "", // event ministry type
            adv_evnt_bef: "", // event Before Date YYYY-MM-DD
            adv_evnt_aft: "", // event After Date YYYY-MM-DD
            adv_evnt_tp: "", // event type
            adv_evnt_subtp: "", // event sub-type
            areaTypeArray: ([{id: 0, name: ""}]),
            nationalOrganizationsArray: ([{id: 0, name: ""}]),
            ministryTypeArray: ([{id: 0, name: ""}]),
            eventTypeArray: ([{id: 0, name: ""}]),
            eventSubTypeArray: ([{id: 0, name: ""}]),
            quickSearchText: localStorage.getItem("quickSearchText") || "",
        }

        this.refPopupTableActions = React.createRef();
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
        window.addEventListener('click', this.onGlobalClick);
        this.loadData();
    }

    loadData = async () => {
        await this.loadAreaTypes();
        await this.loadNationalOrganizationsArray();
        await this.loadMinistryTypes();
        await this.loadEventTypes();
        await this.loadEventSubTypes();

        // load data
        const { adv_op, adv_evnt_nm, adv_area, adv_nt_org, adv_mnry, adv_evnt_bef, adv_evnt_aft, adv_evnt_tp, adv_evnt_subtp } = Object.fromEntries(new URLSearchParams(window.location.search));

        var fixable_adv_op = adv_op;
        if(adv_op != "and" && adv_op != "or"){ fixable_adv_op = "and"; }
        this.setState({ adv_op: fixable_adv_op });
        this.setState({ adv_evnt_nm: adv_evnt_nm });
        this.setState({ adv_area: adv_area });
        this.setState({ adv_nt_org: adv_nt_org });
        this.setState({ adv_mnry: adv_mnry });
        this.setState({ adv_evnt_bef: adv_evnt_bef });
        this.setState({ adv_evnt_aft: adv_evnt_aft });
        this.setState({ adv_evnt_tp: adv_evnt_tp });
        this.setState({ adv_evnt_subtp: adv_evnt_subtp });
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onGlobalClick);
    }

    /* This method is triggered on every click on screen and is checking if some popup is opened nad user clicked out of this popup so it would close that popup in that situation */
    onGlobalClick = (e) => {
        if ( (!this.refPopupTableActions.current.contains(e.target)) && this.state.showPopupTableActions) {
            // user clicked out of the PopupTableActions and that popup is now opened
            this.setState({ showPopupTableActions: false })
        }
    }

    openEventProfilePage = (event, id, openTile) => {
        var openTileText = "";
        if(openTile != ""){
            openTileText = "?tile="+openTile;
        }

        if(event.ctrlKey || event.metaKey){ window.open("/EventProfile/"+id+""+openTileText, '_blank', 'noreferrer'); }
        else { this.setState({ redirectTo: "/EventProfile/"+id+""+openTileText }); }
    }

    openConversationsOfContact = (event, id) => {
        this.openEventProfilePage(event, id, "conversations");
    }

    selectUnselectItem = (id) => {
        if(this.isRowSelected(id)){
            // delete
            let remainingItems = this.state.selectedItems.filter((item) => {return item !== id});
            this.setState({ selectedItems: remainingItems });
        }
        else {
            // add
            this.setState({
                selectedItems: [...this.state.selectedItems, id]
            })
        }
    }

    isRowSelected = (id) => {
        for(let oneRow of this.state.selectedItems){
            if(oneRow == id){
                return true;
            }
        }
        return false;
    }

    tableActionSelectAll = () => {
        var workArray = [];
        for(let oneRow of this.props.eventsArray){
            workArray.push(oneRow.id);
        }
        this.setState({ selectedItems: workArray });
    }

    tableActionDeselectAll = () => {
        this.setState({ selectedItems: [] });
    }

    addSelectedToMyEvents = () => {
        if(this.state.selectedItems.length <= 0){ return; }

        Axios.post('/api/my_evnt/ins/', {
            EvntId: this.state.selectedItems,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.props.openAlertMessage("Events were added to \"My Events\".","OK");
            this.props.loadData();
        });
    }

    removeSelectedFromMyEvents = () => {
        if(this.state.selectedItems.length <= 0){ return; }

        Axios.delete('/api/my_evnt/del/', {
            data: {EvntId: this.state.selectedItems},
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.props.openAlertMessage("Events were removed from \"My Events\".","OK");
            this.props.loadData();
        });
    }

    /* START OF ADVANCED SEARCH METHODS */

    loadAreaTypes = () => {
        Axios.get('/api/area/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var workArray = [];
            for(let oneRow of response.data.result){ workArray.push({"id":oneRow.id, "name": oneRow.label}); }
            this.setState({ areaTypeArray: workArray });
        });
    }

    loadNationalOrganizationsArray = () => {
        Axios.get('/api/nt_org/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var workArray = [];
            for(let oneRow of response.data.result){ workArray.push({"id":oneRow.id, "name": oneRow.label}); }
            this.setState({ nationalOrganizationsArray: workArray });
        });
    }

    loadMinistryTypes = () => {
        Axios.get('/api/mnry/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var workArray = [];
            for(let oneRow of response.data.result){ workArray.push({"id":oneRow.id, "name": oneRow.label}); }
            this.setState({ ministryTypeArray: workArray });
        });
    }

    loadEventTypes = () => {
        Axios.get('/api/evnt_tp/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var workArray = [];
            for(let oneRow of response.data.result){ workArray.push({"id":oneRow.id, "name": oneRow.label}); }
            this.setState({ eventTypeArray: workArray });
        });
    }

    loadEventSubTypes = () => {
        Axios.get('/api/evnt_subtp/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var workArray = [];
            for(let oneRow of response.data.result){ workArray.push({"id":oneRow.id, "name": oneRow.label}); }
            this.setState({ eventSubTypeArray: workArray });
        });
    }

    actionSearch = async () => {
        localStorage.setItem("actualPageEvents", 0);
        localStorage.setItem("sortByEvents", "");
        localStorage.setItem("sortTpEvents", "asc");
        this.setState({ advancedMenuExpanded: false });
        localStorage.setItem("quickSearchText", "");

        var url = "/Events?adv_op="+this.state.adv_op+"&adv_evnt_nm="+(this.state.adv_evnt_nm == undefined ? "" : this.state.adv_evnt_nm)+"&adv_area="+this.state.adv_area+"&adv_nt_org="+this.state.adv_nt_org+"&adv_mnry="+this.state.adv_mnry+"&adv_evnt_tp="+this.state.adv_evnt_tp+"&adv_evnt_subtp="+this.state.adv_evnt_subtp+"&adv_evnt_bef="+(this.state.adv_evnt_bef == undefined ? "" : this.state.adv_evnt_bef)+"&adv_evnt_aft="+(this.state.adv_evnt_aft == undefined ? "" : this.state.adv_evnt_aft);
        navigate(url);
        window.location.reload();
        //this.setState({ redirectTo: url })
    }

    actionClearAll = () => {
        this.setState({ adv_op: "and" });
        this.setState({ adv_evnt_nm: "" });
        this.setState({ adv_area: "" });
        this.setState({ adv_nt_org: "" });
        this.setState({ adv_mnry: "" });
        this.setState({ adv_evnt_bef: "" });
        this.setState({ adv_evnt_aft: "" });
        this.setState({ adv_evnt_tp: "" });
        this.setState({ adv_evnt_subtp: "" });
    }

    actionCancel = () => {
        this.setState({ advancedMenuExpanded: false });
    }

    onEnterSearch = async (e) => {
        if(e.key === 'Enter'){
            this.actionSearch();
        }
    }

    setQuickSearchText = (textToSearch) => {
        this.setState({ quickSearchText: textToSearch });
        localStorage.setItem("quickSearchText", textToSearch);
    }

    /* END OF ADVANCED SEARCH METHODS */

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

        return (
            <div style={{height: "calc(100% - 60px)", width: "100%"}}>
                <div className="myPeopleTile">
                    <div style={{width: this.props.leftMenuOpened == "true" ? "calc(100% - 240px - 80px)" : "calc(100% - 90px - 80px)", height: "calc(100% - 80px - 120px)", position: "absolute", overflowX: "auto", overflowY: "auto"}}> {/* 240px including the padding */}

                        <div className="horizontalStackCenter" style={{position: "sticky", left: 0, zIndex: 4}}>
                            <SearchBoxEvents searchText={this.state.quickSearchText} setSearchText={this.setQuickSearchText}  openAlertMessageTwoActions={this.props.openAlertMessage} />
                            <GrayTextButton title={"Advanced Search"} style={{marginLeft: "18px", marginRight: "9px", whiteSpace: "nowrap"}} onClick={() => this.setState({ advancedMenuExpanded: !this.state.advancedMenuExpanded })} />
                            <img className="clickable onHoverToOrange" style={{width: 20, display: this.state.advancedMenuExpanded ? "" : "none"}} onClick={() => this.setState({ advancedMenuExpanded: !this.state.advancedMenuExpanded })} src={icon_close_gray}/>
                        </div>


                        <div className="verticalStack" style={{display: this.state.advancedMenuExpanded ? "" : "none", position: "sticky", left: 0}}>
                            <span className="fontPoppinsSemiBold15" style={{marginTop: 20}}>Advanced Search:</span>

                            <div className="horizontalStack" style={{marginTop: 15, backgroundColor: "var(--lightest-gray)", padding: 24}}>

                                <div className="verticalStack flex">
                                    <span className="fontPoppinsRegular13">Event Name:</span>
                                    <input className="fontPoppinsRegular13" type="text" id="eventName" name="eventName" autoComplete="off" placeholder="" value={this.state.adv_evnt_nm} onChange={e => {this.setState({ adv_evnt_nm: e.target.value });}} onKeyUp={this.onEnterSearch} style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}}/>

                                    <div style={{height: 10}}></div>

                                    <span className="fontPoppinsRegular13">Area:</span>
                                    <select className="profileTileInputText topMenuSelectBox" name="eventArea" id="eventArea" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_area} onChange={e => {this.setState({ adv_area: e.target.value });}}>
                                        <option value=""></option>
                                        {this.state.areaTypeArray.map(function (item) {
                                            return ( <option value={item.id} selected={item.id == this.state.adv_area ? "selected" : ""}>{item.name}</option> )
                                        }.bind(this))}
                                    </select>

                                    <div style={{height: 10}}></div>

                                    <span className="fontPoppinsRegular13">Event Type:</span>
                                    <select className="profileTileInputText topMenuSelectBox" name="eventType" id="eventType" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_evnt_tp} onChange={e => {this.setState({ adv_evnt_tp: e.target.value });}}>
                                        <option value=""></option>
                                        {this.state.eventTypeArray.map(function (item) {
                                            return ( <option value={item.id} selected={item.id == this.state.adv_evnt_tp ? "selected" : ""}>{item.name}</option> )
                                        }.bind(this))}
                                    </select>

                                </div>

                                <div style={{width: 50}}></div>

                                <div className="verticalStack flex">
                                    <span className="fontPoppinsRegular13">National Organization:</span>
                                    <select className="profileTileInputText topMenuSelectBox" name="eventOrg" id="eventOrg" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_nt_org} onChange={e => {this.setState({ adv_nt_org: e.target.value });}}>
                                        <option value=""></option>
                                        {this.state.nationalOrganizationsArray.map(function (item) {
                                            return ( <option value={item.id} selected={item.id == this.state.adv_nt_org ? "selected" : ""}>{item.name}</option> )
                                        }.bind(this))}
                                    </select>

                                    <div style={{height: 10}}></div>

                                    <span className="fontPoppinsRegular13">Ministry:</span>
                                    <select className="profileTileInputText topMenuSelectBox" name="eventMinistry" id="eventMinistry" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_mnry} onChange={e => {this.setState({ adv_mnry: e.target.value });}}>
                                        <option value=""></option>
                                        {this.state.ministryTypeArray.map(function (item) {
                                            return ( <option value={item.id} selected={item.id == this.state.adv_mnry ? "selected" : ""}>{item.name}</option> )
                                        }.bind(this))}
                                    </select>

                                    <div style={{height: 10}}></div>

                                    <span className="fontPoppinsRegular13">Event Sub-type:</span>
                                    <select className="profileTileInputText topMenuSelectBox" name="eventSubType" id="eventSubType" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_evnt_subtp} onChange={e => {this.setState({ adv_evnt_subtp: e.target.value });}}>
                                        <option value=""></option>
                                        {this.state.eventSubTypeArray.map(function (item) {
                                            return ( <option value={item.id} selected={item.id == this.state.adv_evnt_subtp ? "selected" : ""}>{item.name}</option> )
                                        }.bind(this))}
                                    </select>
                                </div>

                                <div style={{width: 50}}></div>

                                <div className="verticalStack flex">
                                    <span className="fontPoppinsRegular13">After Date:</span>
                                    <input className="fontPoppinsRegular13" type="date" id="eventAFT" name="eventAFT" autoComplete="off" min="1900-01-01" max="9999-01-01" value={this.state.adv_evnt_aft} onChange={e => {this.setState({ adv_evnt_aft: e.target.value });}} style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}}/>

                                    <div style={{height: 15}}></div>

                                    <span className="fontPoppinsRegular13">Before Date:</span>
                                    <input className="fontPoppinsRegular13" type="date" id="eventBEF" name="eventBEF" autoComplete="off" min="1900-01-01" max="9999-01-01" value={this.state.adv_evnt_bef} onChange={e => {this.setState({ adv_evnt_bef: e.target.value });}} style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}}/>
                                </div>

                                <div style={{width: 50}}></div>

                                <div className="verticalStack flex">
                                    <div className="horizontalStack">
                                        <div className="verticalStackCenter" onChange={event => this.setState({ adv_op: event.target.value })}>
                                            <span className="fontPoppinsRegular13">{"And"}</span>
                                            <input type="radio" id="and" name="adv_evnt_op" value={"and"} checked={this.state.adv_op == "and" ? true : false} style={{width: 15, height: 15}}/>
                                        </div>
                                        <div style={{width: 10}}></div>
                                        <div className="verticalStackCenter" onChange={event => this.setState({ adv_op: event.target.value })}>
                                            <span className="fontPoppinsRegular13" style={{textAlign: "center"}}>{"Or"}</span>
                                            <input type="radio" id="or" name="adv_evnt_op" value={"or"} checked={this.state.adv_op == "or" ? true : false} style={{width: 15, height: 15}}/>
                                        </div>
                                    </div>
                                    <span className="fontPoppinsRegular13Gray" style={{marginTop: 20}}>{"Using the “And” search option will give you results that include all of the features you’ve chose from these filters. The “Or” search option will give you results that include only one feature from your filters."}</span>
                                </div>

                            </div>

                            <div className="horizontalStack" style={{marginTop: "20px"}}>
                                {/*<Link className="link" to={"/Events?adv_op="+this.state.adv_op+"&adv_evnt_nm="+(this.state.adv_evnt_nm == undefined ? "" : this.state.adv_evnt_nm)+"&adv_area="+this.state.adv_area+"&adv_nt_org="+this.state.adv_nt_org+"&adv_mnry="+this.state.adv_mnry+"&adv_evnt_tp="+this.state.adv_evnt_tp+"&adv_evnt_subtp="+this.state.adv_evnt_subtp+"&adv_evnt_bef="+(this.state.adv_evnt_bef == undefined ? "" : this.state.adv_evnt_bef)+"&adv_evnt_aft="+(this.state.adv_evnt_aft == undefined ? "" : this.state.adv_evnt_aft)} >*/}
                                    <RoundButton title={"Search"} className={"roundButtonOrange"} style={{marginRight: "10px"}} whiteText={true} onClick={this.actionSearch} />
                                {/*</Link>*/}
                                <RoundButton title={"Clear All"} className={"roundButtonGray"} style={{marginRight: "10px"}} whiteText={true} onClick={this.actionClearAll} />
                                <RoundButton title={"Cancel"} className={"roundButtonGray"} style={{marginRight: "10px"}} whiteText={true} onClick={this.actionCancel} />
                                <div className="flex"></div>
                            </div>

                        </div>

                        <div style={{height: 20, width: 20}}></div>

                        <table className="myPeopleTable">
                            <tr>
                                <td className="myPeopleTableHeader myPeopleTableActionHeader">

                                    <div className="universalPopupSelectContainer" ref={this.refPopupTableActions}>
                                        <div className="universalPopupSelectVisible" onClick={() => this.setState({ showPopupTableActions: !this.state.showPopupTableActions })}>
                                            <span>Action</span>
                                            <img className="myPeopleAvatarImage" style={{width: "10px", height: "6.3px"}} src={icon_action_down}/>
                                        </div>

                                        <div className="universalPopupSelectHidden" style={{display: this.state.showPopupTableActions ? "" : "none", position: "fixed", backgroundColor: "white", borderRadius: 20, height: "auto", marginLeft: -35, marginTop: -35, padding: 15, boxShadow: "0px 10px 20px var(--shadow-color)"}}>
                                            <div className="verticalStack" style={{alignItems: "flex-end"}}>
                                                <span className="fontPoppinsItalic13Orange" onClick={() => this.setState({ showPopupTableActions: false })}>Action</span>
                                                <div style={{width: "calc(100% + 30px)", height: 1, marginLeft: -15, marginRight: -15, backgroundColor: "var(--border-light-gray)", marginTop: 5, marginBottom: 10}}></div>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginBottom: 2}} onClick={() => this.tableActionSelectAll()}>Select All</span>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => this.tableActionDeselectAll()}>Deselect All</span>

                                                { this.props.allOrMyMinistry != "my" ? <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => this.addSelectedToMyEvents()}>Add to My Events</span> : null }
                                                { this.props.allOrMyMinistry == "my" ? <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => this.removeSelectedFromMyEvents()}>Remove from My Events</span> : null }
                                                {/*<span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => alert("This function is coming soon.")}>Add Conversation</span>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => alert("This function is coming soon.")}>Send Email</span>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => alert("This function is coming soon.")}>View on Map</span>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => alert("This function is coming soon.")}>View on Dashboard</span>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => alert("This function is coming soon.")}>Merge</span>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2}} onClick={() => alert("This function is coming soon.")}>Export & Download</span>*/}
                                                <div className="flex"></div>
                                            </div>
                                        </div>

                                    </div>

                                </td>
                                <td className="myPeopleTableHeader myPeopleTableNameHeader" onClick={() => this.props.changeSorting("nm")}>Name {showSortIcon("nm",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("location")}>Location {showSortIcon("location",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("reg_cnt")}>Registered {showSortIcon("reg_cnt",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("attn_cnt")}>Attendees {showSortIcon("attn_cnt",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("reg_url")}>EMS Registration {showSortIcon("reg_url",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("coord")}>Event Coordinator {showSortIcon("coord",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("ph")}>Phone {showSortIcon("ph",this.props.sortBy,this.props.sortTp)}</td>
                            </tr>

                            {this.props.eventsArray.map(function (item, index) {
                                return (
                                    <tr key={index} className="myPeopleTableRow">

                                        <td className="myPeopleTableFirstFixedColumn">
                                            <div className="horizontalStackCenter centerAll">
                                                <input type="checkbox" checked={this.isRowSelected(item.id) ? "checked" : ""} onClick={() => this.selectUnselectItem(item.id)} />
                                                <div style={{width: "15px"}}></div>
                                                <div className="verticalStackCenter horizontalStackCenter myPeopleMessageButton" onClick={(event) => this.openConversationsOfContact(event, item.id)}>
                                                    <img className="myPeopleMessageImage" src={icon_letter}/>
                                                </div>
                                            </div>
                                        </td>

                                        <td className="horizontalStackCenter myPeopleTableName myPeopleTableNameDiv myPeopleTableSecondFixedColumn" nowrap="nowrap" onClick={(event) => this.openEventProfilePage(event, item.id, "")}>
                                                {/* cell.row.original.avatar.default */}
                                                {/*<img className="myPeopleAvatarImage" src={item.avatar}/>*/}
                                                {/*<img className="myPeopleAvatarImage" src={person_avatar}/>*/}

                                                <ProfileImageSmall
                                                    name={item.evnt_nm}
                                                    uploadedImageBase64={item.image ? item.image : ""}
                                                />

                                                <span style={{paddingLeft: "25px", paddingRight: "25px", display: "inline-block", width: "100%", minWidth: "160px", maxWidth: "300px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{item.evnt_nm}</span>
                                        </td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openEventProfilePage(event, item.id, "")}>{item.location.length > 30 ? item.location.substring(0, 30).trim()+"..." : item.location}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openEventProfilePage(event, item.id, "")}>{item.reg_cnt}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openEventProfilePage(event, item.id, "")}>{item.attn_cnt}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openEventProfilePage(event, item.id, "")}>{item.reg_url}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openEventProfilePage(event, item.id, "")}>{item.coord_rcg_nm}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openEventProfilePage(event, item.id, "")}>{item.phone}</td>
                                    </tr>
                                )
                            }.bind(this))}

                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default MyEventsTile;
