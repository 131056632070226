import React, { useState, useEffect, useMemo } from "react";
import {useNavigate} from 'react-router-dom';

export function checkForErrorsInRequest(message, AlertMessage) {
    if(message.stat == "ERR"){
        if(message.code == "err_missing_authorization"){
            //alert("YOU ARE NOT SIGNED IN");
            localStorage.removeItem("leftMenuOpened");
            localStorage.setItem('token', null);
            window.location.reload();
            return 0;
        }
        else if(message.code == "err_expired"){
            //alert("YOUR LOGIN EXPIRED");
            localStorage.removeItem("leftMenuOpened");
            localStorage.setItem('token', null);
            window.location.reload();
            return 0;
        }
        else if(message.code == "err_wrong_token"){
            // happens also when there are two logins in one browser at the same time
            //alert("YOUR TOKEN IS WRONG");
            localStorage.removeItem("leftMenuOpened");
            localStorage.setItem('token', null);
            window.location.reload();
            return 0;
        }

        // show alert message
        if(AlertMessage != null && message.msg && message.msg.length > 0){
            AlertMessage(message.msg,"OK");
            return 1;
        }
    }
    else if(message.stat == "ERR_BAD_REQUEST"){
        // show alert message
        if(AlertMessage != null && message.msg.length > 0){
            AlertMessage(message.msg,"OK");
            return 1;
        }
    }
    return 0;
}
