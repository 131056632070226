import React, { useState, useEffect } from "react";
import '../App.css';
import { Link } from "react-router-dom";
import '../styles/menus.css';

// import components
import LeftMenuItem from './LeftMenuItem.js';

// import assets
import jv_logo from '../assets/jv_logo.png';
import conversations from '../assets/icon_conversations_gray.png';
import conversations_white from '../assets/icon_conversations_white.png';
import events from '../assets/icon_events_programs_gray.png';
import events_white from '../assets/icon_events_programs_white.png';
import people from '../assets/icon_people_gray.png';
import people_white from '../assets/icon_people_white.png';
import churches from '../assets/icon_churches_gray.png';
import churches_white from '../assets/icon_churches_white.png';
import schools from '../assets/icon_schools_gray.png';
import schools_white from '../assets/icon_schools_white.png';
import discipling from '../assets/icon_discipling_gray.png';
import discipling_white from '../assets/icon_discipling_white.png';
import dashboard from '../assets/icon_dashboard.png';
import dashboard_white from '../assets/icon_dashboard_white.png';
import instructions from '../assets/icon_instructions.png';
import instructions_white from '../assets/icon_instructions_white.png';
import left from '../assets/icon_left.png';

class LeftMenu extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);
    }

    isDashboardActive(actualSection){
        if(actualSection == "dashboard")return true;
        return false;
    }

    isInstructionsActive(actualSection){
        if(actualSection == "instructions")return true;
        return false;
    }

    isChurchesActive(actualSection){
        if(actualSection == "churches")return true;
        return false;
    }

    isSchoolsActive(actualSection){
        if(actualSection == "schools")return true;
        return false;
    }

    isPeopleActive(actualSection){
        if(actualSection == "people")return true;
        return false;
    }

    isDisciplingActive(actualSection){
        if(actualSection == "discipling")return true;
        return false;
    }

    isConversationsActive(actualSection){
        if(actualSection == "conversations")return true;
        return false;
    }

    isEventsActive(actualSection){
        if(actualSection == "events")return true;
        return false;
    }

    render(){
        return(
            <div className="mainLeftMenu">
                <div className="verticalStackCenter" style={{alignItems: "center"}}>
                    <Link className="link" to={"./"} >
                        <img style={{width: "185px"}} src={jv_logo}/>
                    </Link>
                </div>

                <div style={{height: "30px"}}></div>

                <div className="verticalStack hideVerticalScrollBar" style={{position: "relative", overflowY: "scroll", height: "calc(100vh - 27px - 35px - 17px - 10px - 60px)"}}>

                    <div className="horizontalStackCenter menuLeftSection">
                        <span className="menuLeftSectionTitle">{"OVERVIEW"}</span>
                        <div style={{ borderTop: "1px solid var(--left-menu-line)", width: "100vh", marginLeft: "5px" }}></div>
                    </div>

                    <div onClick={() => this.setActualSection("instructions")}>
                        <LeftMenuItem title={"Instructions"} link="./Instructions" icon={this.isInstructionsActive(this.props.actualSection) ? instructions_white : instructions} active={this.isInstructionsActive(this.props.actualSection)} iconStyle={{height: 23}} />
                    </div>

                    <div onClick={() => this.setActualSection("dashboard")}>
                        <LeftMenuItem title={"Dashboard"} link="./" icon={this.isDashboardActive(this.props.actualSection) ? dashboard_white : dashboard} active={this.isDashboardActive(this.props.actualSection)} iconStyle={{height: 21}} />
                    </div>

                    <div className="horizontalStackCenter menuLeftSection">
                        <span className="menuLeftSectionTitle">{"MINISTRY"}</span>
                        <div style={{ borderTop: "1px solid var(--left-menu-line)", width: "100vh", marginLeft: "5px", marginRight: "5px"}}></div>
                        <div className="clickable">
                            <span className="menuLeftSectionTitle" style={{color: this.props.allOrMyMinistry == "all" && this.props.actualSection != "advancedSearch" ? "var(--jv-orange)" : "var(--white)"}} onClick={() => this.props.setAllOrMyMinistry("all")}>{"ALL"}</span>
                            <span className="menuLeftSectionTitle" style={{marginLeft: "2px", marginRight: "2px"}}>{"/"}</span>
                            <span className="menuLeftSectionTitle" style={{color: this.props.allOrMyMinistry == "my" && this.props.actualSection != "advancedSearch" ? "var(--jv-orange)" : "var(--white)"}} onClick={() => this.props.setAllOrMyMinistry("my")}>{"MY"}</span>
                        </div>
                    </div>

                    <div onClick={() => {this.setActualSection("conversations");localStorage.setItem("actualPageMyConversations", 0);localStorage.setItem("sortByMyConversations", "");localStorage.setItem("sortTpMyConversations", "asc");}}>
                        <LeftMenuItem title={"My Conversations"} link="./MyConversations" icon={this.isConversationsActive(this.props.actualSection) ? conversations_white : conversations} active={this.isConversationsActive(this.props.actualSection)} iconStyle={{height: 17, marginLeft: 3}} />
                    </div>

                    <div onClick={() => {this.setActualSection("events");localStorage.setItem("actualPageEvents", 0);localStorage.setItem("sortByEvents", "");localStorage.setItem("sortTpEvents", "asc");localStorage.setItem("quickSearchText", "");}}>
                        <LeftMenuItem title={this.props.allOrMyMinistry == "my" ? "My Events & Trainings" : "All Events & Trainings"} link="./Events" icon={this.isEventsActive(this.props.actualSection) ? events_white : events} active={this.isEventsActive(this.props.actualSection)} iconStyle={{height: 17, marginLeft: 4, marginRight: 2}} />
                    </div>

                    <div onClick={() => this.setActualSection("discipling")}>
                        <LeftMenuItem title={"My Disciple-Making"} link="./Discipling" icon={this.isDisciplingActive(this.props.actualSection) ? discipling_white : discipling} active={this.isDisciplingActive(this.props.actualSection)} iconStyle={{height: 16, marginLeft: 3}} />
                    </div>

                    <div className="horizontalStackCenter menuLeftSection">
                        <span className="menuLeftSectionTitle">{"CONTACTS"}</span>
                        <div style={{ borderTop: "1px solid var(--left-menu-line)", width: "100vh", marginLeft: "5px", marginRight: "5px"}}></div>
                        <div className="clickable">
                            <span className="menuLeftSectionTitle" style={{color: this.props.allOrMy == "all" && this.props.actualSection != "advancedSearch" ? "var(--jv-orange)" : "var(--white)"}} onClick={() => this.props.setAllOrMy("all")}>{"ALL"}</span>
                            <span className="menuLeftSectionTitle" style={{marginLeft: "2px", marginRight: "2px"}}>{"/"}</span>
                            <span className="menuLeftSectionTitle" style={{color: this.props.allOrMy == "my" && this.props.actualSection != "advancedSearch" ? "var(--jv-orange)" : "var(--white)"}} onClick={() => this.props.setAllOrMy("my")}>{"MY"}</span>
                        </div>
                    </div>

                    <div onClick={() => {this.setActualSection("people");localStorage.setItem("actualPagePeople", 0);localStorage.setItem("sortByPeople", "");localStorage.setItem("sortTpPeople", "asc");}}>
                        <LeftMenuItem title={this.props.allOrMy == "my" ? "My People" : "All People"} link="./People" icon={this.isPeopleActive(this.props.actualSection) ? people_white : people} active={this.isPeopleActive(this.props.actualSection)} iconStyle={{height: 20, marginLeft: 2}}/>
                    </div>

                    <div onClick={() => {this.setActualSection("churches");localStorage.setItem("actualPageChurches", 0);localStorage.setItem("sortByChurches", "");localStorage.setItem("sortTpChurches", "asc");}}>
                        <LeftMenuItem title={this.props.allOrMy == "my" ? "My Churches" : "All Churches"} link="./Churches" icon={this.isChurchesActive(this.props.actualSection) ? churches_white : churches} active={this.isChurchesActive(this.props.actualSection)} iconStyle={{height: 18, marginLeft: 3}} />
                    </div>

                    {/*<div onClick={() => {this.setActualSection("schools");localStorage.setItem("actualPageSchools", 0);localStorage.setItem("sortBySchools", "");localStorage.setItem("sortTpSchools", "asc");}}>
                        <LeftMenuItem title={this.props.allOrMy == "my" ? "My Schools" : "All Schools"} link="./Schools" icon={this.isSchoolsActive(this.props.actualSection) ? schools_white : schools} active={this.isSchoolsActive(this.props.actualSection)} iconStyle={{height: 18, marginLeft: 3}} />
                    </div>*/}

                    <div onClick={() => this.props.setLeftMenuOpened("false")}>
                        <div className="closeLeftMenuButton"></div>
                    </div>

                </div>
            </div>
        );
	}
}

export default LeftMenu;