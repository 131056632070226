import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { Link, Navigate } from "react-router-dom";
import Axios from 'axios';
import Moment from 'react-moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { showSortIcon } from '../utilities/OtherUtilities.js';

// import assets
import icon_search from '../assets/icon_search.png';

// import components
import ProfileImageSmall from '../components/ProfileImageSmall.js';

class QuickTableMobile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            selectedItems: [],
            showPopupTableActions: false,
        }

        this.refPopupTableActions = React.createRef();
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
    }

    openProfilePage = (event, id, cont_type, is_private, open_tile) => {
        var openTileText = "";
        if(open_tile != ""){
            openTileText = "?tile="+open_tile;
        }

        if(cont_type == 4){
            if(event.ctrlKey || event.metaKey){ window.open("/ChurchProfile/"+id+""+openTileText, '_blank', 'noreferrer'); }
            else { this.setState({ redirectTo: "/ChurchProfile/"+id+""+openTileText }); }
        }
        else if(cont_type == 5){
            if(event.ctrlKey || event.metaKey){ window.open("/DenominationProfile/"+id, '_blank', 'noreferrer'); }
            else { this.setState({ redirectTo: "/DenominationProfile/"+id }); }
        }
        else if(cont_type == 6){ // team
            if(event.ctrlKey || event.metaKey){ window.open("/TeamProfile/"+id, '_blank', 'noreferrer'); }
            else { this.setState({ redirectTo: "/TeamProfile/"+id }); }
        }
        else if(cont_type == 7){ // school
            if(event.ctrlKey || event.metaKey){ window.open("/SchoolProfile/"+id, '_blank', 'noreferrer'); }
            else { this.setState({ redirectTo: "/SchoolProfile/"+id }); }
        }
        else if(cont_type == 1){

            if(is_private == 1){
                if(event.ctrlKey || event.metaKey){ window.open("/PeoplePrivateProfile/"+id+""+openTileText, '_blank', 'noreferrer'); }
                else { this.setState({ redirectTo: "/PeoplePrivateProfile/"+id+""+openTileText }); }
            }
            else {
                if(event.ctrlKey || event.metaKey){ window.open("/PeopleProfile/"+id+""+openTileText, '_blank', 'noreferrer'); }
                else { this.setState({ redirectTo: "/PeopleProfile/"+id+""+openTileText }); }
            }
        }
        else {
            alert("Error");
        }
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;
        if(bottom){
            this.props.loadMoreData();
        }
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

        return (
            <div style={{height: "100%", width: "100%"}}>
                <div className="myPeopleTileMobile">
                    <div onScroll={this.handleScroll} style={{width: this.props.leftMenuOpened == "true" ? "calc(100% - 240px)" : "calc(100% - 60px)", height: "calc(var(--doc-height) - 80px)", position: "absolute", overflowX: "auto", overflowY: "auto"}}> {/* 240px including the padding */}
                        <table className="myPeopleTableMobile">
                            <tr>
                                <td className="myPeopleTableHeader myPeopleTableActionHeaderMobile">
                                    <div className="horizontalStackCenter centerAll">
                                        <img style={{width: 21}} src={icon_search}/>
                                    </div>
                                </td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderRole" onClick={() => this.props.changeSorting("nm")}>Name {showSortIcon("nm",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("ins_at")}>Date created {showSortIcon("ins_at",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("em")}>Email {showSortIcon("em",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("addr")}>Address {showSortIcon("addr",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("ph")}>Phone {showSortIcon("ph",this.props.sortBy,this.props.sortTp)}</td>
                            </tr>

                            {this.props.resultData.map(function (item, index) {
                                return (
                                    <tr key={index} className="myPeopleTableRow">

                                        <td className="myPeopleTableFirstFixedColumnMobile">
                                            <div className="horizontalStackCenter">
                                                <div className="flex"></div>
                                                <ProfileImageSmall
                                                    name={item.rcg_name}
                                                    uploadedImageBase64={item.image ? item.image : ""}
                                                />
                                                <div className="flex"></div>
                                            </div>
                                        </td>

                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openProfilePage(event, item.id,item.cont_tp_id,item.is_private,"")}>{item.rcg_name}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openProfilePage(event, item.id,item.cont_tp_id,item.is_private,"")}><Moment format="MMMM D, YYYY">{item.ins_at}</Moment></td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openProfilePage(event, item.id,item.cont_tp_id,item.is_private,"")}>{item.email}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openProfilePage(event, item.id,item.cont_tp_id,item.is_private,"")}>{item.city}{item.city == null || item.addr_country == null ? "" : ", "}{item.addr_country}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openProfilePage(event, item.id,item.cont_tp_id,item.is_private,"")}>{item.phone}</td>
                                    </tr>
                                )
                            }.bind(this))}

                            { this.props.showLoadingRow ? <tr>
                                <td className="myPeopleTableGeneralDiv" colspan={7}>
                                    <span>{"Loading more records..."}</span>
                                </td>
                            </tr> : null }

                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default QuickTableMobile;
