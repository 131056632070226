import React, { useState, useEffect, useMemo } from "react";
import Moment from 'react-moment';

// import assets
import icon_action_down from '../assets/icon_action_down.png';
import icon_action_up from '../assets/icon_action_up.png';

export function getInitials(name) {
    if(name && name.length > 0 && name != " "){
        const wordsArray = name.split(" ");
        //if(wordsArray.length)
        //alert(JSON.stringify(wordsArray));
        var initials = "";
        var index = 0;
        for(let item of wordsArray){
            if(index >= 2){break;}
            if(item.length > 0){
                initials = initials+""+Array.from(item)[0].toUpperCase();
                index = index+1;
            }
        }
        return initials;
    }
    return "";
}

export function showMobileVersion() {
    if(window.innerWidth < 1000){
        return true;
    }
    return false;
}

export function deviceWidthLowerThan(value) {
    if(window.innerWidth < value){
        return true;
    }
    return false;
}

export function showSortIcon(attribute, sortBy, sortTp) {
    if(attribute == sortBy){
        if(sortTp == "asc")return(<img style={{width: "10px", height: "6.3px", marginLeft: 3, marginBottom: 2}} src={icon_action_down}/>);
        else if(sortTp == "desc")return(<img style={{width: "10px", height: "6.3px", marginLeft: 3, marginBottom: 2}} src={icon_action_up}/>);
    }
}

export function getTodaysDate() {
    var dt = new Date();

    var year  = dt.getFullYear();
    var month = (dt.getMonth() + 1).toString().padStart(2, "0");
    var day   = dt.getDate().toString().padStart(2, "0");

    return(year + '-' + month + '-' + day);
}

export function showNiceDateRange(from_dt, till_dt){
    const from_date = new Date(from_dt);
    const from_year = from_date.getFullYear();
    const from_month = from_date.getMonth();
    const from_day = from_date.getDate();

    const till_date = new Date(till_dt);
    const till_year = till_date.getFullYear();
    const till_month = till_date.getMonth();
    const till_day = till_date.getDate();

    if(from_month == till_month){
        if(from_day == till_day && from_year == till_year){
            // for one-day events do not show range of dates
            return <Moment format="MMM D, YYYY">{from_dt}</Moment>
        }
        // do not repeat same month twice
        return <div><Moment format="MMM D">{from_dt}</Moment>{"-"}<Moment format="D, YYYY">{till_dt}</Moment></div>
    }
    else {
        // show range of two dates
        return <div><Moment format="MMM D">{from_dt}</Moment>{"-"}<Moment format="MMM D, YYYY">{till_dt}</Moment></div>
    }
}

export const fetchContactImage = async (contact,prefix) => {
    try {
        const response = await fetch(process.env.REACT_APP_BASE_URL+'/api/file/load?file_prefix='+prefix+'&cont_id='+contact.id);
        const receivedData = await response.text();
        if(receivedData.includes("ERR") && receivedData.includes("is_ok")) return "";
        else return receivedData;
    }
    catch (e) {
        return "";
    }
}