import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { Link, Navigate } from "react-router-dom";
import Axios from 'axios';
import Moment from 'react-moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { getInitials, showSortIcon } from '../utilities/OtherUtilities.js';

// import assets
import icon_message from '../assets/icon_message.png';
import icon_action_down from '../assets/icon_action_down.png';
import person_avatar from '../assets/person_avatar.png';

// import components
import ProfileImageSmall from '../components/ProfileImageSmall.js';

class MyChurchesTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            selectedItems: [],
            showPopupTableActions: false,
        }

        this.refPopupTableActions = React.createRef();
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
        window.addEventListener('click', this.onGlobalClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onGlobalClick);
    }

    /* This method is triggered on every click on screen and is checking if some popup is opened nad user clicked out of this popup so it would close that popup in that situation */
    onGlobalClick = (e) => {
        if ( (!this.refPopupTableActions.current.contains(e.target)) && this.state.showPopupTableActions) {
            // user clicked out of the PopupTableActions and that popup is now opened
            this.setState({ showPopupTableActions: false })
        }
    }

    openChurchProfilePage = (event, id, openTile) => {
        var openTileText = "";
        if(openTile != ""){
            openTileText = "?tile="+openTile;
        }

        if(event.ctrlKey || event.metaKey){ window.open("/ChurchProfile/"+id+""+openTileText, '_blank', 'noreferrer'); }
        else { this.setState({ redirectTo: "/ChurchProfile/"+id+""+openTileText }); }
    }

    openConversationsOfContact = (event, id) => {
        this.openChurchProfilePage(event, id, "conversations");
    }

    selectUnselectItem = (id) => {
        if(this.isRowSelected(id)){
            // delete
            let remainingItems = this.state.selectedItems.filter((item) => {return item !== id});
            this.setState({ selectedItems: remainingItems });
        }
        else {
            // add
            this.setState({
                selectedItems: [...this.state.selectedItems, id]
            })
        }
    }

    isRowSelected = (id) => {
        for(let oneRow of this.state.selectedItems){
            if(oneRow == id){
                return true;
            }
        }
        return false;
    }

    tableActionSelectAll = () => {
        var workArray = [];
        for(let oneRow of this.props.churchesArray){
            workArray.push(oneRow.id);
        }
        this.setState({ selectedItems: workArray });
    }

    tableActionDeselectAll = () => {
        this.setState({ selectedItems: [] });
    }

    addSelectedToMyContacts = () => {
        if(this.state.selectedItems.length <= 0){ return; }

        Axios.post('/api/my_cont/ins/', {
            ContId: this.state.selectedItems,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.props.openAlertMessage("Contacts were added to \"My Contacts\".","OK");
            this.props.loadData();
        });
    }

    removeSelectedFromMyContacts = () => {
        if(this.state.selectedItems.length <= 0){ return; }

        Axios.delete('/api/my_cont/del/', {
            data: {ContId: this.state.selectedItems},
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.props.openAlertMessage("Contacts were removed from \"My Contacts\".","OK");
            this.props.loadData();
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

        return (
            <div style={{height: "calc(100% - 60px)", width: "100%"}}>
                <div className="myPeopleTile">
                    <div style={{width: this.props.leftMenuOpened == "true" ? "calc(100% - 240px - 80px)" : "calc(100% - 90px - 80px)", height: "calc(100% - 80px - 120px)", position: "absolute", overflowX: "auto", overflowY: "auto"}}> {/* 240px including the padding */}
                        <table className="myPeopleTable">
                            <tr>
                                <td className="myPeopleTableHeader myPeopleTableActionHeader">

                                    <div className="universalPopupSelectContainer" ref={this.refPopupTableActions}>
                                        <div className="universalPopupSelectVisible" onClick={() => this.setState({ showPopupTableActions: !this.state.showPopupTableActions })}>
                                            <span>Action</span>
                                            <img className="myPeopleAvatarImage" style={{width: "10px", height: "6.3px"}} src={icon_action_down}/>
                                        </div>

                                        <div className="universalPopupSelectHidden" style={{display: this.state.showPopupTableActions ? "" : "none", position: "fixed", backgroundColor: "white", borderRadius: 20, height: "auto", marginLeft: -50, marginTop: -35, padding: 15, boxShadow: "0px 10px 20px var(--shadow-color)"}}>
                                            <div className="verticalStack" style={{alignItems: "flex-end"}}>
                                                <span className="fontPoppinsItalic13Orange" onClick={() => this.setState({ showPopupTableActions: false })}>Action</span>
                                                <div style={{width: "calc(100% + 30px)", height: 1, marginLeft: -15, marginRight: -15, backgroundColor: "var(--border-light-gray)", marginTop: 5, marginBottom: 10}}></div>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginBottom: 2}} onClick={() => this.tableActionSelectAll()}>Select All</span>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => this.tableActionDeselectAll()}>Deselect All</span>
                                                { this.props.allOrMy != "my" ? <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => this.addSelectedToMyContacts()}>Add to My Contacts</span> : null }
                                                { this.props.allOrMy == "my" ? <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => this.removeSelectedFromMyContacts()}>Remove from My Contacts</span> : null }
                                                {/*<span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => alert("This function is coming soon.")}>Add Conversation</span>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => alert("This function is coming soon.")}>Send Email</span>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => alert("This function is coming soon.")}>View on Map</span>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => alert("This function is coming soon.")}>View on Dashboard</span>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2, marginBottom: 2}} onClick={() => alert("This function is coming soon.")}>Merge</span>
                                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange" style={{marginTop: 2}} onClick={() => alert("This function is coming soon.")}>Export & Download</span>*/}
                                                <div className="flex"></div>
                                            </div>
                                        </div>

                                    </div>

                                </td>
                                <td className="myPeopleTableHeader myPeopleTableNameHeader" onClick={() => this.props.changeSorting("nm")}>Name {showSortIcon("nm",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderRole" onClick={() => this.props.changeSorting("ins_at")}>Date created {showSortIcon("ins_at",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("deno")}>Denomination {showSortIcon("deno",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("region")}>Region {showSortIcon("region",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("pastor")}>Lead Pastor {showSortIcon("pastor",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("yldr")}>Youth Pastor {showSortIcon("yldr",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("ph")}>Phone {showSortIcon("ph",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("addr")}>Address {showSortIcon("addr",this.props.sortBy,this.props.sortTp)}</td>
                            </tr>

                            {this.props.churchesArray.map(function (item, index) {
                                return (
                                    <tr key={index} className="myPeopleTableRow">

                                        <td className="myPeopleTableFirstFixedColumn">
                                            <div className="horizontalStackCenter centerAll">
                                                <input type="checkbox" checked={this.isRowSelected(item.id) ? "checked" : ""} onClick={() => this.selectUnselectItem(item.id)} />
                                                <div style={{width: "15px"}}></div>
                                                <div className="verticalStackCenter horizontalStackCenter myPeopleMessageButton" onClick={(event) => this.openConversationsOfContact(event, item.id)}>
                                                    <img className="myPeopleMessageImage" src={icon_message}/>
                                                </div>
                                            </div>
                                        </td>

                                        <td className="horizontalStackCenter myPeopleTableName myPeopleTableNameDiv myPeopleTableSecondFixedColumn" nowrap="nowrap" onClick={(event) => this.openChurchProfilePage(event, item.id, "")}>
                                                {/* cell.row.original.avatar.default */}
                                                {/*<img className="myPeopleAvatarImage" src={item.avatar}/>*/}
                                                {/*<img className="myPeopleAvatarImage" src={person_avatar}/>*/}

                                                <ProfileImageSmall
                                                    name={item.org_name}
                                                    uploadedImageBase64={item.image ? item.image : ""}
                                                />

                                                <span style={{paddingLeft: "25px"}}>{item.org_name}</span>
                                        </td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openChurchProfilePage(event, item.id, "")}><Moment format="MMMM D, YYYY">{item.ins_at}</Moment></td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openChurchProfilePage(event, item.id, "")}>{item.denomination}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openChurchProfilePage(event, item.id, "")}>{item.region}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openChurchProfilePage(event, item.id, "")}>{item.pastor_name}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openChurchProfilePage(event, item.id, "")}>{item.yldr_name}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openChurchProfilePage(event, item.id, "")}>{item.phone}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openChurchProfilePage(event, item.id, "")}>{item.city}{item.city == null || item.addr_country == null ? "" : ", "}{item.addr_country}</td>
                                    </tr>
                                )
                            }.bind(this))}

                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default MyChurchesTile;
