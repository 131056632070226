import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles_m/tiles_m.css';
import '../styles/other.css';
import { useLocation, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';

// import assets
import background from '../assets/background.jpg';
import icon_add from '../assets/icon_add.png';
import icon_done from '../assets/icon_done.png';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { getInitials } from '../utilities/OtherUtilities.js';

// import components
import ExpandButton from '../components/ExpandButton.js';
import ProfileImageMobile from '../components_m/ProfileImageMobile.js';
import ProfileChurchesModalMobile from '../modals_m/ProfileChurchesModalMobile.js';

class ProfileTileChurchMobile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            updateVar: true, // I am saving here value from last updateVar I received and then in render if this variable changes from what I get from parent, then I update data
            mainContactId: 0,
            updateExisting: true, // if I am in create new contact mode it would be false
            isMyContact: false, // default should be false
            actualProfile: ([{id:1,short_name:"",org_name:"",email:"",phone:"",church:"Error",city:"",country:"",country_id:"",nick_name:"",is_plant:-1,has_ygrp:0}]),
            actualProfileEmails: ([{email: "", email_tp_id: 0, is_primary: 1},{email: "", email_tp_id: 0, is_primary: 0}]),
            actualProfilePhones: ([{phone: "", phone_tp_id: 0, is_primary: 1},{phone: "", phone_tp_id: 0, is_primary: 0}]),
            actualProfileWebsites: ([{website: "", website_tp_id: 0},{website: "", website_tp_id: 0}]),
            uploadedImageBase64: "",
            prepareToShowModalProfileChurches: false,
            showModalProfileChurches: false,
        }

        this.hiddenFileInput = React.createRef();
    }

    async componentDidMount(){
        this.setState({ updateVar: this.props.updateVar });
        //const queryParams = new URLSearchParams(window.location.search);
        //const id = queryParams.get('id');
        this.loadData();
    }

    loadData = async () => {
        await this.setState({ uploadedImageBase64: "" });

        const pathname = this.props.location.pathname;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))){ // if there is number received from URL
            if(Number(id) == 0){
                if(window.history.length > 1) navigate(-1);
                else { navigate("/Churches"); window.location.reload(); }
                return;
            }
            await Axios.get('/api/cont/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    this.props.openAlertMessage("You don't have rights to this contact.","OK");
                    navigate(-1);
                }
                else {
                    this.setState({ actualProfile: response.data.result });
                    this.setState({ isMyContact: response.data.result[0].my_cont });
                }
            });
            this.setState({ updateExisting: true });
            this.setState({ mainContactId: id });

            // get avatar image of contact
            Axios.get('/api/file/load?file_prefix=avatar&cont_id='+id).then(response => {
                //if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.stat && response.data.stat == "ERR"){
                    // avatar image was not loaded
                }
                else this.setState({ uploadedImageBase64: response.data });
                //alert(JSON.stringify(response.data))
            });

            // get array of emails
            Axios.get('/api/email/get?cont_id='+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    response.data.result.push({email: "", email_tp_id: 0, is_primary: 1});
                    response.data.result.push({email: "", email_tp_id: 0, is_primary: 0});
                }
                else if(response.data.result.length == 1){
                    response.data.result.push({email: "", email_tp_id: 0, is_primary: 0});
                }
                this.setState({ actualProfileEmails: response.data.result })
            });

            // get array of phones
            Axios.get('/api/phone/get?cont_id='+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    response.data.result.push({phone: "", phone_tp_id: 0, is_primary: 1});
                    response.data.result.push({phone: "", phone_tp_id: 0, is_primary: 0});
                }
                else if(response.data.result.length == 1){
                    response.data.result.push({phone: "", phone_tp_id: 0, is_primary: 0});
                }
                this.setState({ actualProfilePhones: response.data.result })
            });

            // get array of websites
            Axios.get('/api/website/get?cont_id='+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    response.data.result.push({website: "", website_tp_id: 0});
                    response.data.result.push({website: "", website_tp_id: 0});
                }
                else if(response.data.result.length == 1){
                    response.data.result.push({website: "", website_tp_id: 0});
                }
                this.setState({ actualProfileWebsites: response.data.result })
            });

        }
        else {
            // not editing but creating new contact
            this.setState({ updateExisting: false });
        }
    }

    addToMyContacts = () => {
        if(this.state.isMyContact){
            // delete
            Axios.delete('/api/my_cont/del/', {
                data: {ContId: this.state.mainContactId},
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ isMyContact: false });
            });
        }
        else {
            // add
            Axios.post('/api/my_cont/ins/', {
                ContId: this.state.mainContactId,
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ isMyContact: true });
            });
        }
    }

    getPrimaryEmail = () => {
        let primaryEmail = "";
        for(var i = 0; i < this.state.actualProfileEmails.length; i++) {
            if(this.state.actualProfileEmails[i].is_primary == 1){
                primaryEmail = this.state.actualProfileEmails[i].email;
            }
        }
        return primaryEmail;
    }

    getPrimaryPhone = () => {
        let primaryPhone = "";
        for(var i = 0; i < this.state.actualProfilePhones.length; i++) {
            if(this.state.actualProfilePhones[i].is_primary == 1){
                primaryPhone = this.state.actualProfilePhones[i].phone;
            }
        }
        return primaryPhone;
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "EditProfileChurches"){
            await this.setState({ prepareToShowModalProfileChurches: true });
            this.setState({ showModalProfileChurches: true });
        }
    }

    closeModal = async (modalWindow, createAnotherNewAfter) => {
        // close the modal
        if(modalWindow == "EditProfileChurches"){
            this.setState({ showModalProfileChurches: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalProfileChurches: false });
        }
        this.loadData();
    }

    openLink = (link) => {
        if(link && link.length > 0){
            link = link.match(/^https?:/) ? link : '//' + link;
            window.open(link, "_blank", "noreferrer");
        }
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }
        else if(this.state.updateVar != this.props.updateVar){
            this.setState({ updateVar: this.props.updateVar });
            this.loadData();
        }

        return (
            <div className="profileTileMobile">
                <div className="flex" style={{flex: 1, position: "relative"}}>

                    <div style={{width: "calc(100% + 40px)", backgroundColor: "var(--full-black)", height: "80px", position: "absolute", zIndex: 0, marginLeft: "-20px", marginTop: "-20px", borderRadius: "20px 20px 0px 0px"}}>
                        <img style={{width: "100%", height: "100%", borderRadius: "25px 25px 0px 0px", objectFit: "cover", opacity: 1}} src={background}/>
                    </div>

                    <div className="horizontalStackCenter" style={{height: "100%"}}>

                        <div className="verticalStack" style={{zIndex: "0", marginTop: "0px", marginRight: "15px"}}>
                            <div className="flex"></div>

                            <ProfileImageMobile
                                initials={getInitials(this.state.actualProfile[0].org_name)}
                                uploadedImageBase64={this.state.uploadedImageBase64}
                                uploadImageClick={() => alert("Not implemented")}
                                deleteImageClick={() => alert("Not implemented")}
                                contactType={"church"}
                            />

                            <div className="flex"></div>
                        </div>

                        <div className="verticalStack" style={{zIndex: "0", height: "100%", marginTop: -80}}>
                            <div className="responsiveThreeDotsContainer">
                                <span className="fontPoppinsMedium20White responsiveThreeDots">{this.state.actualProfile[0].short_name}</span>
                            </div>
                            <div className="flex"></div>
                        </div>


                    </div>

                    <div className="verticalStackCenter">

                        {this.state.actualProfileWebsites[0].website && this.state.actualProfileWebsites[0].website.length > 0 ? <div className="responsiveThreeDotsContainer">
                            <span className="fontPoppinsMedium15DarkGray responsiveThreeDots" onClick={() => this.openLink(this.state.actualProfileWebsites[0].website)}>{this.state.actualProfileWebsites[0].website}</span>
                        </div> : null}

                        {this.state.actualProfileWebsites[1].website && this.state.actualProfileWebsites[1].website.length > 0 ? <div className="responsiveThreeDotsContainer">
                            <span className="fontPoppinsMedium15DarkGray responsiveThreeDots" onClick={() => this.openLink(this.state.actualProfileWebsites[1].website)}>{this.state.actualProfileWebsites[1].website}</span>
                        </div> : null}

                    </div>

                    <div>
                        <div className="horizontalStack flex" style={{backgroundColor: "white", paddingTop: 10, overflowX: "visible", display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: 20}}>

                            {this.getPrimaryEmail() == "" ? null :
                                <div className="responsiveThreeDotsContainer">
                                    <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.getPrimaryEmail() == "" ? "Email" : this.getPrimaryEmail()}</span>
                                </div>
                            }

                            {this.getPrimaryPhone() == "" ? null :
                                <div className="responsiveThreeDotsContainer">
                                    <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.getPrimaryPhone() == "" ? "Phone" : this.getPrimaryPhone()}</span>
                                </div>
                            }

                            {this.state.actualProfile[0].denomination == undefined ? null :
                                <div className="responsiveThreeDotsContainer">
                                    <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].denomination != undefined ? this.state.actualProfile[0].denomination : "Denomination"}</span>
                                </div>
                            }

                            {this.state.actualProfile[0].city == "" || this.state.actualProfile[0].city == undefined ? null :
                                <div className="responsiveThreeDotsContainer">
                                    <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].city == null || this.state.actualProfile[0].city == "" ? "City" : this.state.actualProfile[0].city}{this.state.actualProfile[0].addr_country == null || this.state.actualProfile[0].addr_country == "" ? "" : ", "+this.state.actualProfile[0].addr_country}</span>
                                </div>
                            }

                            {this.state.actualProfile[0].region == "" || this.state.actualProfile[0].region == undefined ? null :
                                <div className="responsiveThreeDotsContainer">
                                    <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].region == null || this.state.actualProfile[0].region == "" ? "Region" : this.state.actualProfile[0].region}</span>
                                </div>
                            }

                            {this.state.actualProfile[0].is_plant == -1 ? null :
                                <div className="responsiveThreeDotsContainer">
                                    <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].is_plant == null || this.state.actualProfile[0].is_plant == -1 ? "Church Status" : this.state.actualProfile[0].is_plant == 1 ? "Church Plant" : this.state.actualProfile[0].is_plant == 0 ? "Est. Church" : "Church Status"}</span>
                                </div>
                            }

                        </div>

                        <div style={{height: "20px"}}></div>

                        { this.state.isMyContact == false ?
                            <div className="horizontalStackCenter" onClick={this.addToMyContacts}>
                                <div className="circleButtonContainer circleButtonContainerBlue clickable">
                                    <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add}/>
                                </div>
                                <span className="fontPoppinsSemiBold15" style={{marginLeft: 20}}>{"Add to My Contacts"}</span>
                            </div>
                        :
                            <div className="horizontalStackCenter" onClick={this.addToMyContacts}>
                                <div className="circleButtonContainer circleButtonContainerBlue clickable">
                                    <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_done}/>
                                </div>
                                <span className="fontPoppinsSemiBold15" style={{marginLeft: 20}}>{"Added to My Contacts"}</span>
                            </div>
                        }

                        <div style={{height: "20px"}}></div>

                        <div className="horizontalStackCenter" onClick={() => this.openModal("EditProfileChurches")}>
                            <ExpandButton isOpen={false} style={{marginTop: 5}} />
                            <span className="fontPoppinsSemiBold15" style={{marginLeft: 20}}>{"Edit Contact Info"}</span>
                        </div>

                    </div>
                </div>

                {this.state.prepareToShowModalProfileChurches ? <ProfileChurchesModalMobile showModal={this.state.showModalProfileChurches} closeModal={this.closeModal} contactId={this.state.mainContactId} actionType={"EditProfileChurches"} openAlertMessage={this.props.openAlertMessage} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }

            </div>
        );
    }
}

const withLocation = Component => props => {
    const location = useLocation();

    return <Component {...props} location={location} />;
};

export default withLocation(ProfileTileChurchMobile);
