import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/components.css';

// import assets
import icon_arrow_left_black from '../assets/icon_arrow_left_black.png';
import icon_arrow_left_white from '../assets/icon_arrow_left_white.png';
import icon_arrow_bottom_black from '../assets/icon_arrow_bottom_black.png';
import icon_arrow_bottom_white from '../assets/icon_arrow_bottom_white.png';

class PopupButtonDescription extends React.Component {

    constructor(props) {
        super(props);
    }

    render(){
        if(this.props.from == "top"){
            return(
                <div className="verticalStackCenter" style={{marginLeft: this.props.marginLeft ? this.props.marginLeft : "0px"}}>
                    <div className="popupButtonDescription" style={{marginBottom: "-12px"}}>
                        <span className="fontPoppinsRegular13AlmostBlack">{this.props.title}</span>
                    </div>
                    <div className="horizontalStack flex">
                        <div className="flex"></div>
                        <img className="circleButton" style={{width: "14.24px", height: "28.11px"}} src={this.props.blackArrow ? icon_arrow_bottom_black : icon_arrow_bottom_white}/>
                        <div className="flex"></div>
                    </div>
                </div>
            );
        }
        else if(this.props.from == "left") {
            return(
                <div className="horizontalStackCenter" style={{height: "100%", marginLeft: this.props.marginLeft ? this.props.marginLeft: "0px"}}>
                    <div className="popupButtonDescription">
                        <span className="fontPoppinsRegular13AlmostBlack">{this.props.title}</span>
                    </div>
                    <img className="circleButton" style={{width: "28.11px", height: "14.24px", rotate: "180deg", marginLeft: "-13px"}} src={this.props.blackArrow ? icon_arrow_left_black : icon_arrow_left_white}/>
                </div>
            );
        }
        else {
            return(
                <div className="horizontalStackCenter" style={{height: "100%"}}>
                    <img className="circleButton" style={{width: "28.11px", height: "14.24px", marginRight: "-13px"}} src={this.props.blackArrow ? icon_arrow_left_black : icon_arrow_left_white}/>
                    <div className="popupButtonDescription">
                        <span className="fontPoppinsRegular13AlmostBlack">{this.props.title}</span>
                    </div>
                </div>
            );
        }
	}
}

export default PopupButtonDescription;