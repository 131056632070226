import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import Axios from 'axios';
import MapComponent from '../components/MapComponent.js';
import Select, { StylesConfig } from 'react-select';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { getDeviceType, getOperatingSystem, getBrowser } from '../utilities/DeviceDataUtilities.js';

// import components
import EmptyChurchInfoTile from '../tiles/EmptyChurchInfoTile.js';
import EmptyDiagnosticTile from '../tiles/EmptyDiagnosticTile.js';
import EmptyClosedTile from '../tiles/EmptyClosedTile.js';
import ExpandButton from '../components/ExpandButton.js';
import icon_add_white from '../assets/icon_add_white.png';
import PopupButtonDescription from '../components/PopupButtonDescription.js';
import EditButtonDropdown from '../components/EditButtonDropdown.js';


const customStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid var(--border-light-gray)',
        backgroundColor: "white",
        fontFamily: "PoppinsRegular",
        color: 'black',
        fontSize: 13,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 15,
        paddingRight: 15,
        ':hover': {
            backgroundColor: "white",
            color: 'var(--jv-orange)',
            cursor: "pointer",
        },
        ':last-child': {
            borderBottom: '0px solid var(--border-light-gray)',
        },
    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 250,
        backgroundColor: "white",
        display: "flex",
        flexDirection: "row",
    }),
    multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: "white",
          borderRadius: 5,
          padding: 0,
          paddingLeft: 1,
          paddingRight: 5,
          border: "1px solid black",
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        fontFamily: "PoppinsRegular",
        padding: 0,
        color: 'black',
        fontSize: 13,
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: "black",
        padding: 0,
        ':hover': {
            backgroundColor: data.color,
            color: 'var(--jv-orange)',
            cursor: "pointer",
        },
    }),
    input: (styles) => ({
        ...styles,
        backgroundColor: "white",
        fontFamily: "PoppinsRegular",
        color: 'black',
        fontSize: 13,
    }),
    noOptionsMessage: (styles) => ({
        ...styles,
        fontFamily: "PoppinsRegular",
        color: 'black',
        fontSize: 13,
    }),
    valueContainer: (styles) => ({ /* container where chosen items are shown */
        ...styles,
        backgroundColor: "white",
        padding: 5,
    }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: "white",
        marginTop: 1,
        borderRadius: "0px",
        border: "0px solid black",
    }),
    menuList: (styles) => ({
        ...styles,
        backgroundColor: "white",
        borderRadius: "0px",
        border: "0px solid black",
    }),
    menuPortal: (styles) => ({
        ...styles,
        backgroundColor: "white",
        borderRadius: 0,
        border: "0px solid black",
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        backgroundColor: "white",
        width: 0,
        height: 0,
    }),
    indicatorsContainer: (styles) => ({
        ...styles,
        backgroundColor: "var(--light-purple-gray)",
    }),
    dropdownIndicator: (styles) => ({
        ...styles,
        color: "white",
    }),
    clearIndicator: (styles) => ({
        ...styles,
        display: "none",
    }),
}


class Testing extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            address: "",
            response: "nothing",
            selectArray: ([{value:'1',label:'One'},{value:'2',label:'Two'},{value:'3',label:'Three'},{value:'4',label:'Four'},{value:'5',label:'Five'}]),
            listOfCountries: ([{"value":1,"label":"ALL"},{"value":2,"label":"Afghanistan"},{"value":3,"label":"Aland Islands"}]),
            listOfSelectedCountries: ([]),
            isOpen: false,
            usersArray: ([{id: 1, name: "Jan", surname: "Novák", position: "Programátor", departement: "Vývoj", age: 30, city: "Praha", email: "jan.novak@example.com", phone: "123 456 789", start_date: "01.01.2020", salary: "50 000"}])
        }

    }

    componentDidMount(){
        this.loadData();
    }

    loadData = async () => {
        //Axios.get('/api/cont/get?tp=1&limit='+this.state.recordsOnOnePage+'&offset='+(this.state.actualPage*this.state.recordsOnOnePage)+'&search='+this.props.searchText).then(response => {
        //    if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
        //    this.setState({ peopleData: response.data.result })
        //});

        await Axios.get('/api/perm_cntry/get/').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }

            var listOfCountries = [];
            for(let oneRow of response.data.result){ listOfCountries.push({"value":oneRow.id, "label": oneRow.descr}); }
            this.setState({ listOfCountries: listOfCountries })
        });

        this.getDataFromUser();
    }

    getDataFromUser = async () => {
        this.setState({ windowHeight: window.innerHeight });
        this.setState({ windowWidth: window.innerWidth });

        /*const response = await fetch('https://geolocation-db.com/json/');
        // country code, country name, city, longitude, latitude, IPv4 (for Jihlava it gives me that I am in Brno)
        const locationData = await response.json();
        this.setState({ locationData: JSON.stringify(locationData) });*/

        const response = await fetch('https://ipapi.co/json/');
        const locationData = await response.json();
        this.setState({ ipAddress: locationData.ip });
        this.setState({ city: locationData.city });
        this.setState({ region: locationData.region });
        this.setState({ country: locationData.country });
        this.setState({ organisation: locationData.org });
        //alert(JSON.stringify(locationData.ip));


        //var effectiveBandwidth = navigator.connection.downlink; // if faster than 10MBit it still shows 10MBit

        // informations about architecture, bitness, OS, OS version (not supported by Firefox and Safari)
        //var platformDetails = await navigator.userAgentData.getHighEntropyValues(["architecture", "platform", "platformVersion", "model", "bitness", "uaFullVersion"]);
        //alert(JSON.stringify(platformDetails));

    }

    onClick = async () => {
        /*var xhr = new XMLHttpRequest();
        xhr.addEventListener('load', () => {

            var myObject = JSON.parse(xhr.responseText);
            var array = myObject.results[0].address_components;

            for(let oneComponent of array){
                for(let oneType of oneComponent.types){
                    if(oneType == "administrative_area_level_1"){
                        this.setState({ response: oneComponent.long_name });
                    }
                }
            }
            //console.log(myObject.results[0]);

        });
        xhr.open('GET', 'https://maps.googleapis.com/maps/api/geocode/json?address='+this.state.address+'&key=AIzaSyCzFI1LkEV1yumNDTBf6_uBn3MnmgVFqyM');
        xhr.send();*/
        alert("action");
    }

    getSelected = () => {
        var list = "";

        for(let oneRow of this.state.listOfSelectedCountries){
            list = list + oneRow.label + ", ";
        }

        alert(list);
    }

    openCloseTile = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        return (
            <div>
                <span style={{fontSize: "30px"}}>Simple testing page</span>

                {/*<br/><br/>

                <span><b>{"Browser: "}</b>{getBrowser(navigator.userAgent)}</span>
                <br/>
                <span><b>{"Window Height: "}</b>{this.state.windowHeight}{"px"}</span>
                <br/>
                <span><b>{"Window Width: "}</b>{this.state.windowWidth}{"px"}</span>
                <br/>
                <span><b>{"IP address: "}</b>{this.state.ipAddress}</span>
                <br/>
                <span><b>{"City: "}</b>{this.state.city}</span>
                <br/>
                <span><b>{"Region: "}</b>{this.state.region}</span>
                <br/>
                <span><b>{"Country: "}</b>{this.state.country}</span>
                <br/>
                <span><b>{"Provider: "}</b>{this.state.organisation}</span>
                <br/>
                <span><b>{"Device type: "}</b>{getDeviceType(navigator.userAgent)}</span>
                <br/>
                <span><b>{"Operating System: "}</b>{getOperatingSystem(navigator.userAgent)}</span>
                <br/>
                <span>{"* pro získání IP a lokalizace používám IPAPI kde je limit 1000 requestů za den (jinak se to platí) *"}</span>
                <br/>
                <span>{"* ale IPAPI je nejpřesnější, některé ostatní API mi ukazovali, že jsem v Brně, či v jiném městě *"}</span>

                <div class="horizontalStack" style={{margin: 10}}>
                    <Select
                        options={this.state.listOfCountries}
                        onChange={(e) => this.setState({ listOfSelectedCountries: e })}
                        isMulti
                        placeholder={""}
                        styles={customStyles}
                        className=""/>

                    <div style={{width: 50}}></div>

                    <button onClick={() => this.getSelected()}>
                        <span>{"Get selected"}</span>
                    </button>
                </div>*/}


                {/*<br/>
                <span>{"Address:"}</span>
                <input type="text" id="address" name="address" value={this.state.address} onChange={e => this.setState({ address: e.target.value })} />
                <input type="submit" value="Search" onClick={() => this.onClick()} />
                <br/>
                <span>{"Response: "}{this.state.response}</span>*/}

                {/*<div style={{ height: '300px', width: '500px' }}>
                    <MapComponent />
                </div>*/}


                {/*<div style={{display: "flex"}}>
                    <div style={{width: "400px", minWidth: "400px", backgroundColor: "red"}}></div>
                    <div style={{flexGrow: 1, backgroundColor: "#e8e8e8", overflowX: "auto"}}>
                        <table style={{width: "100%", borderCollapse: "collapse", minWidth: "600px", whiteSpace: "nowrap"}}>
                            <tr>
                                <th>Jméno</th>
                                <th>Příjmení</th>
                                <th>Pozice</th>
                                <th>Oddělení</th>
                                <th>Věk</th>
                                <th>Město</th>
                                <th>Email</th>
                                <th>Telefon</th>
                                <th>Datum nástupu</th>
                                <th>Plat</th>
                            </tr>
                            <tr>
                                <td>Jan</td>
                                <td>Novák</td>
                                <td>Programátor</td>
                                <td>Vývoj</td>
                                <td>30</td>
                                <td>Praha</td>
                                <td>jan.novak@example.com</td>
                                <td>123 456 789</td>
                                <td>01.01.2020</td>
                                <td>50 000</td>
                            </tr>
                        </table>
                    </div>
                </div>*/}







                <div style={{display: "flex"}}>
                    <div className="verticalStack">
                        <EmptyClosedTile />
                        <div style={{marginTop: "20px"}}></div>

                        <div className="conversationsTile">

                            <div className="horizontalStack">

                                <ExpandButton isOpen={this.state.isOpen} style={{marginTop: 5}} onClick={this.openCloseTile} />

                                <div style={{width: "20px"}}></div>

                                <div className="verticalStack flex" style={{display: "table", tableLayout: "fixed", width: "100%"}}>
                                    <span className="fontPoppinsSemiBold15">{"Attendees"}</span>
                                    <span className={this.state.isOpen ? "fontPoppinsRegular13Gray oneLineThreeDotsTextDeactivated" : "fontPoppinsRegular13Gray oneLineThreeDotsText"}>{"Use this tools for seeing a preview of registered attendees and a preview of their entry information. You can only add or edit each user in the EMS System."}</span>
                                </div>

                                <div style={{width: "20px"}}></div>

                                <div>
                                    <div className="horizontalStackCenter universalPopupContainer" style={{marginTop: 5}} onClick={() => alert("open modal attendee")}>
                                        <div className="universalPopupVisible clickable">
                                            <div className="circleButtonContainer circleButtonContainerOrange clickable">
                                                <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                                            </div>
                                        </div>

                                        <div className="universalPopupHidden clickable">
                                            <PopupButtonDescription title={"Add in the EMS System"} from={"left"} marginLeft={"-209px"} blackArrow={true} />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div style={{display: this.state.isOpen ? "table" : "none", tableLayout: "fixed", width: "100%", marginTop: 10}}>
                                <div style={{borderCollapse: "collapse", whiteSpace: "nowrap", overflow: "auto", display: "table-cell"}}>
                                    <table className="rolesTable" style={{width: "100%", maxWidth: "100%"}}>

                                        <tr>
                                            <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Jméno</td>
                                            <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Příjmení</td>
                                            <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Pozice</td>
                                            <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Oddělení</td>
                                            <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Věk</td>
                                            <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Město</td>
                                            <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Email</td>
                                            <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Telefon</td>
                                            <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Datum nástupu</td>
                                            <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Plat</td>
                                            <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Akce</td>
                                        </tr>

                                        {this.state.usersArray.map(function (item) {
                                            return (
                                                <tr key={item.id} onClick={() => this.onRoleClick(item.id)}>
                                                    <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.name}</td>
                                                    <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.surname}</td>
                                                    <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.position}</td>
                                                    <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.departement}</td>
                                                    <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.age}</td>
                                                    <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.city}</td>
                                                    <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.email}</td>
                                                    <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.phone}</td>
                                                    <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.start_date}</td>
                                                    <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.salary}</td>
                                                    <td className="rolesTableGeneralDiv" nowrap="nowrap">
                                                        <EditButtonDropdown itemId={item.id} onEdit={this.onEdit} onDelete={this.onDelete} text={"Are you sure want to delete this role?"}/>
                                                    </td>
                                                </tr>
                                            )
                                        }.bind(this))}
                                    </table>
                                </div>
                            </div>

                            {/*<div style={{height: "50px"}}></div>

                            <div style={{display: "table", tableLayout: "fixed", width: "100%", overflowX: "auto", marginTop: 10}}>
                                <table className="rolesTable" style={{borderCollapse: "collapse", whiteSpace: "nowrap", overflow: "auto", display: "table-cell"}}>

                                    <tr>
                                        <th className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Jméno</th>
                                        <th className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Příjmení</th>
                                        <th className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Pozice</th>
                                        <th className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Oddělení</th>
                                        <th className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Věk</th>
                                        <th className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Město</th>
                                        <th className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Email</th>
                                        <th className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Telefon</th>
                                        <th className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Datum nástupu</th>
                                        <th className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Plat</th>
                                    </tr>
                                    <tr>
                                        <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">Jan</td>
                                        <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">Novák</td>
                                        <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">Programátor</td>
                                        <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">Vývoj</td>
                                        <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">30</td>
                                        <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">Praha</td>
                                        <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">jan.novak@example.com</td>
                                        <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">123 456 789</td>
                                        <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">01.01.2020</td>
                                        <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">50 000</td>
                                    </tr>
                                </table>
                            </div>*/}
                        </div>

                        <div style={{marginTop: "40px"}}></div>

                        <p>This solution advantages/disadvantages:</p>
                        <ul>
                            <li>{"It is now fully responsive and should look good on smaller screens"}</li>
                            <li>{"The edit button will be sometimes hidden and you need to scroll to get it....and also the popup is not showing correctelly.... (which would not be problem if we will change the funcitonality to work using modal window - I think we wanted to do this in MinistryStatistics tile)"}</li>
                        </ul>

                    </div>


                    <div className="verticalStack">
                        <EmptyChurchInfoTile />
                        <div style={{marginTop: "20px"}}></div>
                        <EmptyDiagnosticTile />
                    </div>
                </div>
            </div>
        );
    }
}

export default Testing;