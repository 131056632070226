import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { Link, Navigate, useLocation } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { goToPrevContactUtilities, goToNextContactUtilities, goToFirstContactUtilities, goToLastContactUtilities, openContactUtilities } from '../utilities/PagingUtilities.js';

// import components
import ChurchInfoTile from '../tiles/ChurchInfoTile.js';
import ProfileTile from '../tiles/ProfileTile.js';
import ConversationsTile from '../tiles/ConversationsTile.js';
import DiagnosticTile from '../tiles/DiagnosticTile.js';
import RelationshipsTile from '../tiles/RelationshipsTile.js';
import RolesTile from '../tiles/RolesTile.js';
import EventsTrainingsTile from '../tiles/EventsTrainingsTile.js';
import ClosedTile from '../tiles/ClosedTile.js';
import ReturnToSearchResultsButton from '../components/ReturnToSearchResultsButton.js';

class PeopleProfile extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);
        this.refConvScroll = React.createRef();
        this.refRolesScroll = React.createRef();
        this.refRelScroll = React.createRef();

        this.state = {
            actualOpenedTile: "", //profiledata, conversations, roles, relationships, diagnosticlens
            updateChurchInfoTileVar: true, // to update change its value
            updateDiagnosticTileVar: true, // to update change its value
            updateProfileTileVar: true, // to update change its value
            updateConversationsTileVar: true, // to update change its value
            updateRolesTileVar: true, // to update change its value
            updateRelationshipsTileVar: true, // to update change its value
            updateEventsTrainingsTileVar: true, // to update change its value
            tileMenuOpened: false,
            mainContactId: 0,
            contactIdPosition: 0,
            contactIdsArray: ([]),
        }
    }

    componentDidMount(){
        //this.setActualSection("people");

        const { tile } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(tile == "conversations"){ this.openTile("conversations"); }

        const pathname = this.props.location.pathname;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }
        if(Number.isInteger(Number(id)))this.setState({ mainContactId: parseInt(id) });

        try {
            const getIdsArray = localStorage.getItem('idsArray');
            const idsArray = JSON.parse(getIdsArray);
            this.setState({ contactIdPosition: idsArray.indexOf(parseInt(id))+1 });
            this.setState({ contactIdsArray: idsArray });
        }
        catch(err){
            alert("Error");
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.location.pathname !== prevProps.location.pathname){
            this.updateProfileTile();
            this.updateChurchInfoTile();
            this.updateDiagnosticTile();
            this.updateConversationsTile();
            this.updateRolesTile();
            this.updateRelationshipsTile();
            this.updateEventsTrainingsTile();
        }
    }

    setActualOpenedTile = (tile) => {
        this.setState({ actualOpenedTile: tile });
    }

    updateChurchInfoTile = () => {
        if(this.state.updateChurchInfoTileVar)this.setState({ updateChurchInfoTileVar: false });
        else this.setState({ updateChurchInfoTileVar: true });
    }

    updateDiagnosticTile = () => {
        if(this.state.updateDiagnosticTileVar)this.setState({ updateDiagnosticTileVar: false });
        else this.setState({ updateDiagnosticTileVar: true });
    }

    updateProfileTile = () => {
        if(this.state.updateProfileTileVar)this.setState({ updateProfileTileVar: false });
        else this.setState({ updateProfileTileVar: true });
    }

    updateConversationsTile = () => {
        this.setState({ updateConversationsTileVar: !this.state.updateConversationsTileVar });
    }

    updateRolesTile = () => {
        this.setState({ updateRolesTileVar: !this.state.updateRolesTileVar });
    }

    updateRelationshipsTile = () => {
        this.setState({ updateRelationshipsTileVar: !this.state.updateRelationshipsTileVar });
    }

    updateEventsTrainingsTile = () => {
        this.setState({ updateEventsTrainingsTileVar: !this.state.updateEventsTrainingsTileVar });
    }

    openTile = async (tile) => {
        await this.setActualOpenedTile(tile);
        await new Promise(resolve => setTimeout(resolve, 100));
        if(tile == "conversations"){ this.refConvScroll.current.scrollIntoView({ behavior: "smooth", block: "start" }); }
        else if(tile == "roles"){ this.refRolesScroll.current.scrollIntoView({ behavior: "smooth", block: "start" }); }
        else if(tile == "relationships"){ this.refRelScroll.current.scrollIntoView({ behavior: "smooth", block: "start" }); }
    }

    render() {
        return(
                <div className="verticalStack flex" style={{height: "calc(100vh - 80px)", overflowY: "scroll"}}>
                    <div className="horizontalStackCenter myPeopleTableListingBar">
                        <ReturnToSearchResultsButton position={this.state.contactIdPosition} />
                        <div style={{display: this.state.contactIdPosition != 0 ? "" : "none", marginLeft: "40px"}}>
                            <span className="fontPoppinsSemiBold16 clickable onHoverToOrange" onClick={() => goToFirstContactUtilities(this.state.contactIdPosition-1, this.state.contactIdsArray)}>{"<<"}</span>
                            <span className="fontPoppinsSemiBold16 clickable onHoverToOrange" onClick={() => goToPrevContactUtilities(this.state.contactIdPosition-1, this.state.contactIdsArray)} style={{paddingLeft: "20px"}}>{"<"}</span>
                            <span className="fontPoppinsSemiBold16" style={{paddingLeft: "20px"}}>{this.state.contactIdPosition}{"/"}{this.state.contactIdsArray.length}</span>
                            <span className="fontPoppinsSemiBold16 clickable onHoverToOrange" onClick={() => goToNextContactUtilities(this.state.contactIdPosition-1, this.state.contactIdsArray)} style={{paddingLeft: "20px", paddingRight: "20px"}}>{">"}</span>
                            <span className="fontPoppinsSemiBold16 clickable onHoverToOrange" onClick={() => goToLastContactUtilities(this.state.contactIdPosition-1, this.state.contactIdsArray)}>{">>"}</span>
                        </div>
                        <div className="flex"/>
                        {this.state.tileMenuOpened ? <div>
                                <span className="fontPoppinsRegular13Gray onHoverToOrange clickable" style={{marginRight: 24}} onClick={() => this.openTile("conversations")}>{"Conversations"}</span>
                                <span className="fontPoppinsRegular13Gray onHoverToOrange clickable" style={{marginRight: 24}} onClick={() => this.openTile("roles")}>{"Roles"}</span>
                                <span className="fontPoppinsRegular13Gray onHoverToOrange clickable" style={{marginRight: 24}} onClick={() => this.openTile("relationships")}>{"Relationships"}</span>
                                <span className="fontPoppinsRegular13Orange clickable" onClick={() => this.setState({ tileMenuOpened: false })}>{"Hide Tile Menu"}</span>
                            </div>
                        : <span className="fontPoppinsRegular13Gray onHoverToOrange clickable" onClick={() => this.setState({ tileMenuOpened: true })}>{"Show Tile Menu"}</span> }
                    </div>

                    <div className="horizontalStack">

                        <div className="verticalStack flex">
                            <ProfileTile onCloseOrOpen={this.setActualOpenedTile} openAlertMessage={this.props.openAlertMessage} openTile={this.openTile} updateVar={this.state.updateProfileTileVar} />

                            <div style={{marginTop: "20px"}}></div>

                            <div ref={this.refConvScroll} className="verticalStack">

                                <ConversationsTile isOpen={this.state.actualOpenedTile == "conversations" ? true : false} setActualOpenedTile={this.setActualOpenedTile} contactMode={"person"} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} updateVar={this.state.updateConversationsTileVar} />

                                <div ref={this.refRolesScroll}  style={{marginTop: "20px"}}></div>

                                <RolesTile isOpen={this.state.actualOpenedTile == "roles" ? true : false} setActualOpenedTile={this.setActualOpenedTile} updateChurchInfoTile={this.updateChurchInfoTile} updateDiagnosticTile={this.updateDiagnosticTile} updateProfileTile={this.updateProfileTile} openAlertMessage={this.props.openAlertMessage} updateVar={this.state.updateRolesTileVar} />

                                <div ref={this.refRelScroll}  style={{marginTop: "20px"}}></div>

                                <RelationshipsTile isOpen={this.state.actualOpenedTile == "relationships" ? true : false} setActualOpenedTile={this.setActualOpenedTile} openAlertMessage={this.props.openAlertMessage} updateVar={this.state.updateRelationshipsTileVar} />

                                <div style={{marginTop: "20px"}}></div>

                                <EventsTrainingsTile isOpen={this.state.actualOpenedTile == "eventsTrainings" ? true : false} setActualOpenedTile={this.setActualOpenedTile} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} updateVar={this.state.updateEventsTrainingsTileVar} />

                                <div style={{marginTop: "20px"}}></div>

                            </div>
                        </div>

                        <div className="verticalStack">
                            <ChurchInfoTile mode={"person"} updateVar={this.state.updateChurchInfoTileVar} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} updateRolesTile={this.updateRolesTile} deviceType={"pc"} />
                            <div style={{marginTop: "20px"}}></div>
                            <DiagnosticTile mode={"person"} updateVar={this.state.updateDiagnosticTileVar} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />
                            <div style={{marginTop: "20px"}}></div>
                        </div>

                    </div>
                </div>
        );
    }
}

const withLocation = Component => props => {
    const location = useLocation();

    return <Component {...props} location={location} />;
};

export default withLocation(PeopleProfile);