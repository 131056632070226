import React from 'react';
import ReactDOM from 'react-dom';
import '../styles/modals.css';
import Axios from 'axios';

// components
import ModalMobile from '../modals_m/ModalMobile';
import RoundButton from '../components/RoundButton.js';
import DropdownItem from '../components/DropdownItem.js';
import SearchPopup from '../components/SearchPopup.js';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { getTodaysDate } from '../utilities/OtherUtilities.js';

// assets
import close_white from '../assets/close_white.png';
import blue_close_icon from '../assets/blue_close_icon.png';

class ConversationsModalMobile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editingMode: this.props.actionType, // AddConversation, EditConversation
            conversationCategory: -1,
            conversationCategorySelected: "+ Add",
            descr: "",
            descrNeed: "",
            findContactByName: "",
            findEmailByName: "",
            foundContacts: ([]),
            foundEmails: ([]),
            otherWriteHere: "",
            createdBy: "Unknown",
            showPopupWithWhom: false,
            showPopupEmails: false,
            showPopupConversationCategory: false,
            conversationCategoriesArray: ([{"id":1,"name":"Discipler", "selected": false}]),
            date: "2022-01-01",
            checkboxPrayer: false,
            checkboxMentoring: false,
            checkboxMaterials: false,
            checkboxProgram: false,
            checkboxTraining: false,
            checkboxEXIT: false,
            checkboxFusion: false,
            checkboxEdge: false,
            checkboxMedia: false,
            checkboxCamps: false,
            checkboxSchools: false,
            checkboxCoaching: false,
            checkboxOther: false,
            /*emailsArray: ([{"id":2 ,"name":"Frank Kirk", "email":"frank@skote.com"}]),*/
            emailsArray: ([]),
            alreadySentEmailsArray: ([]),
            /*contactsArray: ([{"id":2 ,"name":"Frank Kirk"}]),*/
            contactsArray: ([]),
            isPrivate: 0, // 0 or 1
        };

        this.refPopupCategories = React.createRef();
        this.refPopupEmails = React.createRef();
        this.refPopupWithWhom = React.createRef();
        this.refInputWithWhom = React.createRef();
        this.refInputEmails = React.createRef();
    }

    componentDidMount(){
        this.loadConversationsCategoriesArray();

        // Edit
        if(this.props.conversationId != -1 && this.props.actionType == "EditConversation"){
            this.loadExistingConversation(this.props.conversationId);
        }
        // Add
        else {
            // IF opening from person profile page AddConversation THEN add first default contact to create conversation with
            if(this.props.contactMode == "person" || this.props.contactMode == "church"){
                if(this.props.contactId != null && this.props.contactName != null && this.props.contactId != 0){
                    this.addContactToArray(this.props.contactId, this.props.contactName);
                }
            }

            this.setState({ date: getTodaysDate() });

            // add email of signed user to the list of emails
            var token_object = JSON.parse(localStorage.getItem("token"));
            var signedUserId = token_object.id;
            var signedUserEmail = token_object.email;
            var signedUserName = token_object.first_name + " " + token_object.last_name;
            this.setState({ createdBy: signedUserName || "" });
            //this.addEmailContact(signedUserId, signedUserName, signedUserEmail);
        }
        window.addEventListener('click', this.onGlobalClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onGlobalClick);
    }

    /* This method is triggered on every click on screen and is checking if some popup is opened nad user clicked out of this popup so it would close that popup in that situation */
    onGlobalClick = (e) => {
        if ( (!this.refPopupCategories.current.contains(e.target)) && this.state.showPopupConversationCategory) {
            // user clicked out of the PopupCategories and that popup is now opened
            this.setState({ showPopupConversationCategory: false })
        }
        if ( (!this.refPopupEmails.current.contains(e.target)) && this.state.showPopupEmails) {
            this.setState({ showPopupEmails: false })
        }
        if ( (!this.refPopupWithWhom.current.contains(e.target)) && this.state.showPopupWithWhom) {
            this.setState({ showPopupWithWhom: false })
        }
        if ( this.state.showPopupWithWhom ) this.refInputWithWhom.current.focus();
        if ( this.state.showPopupEmails ) this.refInputEmails.current.focus();
    }

    loadConversationsCategoriesArray = () => {
        Axios.get('/api/ctg/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            var results = response.data.result;
            var otherArray = [];

            for(let oneRow of results){
                otherArray.push({"id":oneRow.id, "name": oneRow.label, "selected": false});
            }
            this.setState({ conversationCategoriesArray: otherArray });
        });
    }

    loadExistingConversation = async (id) => {
        await Axios.get('/api/conv/get/'+id).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            var results = response.data.result;
            this.setState({ descr: results[0].descr });
            this.setState({ descrNeed: results[0].need_descr });
            this.setState({ otherWriteHere: results[0].offer_descr });

            this.setState({ createdBy: results[0].cr_by });
            this.setState({ isPrivate: results[0].is_private});

            var resultOff = response.data.resultOff;
            for(let oneRow of resultOff){
                if(oneRow.offer_id == 1){ this.setState({ checkboxPrayer: true }); }
                if(oneRow.offer_id == 2){ this.setState({ checkboxMentoring: true }); }
                if(oneRow.offer_id == 3){ this.setState({ checkboxMaterials: true }); }
                if(oneRow.offer_id == 4){ this.setState({ checkboxProgram: true }); }
                if(oneRow.offer_id == 5){ this.setState({ checkboxTraining: true }); }
                if(oneRow.offer_id == 6){ this.setState({ checkboxEXIT: true }); }
                if(oneRow.offer_id == 7){ this.setState({ checkboxFusion: true }); }
                if(oneRow.offer_id == 8){ this.setState({ checkboxEdge: true }); }
                if(oneRow.offer_id == 9){ this.setState({ checkboxMedia: true }); }
                if(oneRow.offer_id == 10){ this.setState({ checkboxCamps: true }); }
                if(oneRow.offer_id == 11){ this.setState({ checkboxSchools: true }); }
                if(oneRow.offer_id == 12){ this.setState({ checkboxCoaching: true }); }
                if(oneRow.offer_id == 13){ this.setState({ checkboxOther: true }); }
            }

            var newConversationCategoriesArray = this.state.conversationCategoriesArray;
            for(let oneRow of newConversationCategoriesArray){
                for(let secondRow of response.data.resultCtg){
                    if(secondRow.ctg_id == oneRow.id){
                        this.selectUnselectCategory(oneRow.id);
                    }
                }
            }

            var newContactsArray = this.state.contactsArray;
            for(let oneRow of response.data.resultCont){
                newContactsArray.push({"id": oneRow.cont_id, "name": oneRow.cont_name});
            }
            this.setState({ contactsArray: newContactsArray });

            var sentEmailsArray = [];
            for(let oneRow of response.data.resultSend){
                sentEmailsArray.push({id: oneRow.send_id, name: oneRow.rcg_name, email: oneRow.email, sent: true})
            }
            this.setState({ alreadySentEmailsArray: sentEmailsArray });

            //this.setState({ conversationCategory: results[0].rel_tp_id+";a_b" });

            if(results[0].dt != null){ this.setState({ date: results[0].dt }); }
        });
    }

    // this method works for both creating new Conversation and saving changed Conversation
    saveConversationAction = async (createAnotherNewAfter) => {
        var date = this.state.date;
        var Descr = this.state.descr == null ? "" : this.state.descr;
        var NeedDescr = this.state.descrNeed == null ? "" : this.state.descrNeed;
        var otherWriteHere = this.state.otherWriteHere == null ? "" : this.state.otherWriteHere;
        var categoriesArray = this.state.conversationCategoriesArray;
        var emailsArray = this.state.emailsArray;

        // create final list of assigned contacts to send
        var finalContactsArray = [];
        if(this.state.contactsArray.length == 0){
            this.props.openAlertMessage("You need to assign at least one person to this conversation.","OK");
            return;
        }
        for(let oneRow of this.state.contactsArray){
            finalContactsArray.push(oneRow.id);
        }

        // check year validity
        const objectDate = new Date(date);
        if(objectDate.getFullYear() < 1900){
            this.props.openAlertMessage("Year cannot be lower than 1900.","OK");
            return;
        }

        // check description length
        if(Descr.length > 5000){
            this.props.openAlertMessage("Maximum length for description is 5000 characters.","OK");
            return;
        }

        // create final list of assigned categories to send
        var finalCategoriesArray = [];
        var arrayOfSelectedCategories = [];
        for(let oneRow of this.state.conversationCategoriesArray){
            if(oneRow.selected){ arrayOfSelectedCategories.push(oneRow); }
        }
        if(arrayOfSelectedCategories.length == 0){
            this.props.openAlertMessage("You need to select at least one category to this conversation.","OK");
            return;
        }
        for(let oneRow of arrayOfSelectedCategories){
            finalCategoriesArray.push(oneRow.id);
        }

        // create final list of emails to send
        var finalEmailsArray = [];
        for(let oneRow of this.state.alreadySentEmailsArray){
            finalEmailsArray.push(oneRow.id);
        }
        for(let oneRow of this.state.emailsArray){
            finalEmailsArray.push(oneRow.id);
        }

        // create final list of selected checboxes of needs/offers
        var finalNeedsArray = [];
        if(this.state.checkboxPrayer){ finalNeedsArray.push(1); }
        if(this.state.checkboxMentoring){ finalNeedsArray.push(2); }
        if(this.state.checkboxMaterials){ finalNeedsArray.push(3); }
        if(this.state.checkboxProgram){ finalNeedsArray.push(4); }
        if(this.state.checkboxTraining){ finalNeedsArray.push(5); }
        if(this.state.checkboxEXIT){ finalNeedsArray.push(6); }
        if(this.state.checkboxFusion){ finalNeedsArray.push(7); }
        if(this.state.checkboxEdge){ finalNeedsArray.push(8); }
        if(this.state.checkboxMedia){ finalNeedsArray.push(9); }
        if(this.state.checkboxCamps){ finalNeedsArray.push(10); }
        if(this.state.checkboxSchools){ finalNeedsArray.push(11); }
        if(this.state.checkboxCoaching){ finalNeedsArray.push(12); }
        if(this.state.checkboxOther){ finalNeedsArray.push(13); }

        /*if(finalNeedsArray.length == 0){
            alert("You need to select at least one need to this conversation");
            return;
        }*/

        //alert("SAVE:\ContId: "+ContId+"\nDesc: "+Descr+"\nNeedDesc: "+NeedDescr+"\nDate: "+date+"\n);
        //alert(finalEmailsArray);
        //return;

        var url = "/api/conv/ins";
        if(this.props.actionType == "EditConversation"){url = "/api/conv/upd/"+this.props.conversationId;}

        Axios.post(url, {
            Dt: date,
            Descr: Descr,
            NeedDescr: NeedDescr,
            ContId: finalContactsArray,
            CtgId: finalCategoriesArray,
            OfferId: finalNeedsArray,
            OfferDescr: otherWriteHere,
            SendId: finalEmailsArray,
            IsPrivate: this.state.isPrivate,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.props.closeModal(this.state.editingMode, createAnotherNewAfter);
        });
    }

    askToDelete = () => {
        this.props.openAlertMessageTwoActions("Are you sure you want to delete this conversation?","Cancel","Delete",this.deleteConversation);
    }

    deleteConversation = () => {
        if(this.props.conversationId != -1 && this.props.actionType == "EditConversation"){
            Axios.delete('/api/conv/del/'+this.props.conversationId, {
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.props.closeModal(this.state.editingMode, false);
            });
        }
    }

    searchForContacts = async (searchText) => {
        if(searchText.length >= 3){
            Axios.get('/api/cont/get?search='+searchText+'&is_wo_me=1').then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ foundContacts: response.data.result });
            });
        }
        else {
            this.setState({ foundContacts: [] });
        }
    }

    searchForEmails = async (searchText) => {
        if(searchText.length >= 3){
            Axios.get('/api/usr/get?search='+searchText).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ foundEmails: response.data.result });
            });
        }
        else {
            this.setState({ foundEmails: [] });
        }
    }

    addEmailContact = async (contactId, contactName, contactEmail) => {
        // check if it is not already added
        for(let oneRow of this.state.emailsArray){
            if(oneRow.id == contactId){
                this.setState({ showPopupEmails: false });
                return;
            }
        }

        this.setState({
            emailsArray: [...this.state.emailsArray, {"id": contactId ,"name": contactName, "email": contactEmail, "sent": false}]
        });

        this.setState({ showPopupEmails: false });
        this.setState({ findEmailByName: "" });
        this.setState({ foundEmails: [] });
    }

    addContactToArray = async (contactId, contactName) => {
        // check if it is not already added
        for(let oneRow of this.state.contactsArray){
            if(oneRow.id == contactId){
                this.setState({ showPopupWithWhom: false });
                return;
            }
        }

        this.setState({
            contactsArray: [...this.state.contactsArray, {"id": contactId, "name": contactName}]
        });
        this.setState({ showPopupWithWhom: false });
        this.setState({ findContactByName: "" });
        this.setState({ foundContacts: [] });
    }

    removeEmailFromList = (contactId) => {
        var newEmailsArray = [];

        for(let oneRow of this.state.emailsArray){
            if(oneRow.id != contactId){
                newEmailsArray.push({id: oneRow.id, name: oneRow.name, email: oneRow.email, sent: oneRow.sent})
            }
        }

        this.setState({ emailsArray: newEmailsArray });
    }

    removeContactFromList = (contactId) => {
        var newContactsArray = [];

        for(let oneRow of this.state.contactsArray){
            if(oneRow.id != contactId){
                newContactsArray.push({id: oneRow.id, name: oneRow.name})
            }
        }

        this.setState({ contactsArray: newContactsArray });
    }

    selectUnselectCategory = (id) => {
        var numSelectedCategories = 0;
        var selectedCategory = "";
        var newConversationCategoriesArray = this.state.conversationCategoriesArray;
        for(let oneRow of newConversationCategoriesArray){
            if(oneRow.id == id){
                oneRow.selected = !oneRow.selected;
            }
        }
        for(let oneRow of newConversationCategoriesArray){
            if(oneRow.selected){
                numSelectedCategories++;
                selectedCategory = oneRow.name;
            }
        }
        if(numSelectedCategories > 1){
            selectedCategory = "More than one";
        }

        this.setState({ conversationCategory: 1 });
        if(numSelectedCategories == 0){
            selectedCategory = "+ Add";
            this.setState({ conversationCategory: -1 });
        }
        this.setState({ showPopupConversationCategory: false });
        this.setState({ conversationCategorySelected: selectedCategory });
        this.setState({ conversationCategoriesArray: newConversationCategoriesArray });

    }

    toggleIsPrivate = () => {
        this.setState({ isPrivate: this.state.isPrivate == 1 ? 0 : 1 });
    }

    setConversationPrivate = () => {

        if(this.state.editingMode == "EditConversation"){
            if(this.state.isPrivate == 1){
                this.props.openAlertMessageTwoActions("Are you sure you want to public this conversation to everybody?","Keep private","Make public",this.toggleIsPrivate);
            }
            else {
                this.props.openAlertMessageTwoActions("Once conversation was created as public it is not possible to change it to private.","OK","",null);
            }
        }
        else {
            if(this.state.isPrivate == 0){
                if(this.state.emailsArray.length > 0){
                    var listOfEmails = "";
                    for(let oneRow of this.state.emailsArray){
                        if(listOfEmails == "") listOfEmails = ""+oneRow.email;
                        else listOfEmails = listOfEmails+", "+oneRow.email;
                    }
                    this.props.openAlertMessageTwoActions("Are you sure you want these people to receive this private conversation? ("+listOfEmails+")","Cancel","Make private",this.toggleIsPrivate);
                }
                else {
                    this.props.openAlertMessageTwoActions("Making this conversation private will keep anyone else from viewing it.","Cancel","Make private",this.toggleIsPrivate);
                }
            }
            else {
                this.toggleIsPrivate();
            }
        }
    }

	render(){
		return(
            <React.Fragment>
                <ModalMobile show={this.props.showModal} onClose={() => this.props.closeModal(this.state.editingMode, false)} isExpanded={false}>
                    <div className="content">
                        <div className="horizontalStack">
                            <div className="verticalStack flex">
                                <span className="fontPoppinsSemiBold15">{this.state.editingMode == "EditConversation" ? "Edit Your Conversation with:" : "Add Your Conversation with:"}</span>

                                <div style={{display: "table", tableLayout: "fixed", width: "100%"}}>
                                    <div className="horizontalStackCenter " style={{flexWrap: "wrap", overflowX: "hidden"}}>

                                        {this.state.contactsArray.map(function (item) {
                                            return (
                                                <div className="horizontalStackCenter responsiveThreeDots" onClick={() => this.removeContactFromList(item.id)} style={{marginRight: 12, whiteSpace: "nowrap"}}>
                                                    <img className="clickable" style={{width: 7}} src={blue_close_icon}/>
                                                    <span className="fontPoppinsSemiBold15" style={{color: "var(--blue-button-link)", cursor: "default", marginLeft: 5}}>{item.name}</span>
                                                </div>
                                            )
                                        }.bind(this))}

                                        <SearchPopup
                                            id={"searchContactId"}
                                            textName={"+ Add a Name"}
                                            textStyle={"italicOrange"}
                                            value={this.state.findContactByName}
                                            changeValue={(text) => {this.setState({ findContactByName: text }); this.searchForContacts(text);}}
                                            foundRecords={this.state.foundContacts}
                                            refPopup={this.refPopupWithWhom}
                                            refInput={this.refInputWithWhom}
                                            showPopup={this.state.showPopupWithWhom}
                                            showHidePopup={() => this.setState({ showPopupWithWhom: !this.state.showPopupWithWhom })}
                                            addContactToArray={(item) => this.addContactToArray(item.id,item.rcg_name)}
                                            showEmailStyle={false}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="verticalStack">
                                    <img className="modalCloseButton clickable" src={close_white} onClick={() => this.props.closeModal(this.state.editingMode, false)}/>
                                    <div className="flex"></div>
                            </div>
                        </div>

                        <div className="verticalStack" style={{backgroundColor:"var(--lightest-gray)", padding: 12, marginTop: 12}}>

                            <div className="horizontalStack">
                                <div className="horizontalStack flex">
                                    <span className="fontPoppinsRegular13">Date:*</span>
                                    <div style={{width: 5}}></div>
                                    <input className="modalDatePicker fontPoppinsRegular13" type="date" id="start_date" name="start_date" min="1900-01-01" max="9999-01-01" value={this.state.date} onChange={e => this.setState({ date: e.target.value })}/>
                                </div>

                                <span className="fontPoppinsItalic13Gray" style={{marginLeft: 5}}>*Required fields</span>
                            </div>

                            <div className="horizontalStack flex" style={{marginTop: 10}}>

                                <div className="universalPopupSelectContainer" ref={this.refPopupCategories}>

                                    <div className="universalPopupSelectVisible horizontalStack">
                                        <span className="fontPoppinsRegular13">Categories:*</span>
                                        <div style={{width: 5}}></div>
                                        <span className="fontPoppinsRegular13 clickable" style={{color: this.state.conversationCategory == -1 ? "var(--jv-orange)" : "var(--blue-button-link)"}} onClick={() => this.setState({ showPopupConversationCategory: !this.state.showPopupConversationCategory })}>{this.state.conversationCategorySelected}</span>
                                    </div>

                                    <div className="universalPopupSelectHidden" style={{display: this.state.showPopupConversationCategory ? "" : "none"}}>
                                        <div className="verticalStack" onClick={() => this.setState({ showPopupConversationCategory: false })}>
                                            <span className="fontPoppinsRegular13">Select Categories</span>

                                            <div style={{height: 1, backgroundColor: "var(--border-light-gray)", marginTop: 10, marginBottom: 10}}></div>

                                            <div className="verticalStack">

                                                {this.state.conversationCategoriesArray.map(function (item) {
                                                    return (
                                                        <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3}} onClick={() => this.selectUnselectCategory(item.id)}>
                                                            <input type="checkbox" className="clickable" checked={item.selected} />
                                                            <label for={item.id+""+item.name} className="fontPoppinsRegular13" style={{marginLeft: 5}}>{item.name}</label>
                                                        </div>
                                                    )
                                                }.bind(this))}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="horizontalStack flex" style={{marginTop: 10}}>

                                <div className="universalPopupSelectContainer">

                                    <div className="universalPopupSelectVisible horizontalStack">
                                        <span className="fontPoppinsRegular13">Created by:*</span>
                                        <div style={{width: 5}}></div>
                                        <span className="fontPoppinsRegular13" style={{color: "var(--blue-button-link)"}}>{this.state.createdBy}</span>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="verticalStack" style={{backgroundColor:"var(--lightest-gray)", padding: 12, marginTop: 2}}>

                            <span className="fontPoppinsRegular13">Notes:</span>

                            <textarea className="modalInputAddNote" rows="8" style={{backgroundColor: "white"}} placeholder="Write here any key observations from the conversation you need to record.&#13; &#10;Notice: these notes will be viewable by other users" id="textAreaDescr" name="textAreaDescr" value={this.state.descr} onChange={e => this.setState({ descr: e.target.value })}></textarea>

                            <span className="fontPoppinsRegular13" style={{marginTop: 10}}>What can we offer to help meet their needs?:</span>

                            <div className="horizontalStackCenter flex">

                                <div className="verticalStack flex">
                                    <div className="horizontalStackCenter flex" onClick={() => this.setState({ checkboxPrayer: !this.state.checkboxPrayer })}>
                                        <input type="checkbox" checked={this.state.checkboxPrayer} style={{marginTop: 10}}/>
                                        <label className="fontPoppinsRegular13" style={{marginTop: 8}}>{"Prayer"}</label>
                                    </div>
                                    <div className="horizontalStackCenter flex" onClick={() => this.setState({ checkboxMaterials: !this.state.checkboxMaterials })}>
                                        <input type="checkbox" checked={this.state.checkboxMaterials} style={{marginTop: 10}}/>
                                        <label className="fontPoppinsRegular13" style={{marginTop: 8}}>{"Materials"}</label>
                                    </div>
                                    <div className="horizontalStackCenter flex" onClick={() => this.setState({ checkboxTraining: !this.state.checkboxTraining })}>
                                        <input type="checkbox" checked={this.state.checkboxTraining} style={{marginTop: 10}}/>
                                        <label className="fontPoppinsRegular13" style={{marginTop: 8}}>{"Training"}</label>
                                    </div>
                                    <div className="horizontalStackCenter flex" onClick={() => this.setState({ checkboxFusion: !this.state.checkboxFusion })}>
                                        <input type="checkbox" checked={this.state.checkboxFusion} style={{marginTop: 10}}/>
                                        <label className="fontPoppinsRegular13" style={{marginTop: 8}}>{"Fusion"}</label>
                                    </div>
                                    <div className="horizontalStackCenter flex" onClick={() => this.setState({ checkboxMedia: !this.state.checkboxMedia })}>
                                        <input type="checkbox" checked={this.state.checkboxMedia} style={{marginTop: 10}}/>
                                        <label className="fontPoppinsRegular13" style={{marginTop: 8}}>{"Media"}</label>
                                    </div>
                                    <div className="horizontalStackCenter flex" onClick={() => this.setState({ checkboxSchools: !this.state.checkboxSchools })}>
                                        <input type="checkbox" checked={this.state.checkboxSchools} style={{marginTop: 10}}/>
                                        <label className="fontPoppinsRegular13" style={{marginTop: 8}}>{"Schools"}</label>
                                    </div>
                                </div>

                                <div className="verticalStack flex">
                                    <div className="horizontalStackCenter flex" onClick={() => this.setState({ checkboxMentoring: !this.state.checkboxMentoring })}>
                                        <input type="checkbox" checked={this.state.checkboxMentoring} style={{marginTop: 10}}/>
                                        <label className="fontPoppinsRegular13" style={{marginTop: 8}}>{"Mentoring"}</label>
                                    </div>
                                    <div className="horizontalStackCenter flex" onClick={() => this.setState({ checkboxProgram: !this.state.checkboxProgram })}>
                                        <input type="checkbox" checked={this.state.checkboxProgram} style={{marginTop: 10}}/>
                                        <label className="fontPoppinsRegular13" style={{marginTop: 8}}>{"Program"}</label>
                                    </div>
                                    <div className="horizontalStackCenter flex" onClick={() => this.setState({ checkboxEXIT: !this.state.checkboxEXIT })}>
                                        <input type="checkbox" checked={this.state.checkboxEXIT} style={{marginTop: 10}}/>
                                        <label className="fontPoppinsRegular13" style={{marginTop: 8}}>{"EXIT"}</label>
                                    </div>
                                    <div className="horizontalStackCenter flex" onClick={() => this.setState({ checkboxEdge: !this.state.checkboxEdge })}>
                                        <input type="checkbox" checked={this.state.checkboxEdge} style={{marginTop: 10}}/>
                                        <label className="fontPoppinsRegular13" style={{marginTop: 8}}>{"Edge"}</label>
                                    </div>
                                    <div className="horizontalStackCenter flex" onClick={() => this.setState({ checkboxCamps: !this.state.checkboxCamps })}>
                                        <input type="checkbox" checked={this.state.checkboxCamps} style={{marginTop: 10}}/>
                                        <label className="fontPoppinsRegular13" style={{marginTop: 8}}>{"Camps"}</label>
                                    </div>
                                    <div className="horizontalStackCenter flex" onClick={() => this.setState({ checkboxCoaching: !this.state.checkboxCoaching })}>
                                        <input type="checkbox" checked={this.state.checkboxCoaching} style={{marginTop: 10}}/>
                                        <label className="fontPoppinsRegular13" style={{marginTop: 8}}>{"Coaching"}</label>
                                    </div>
                                </div>

                            </div>

                            <div className="horizontalStackCenter flex" style={{width: "200px", overflowX: "hidden", marginTop: 5}}>
                                <input type="checkbox" checked={this.state.checkboxOther} style={{marginTop: 2}} onClick={() => this.setState({ checkboxOther: !this.state.checkboxOther })}/>
                                <label className="fontPoppinsRegular13" onClick={() => this.setState({ checkboxOther: !this.state.checkboxOther })}>{"Other:"}</label>
                                <input className="fontPoppinsRegular13" type="text" id="otherId" name="otherId" autoComplete="off" placeholder="Write here..." value={this.state.otherWriteHere} onChange={e => this.setState({ otherWriteHere: e.target.value })} style={{marginLeft: 10, marginBottom: 0, border: "0px solid black", backgroundColor: "transparent"}}/>
                            </div>

                            <textarea className="modalInputAddNote" rows="2" style={{backgroundColor: "white"}} placeholder="Write a description of their needs here." id="textAreaNeedsDescr" name="textAreaNeedsDescr" value={this.state.descrNeed} onChange={e => this.setState({ descrNeed: e.target.value })}></textarea>

                            <span className="fontPoppinsRegular13" style={{marginTop: 20}}>Who do you want to send a copy of this conversation to? Blue indicates that they were already notified.</span>

                            <div style={{display: "table", tableLayout: "fixed", width: "100%"}}>
                                <div className="horizontalStack flex" style={{backgroundColor: "white", marginTop: 10, paddingTop: 10, width: "100%", overflowX: "hidden", flexWrap: "wrap"}}>

                                    {this.state.alreadySentEmailsArray.map(function (item) {
                                        return (
                                            <label className="fontPoppinsRegular13" style={{marginLeft: 10, marginRight: 10, marginBottom: 10, border: "1px solid var(--blue-button-link)", color: "var(--blue-button-link)", whiteSpace: "nowrap", borderRadius: 5, paddingLeft: 5, paddingRight: 5}}>{item.name} {item.email}</label>
                                        )
                                    }.bind(this))}

                                    {this.state.emailsArray.map(function (item) {
                                        return (
                                            <label className="fontPoppinsRegular13 clickable" onClick={() => this.removeEmailFromList(item.id)} style={{position: "relative", marginLeft: 10, marginRight: 10, marginBottom: 10, border: "1px solid var(--jv-orange)", color: "var(--jv-orange)", whiteSpace: "nowrap", borderRadius: 5, paddingLeft: 5, paddingRight: 5}}>{"x"} {item.name} {item.email}</label>
                                        )
                                    }.bind(this))}

                                    <SearchPopup
                                        id={"searchEmail"}
                                        textName={"+ Add an Email"}
                                        textStyle={"italicOrange"}
                                        value={this.state.findEmailByName}
                                        changeValue={(text) => {this.setState({ findEmailByName: text }); this.searchForEmails(text);}}
                                        foundRecords={this.state.foundEmails}
                                        refPopup={this.refPopupEmails}
                                        refInput={this.refInputEmails}
                                        showPopup={this.state.showPopupEmails}
                                        showHidePopup={() => this.setState({ showPopupEmails: !this.state.showPopupEmails })}
                                        addContactToArray={(item) => this.addEmailContact(item.id, item.rcg_name, item.email)}
                                        showEmailStyle={true}
                                        style={{marginBottom: 10}}
                                    />

                                </div>
                            </div>

                        </div>

                        <div className="verticalStack" style={{marginTop: "12px"}}>
                            <div className="horizontalStackCenter" onClick={() => this.setConversationPrivate()}>
                                <input type="checkbox" className="clickable" checked={this.state.isPrivate == 1 ? true : false} style={{marginTop: -8}}/>
                                <label className="fontPoppinsRegular13 clickable" style={{marginBottom: 10, marginLeft: 3, paddingTop: 0}}>{"Private Conversation"}</label>
                            </div>

                            <div className="roundButton roundButtonOrange" style={{textAlign: "center", marginTop: 0}} onClick={() => this.saveConversationAction(false)}>
                                <span className="fontPoppinsRegular13White">{"Save Conversation"}</span>
                            </div>

                            <div className="roundButton roundButtonGray" style={{textAlign: "center", marginTop: 10}} onClick={() => this.saveConversationAction(true)}>
                                <span className="fontPoppinsRegular13White">{"Save and Add New"}</span>
                            </div>

                            {this.props.conversationId != -1 && this.props.actionType == "EditConversation" ?
                                <div className="roundButton roundButtonGray" style={{textAlign: "center", marginTop: 10}} onClick={this.askToDelete}>
                                    <span className="fontPoppinsRegular13White">{"Delete"}</span>
                                </div>
                            : null }

                        </div>

                    </div>
                </ModalMobile>
            </React.Fragment>
		);
	}

}

export default ConversationsModalMobile;