import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import Axios from 'axios';
import Moment from 'react-moment';
import { navigate } from "@reach/router";

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import assets
import icon_action_down from '../assets/icon_action_down.png';
import icon_expand from '../assets/icon_expand.png';
import icon_add_white from '../assets/icon_add_white.png';

// import components
import EditButtonDropdown from '../components/EditButtonDropdown.js';
import RoundButton from '../components/RoundButton.js';
import ExpandButton from '../components/ExpandButton.js';
import PopupButtonDescription from '../components/PopupButtonDescription.js';

class AttendeesTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clickedAttendeeId: -1,
            mainContactId: 0,
            mainContactName: "Error",
            /*attendeesList: ([{reg_id: 1, cont_nm: "Greg Strock", last_name: "Strock", registered_by: "Jiri Nemec", date: "June 8, 2020", grp_name: "Default", is_frst: 1, email: "greg.strock@josiahventure.com", reg_email: "greg.strock@josiahventure.com", comment: "None", grp_name: "Default", price: "+0.00 USD", birth_dt: "03.06.1988", is_gdpr_informed: 1, isOpen: false},
                         {reg_id: 2, cont_nm: "Jiri Němec", last_name: "Němec", registered_by: "Greg Strock", date: "June 8, 2020", grp_name: "Default", is_frst: 1, email: "jiri.nemec@josiahventure.com", reg_email: "greg.strock@josiahventure.com", comment: "None", grp_name: "Default", price: "+0.00 USD", birth_dt: "03.06.1988", is_gdpr_informed: 1, isOpen: false},
                         {reg_id: 3, cont_nm: "Mark Howland", last_name: "Howland", registered_by: "Jiri Nemec", date: "June 8, 2020", grp_name: "Default", is_frst: 1, email: "mark.howland@josiahventure.com", reg_email: "greg.strock@josiahventure.com", comment: "None", grp_name: "Default", price: "+0.00 USD", birth_dt: "03.06.1988", is_gdpr_informed: 1, isOpen: false}]),*/
            attendeesList: ([]),
            expandAll: false,
            needUpdateVar: true, // I am saving here value from last updateVar I received and then in render if this variable changes from what I get from parent, then I update data
        }
    }

    componentDidMount(){
        this.loadData();
        this.setState({ needUpdateVar: this.props.updateVar });
    }

    loadData = () => {
        const pathname = window.location.href;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))){ // if there is number received from URL

            // /api/evnt_part/get
            Axios.get('/api/evnt_part/get?part_tp=1&evnt_id='+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

                //alert(JSON.stringify(response.data.result[1]));

                var improvedArray = [];
                for(let oneRow of response.data.result){
                    var improvedObject = oneRow;

                    //alert(JSON.stringify(improvedObject));
                    //return;

                    /* I WOULD NEED TO GET ALSO THOSE ATTRIBUTES
                    oneRow.first_jv_evnt = "GET THIS ONE";
                    oneRow.comment = "GET THIS ONE";*/
                    oneRow.isOpen = false;





                    improvedArray.push(improvedObject);
                }


                // something
                //alert(JSON.stringify(response.data.result))

                this.setState({ attendeesList: response.data.result });
            });

            this.setState({ mainContactId: id });

        }
    }

    openCloseAttendee(regIdAttendee){
        var newArray = this.state.attendeesList;
        for(let oneRow of newArray){
            if(oneRow.reg_id == regIdAttendee){
                oneRow.isOpen = !oneRow.isOpen;
            }
        }
        this.setState({ attendeesList: newArray})
    }

    onEdit = async (id) => {
        await this.setState({ clickedAttendeeId: id });
        //this.openModal("EditAttendee");
    }

    onDelete = (id) => {
        /*Axios.delete('/api/conv/del/'+id, {
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.loadData();
        });*/
    }

    openCloseTile = () => {
        if(this.props.isOpen){
            this.props.setActualOpenedTile("");
        }
        else {
            this.props.setActualOpenedTile("attendees");
        }
    }

    expandCollapseAll(){
        var newArray = this.state.attendeesList;
        for(let oneRow of newArray){
            if(this.state.expandAll){
                oneRow.isOpen = false;
            }
            else {
                oneRow.isOpen = true;
            }
        }
        this.setState({ attendeesList: newArray });
        this.setState({ expandAll: !this.state.expandAll });
    }

    openLink = (link) => {
        if(link && link.length > 0){
            link = link.match(/^https?:/) ? link : '//' + link;
            window.open(link, "_blank", "noreferrer");
        }
    }

    openContactProfile = (cont_id) => {
        // check if this contact exists in the CRM
        Axios.get('/api/cont/get/'+cont_id).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            if(response.data.result.length == 0){
                this.props.openAlertMessageTwoActions("This contact is not imported in CRM or you don't have access to this contact.","OK","",null);
            }
            else {
                //window.open("/PeopleProfile/"+cont_id, '_blank', 'noreferrer');
                navigate("/PeopleProfile/"+cont_id);
                window.location.reload();
            }
        });
    }

    render() {
        if(this.state.needUpdateVar != this.props.updateVar){
            this.setState({ needUpdateVar: this.props.updateVar });
            this.loadData();
        }
        return (
            <div className="conversationsTile">

                <div className="horizontalStack">

                    <ExpandButton isOpen={this.props.isOpen} style={{marginTop: 5}} onClick={this.openCloseTile} />

                    <div style={{width: "20px"}}></div>

                    <div className="verticalStack flex" style={{display: "table", tableLayout: "fixed", width: "100%"}}>
                        <span className="fontPoppinsSemiBold15">{"Attendees"}</span>
                        <span className={this.props.isOpen ? "fontPoppinsRegular13Gray oneLineThreeDotsTextDeactivated" : "fontPoppinsRegular13Gray oneLineThreeDotsText"}>{"Use this tools for seeing a preview of registered attendees and a preview of their entry information. You can only add or edit each user in the EMS System."}</span>
                    </div>

                    {/*<div style={{width: "20px"}}></div>

                    <div>
                        <div className="horizontalStackCenter universalPopupContainer" style={{marginTop: 5}} onClick={() => alert("open modal attendee")}>
                            <div className="universalPopupVisible clickable">
                                <div className="circleButtonContainer circleButtonContainerOrange clickable">
                                    <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                                </div>
                            </div>

                            <div className="universalPopupHidden clickable">
                                <PopupButtonDescription title={"Add in the EMS System"} from={"left"} marginLeft={"-209px"} blackArrow={true} />
                            </div>
                        </div>
                    </div>*/}

                </div>

                <div className="horizontalStackCenter" style={{display: this.props.isOpen ? "" : "none"}}>

                        {/* I am using here styles from Roles tile */}
                        <table className="rolesTable" style={{marginTop: 10}}>
                            <tr>
                                <td className="rolesTableHeader rolesTableHeaderOther clickable" nowrap="nowrap" onClick={() => this.expandCollapseAll()}>
                                    <img className={this.state.expandAll ? "expandButtonExpanded onHoverToOrange" : "expandButton onHoverToOrange"}  style={{width: "10px", height: "10px"}} src={icon_expand}/>
                                </td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Name</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Registered by</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Registration Date</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Group</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">First JV Event?</td>
                            </tr>

                            {this.state.attendeesList.map(function (item) {
                                    return (
                                        <tbody>
                                        <tr className="clickable" onClick={() => this.openCloseAttendee(item.reg_id)} style={{backgroundColor: item.isOpen ? "var(--lightest-gray)" : ""}}>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther rolesTableRowExpandImage" nowrap="nowrap">
                                                <img className={item.isOpen ? "expandButtonExpanded" : "expandButton"}  style={{width: "10px", height: "10px"}} src={icon_expand}/>
                                            </td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.cont_nm}</td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther responsiveTableDiv" nowrap="nowrap">{item.reg_email}</td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.reg_dt ? <Moment format="MMMM D, YYYY">{item.reg_dt}</Moment> : null}</td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.grp_name}</td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.is_frst == 1 ? "Yes" : item.is_frst == 0 ? "No" : ""}</td>
                                        </tr>
                                        <div style={{display: item.isOpen ? "" : "none", height: 1}}></div>
                                        <tr style={{display: item.isOpen ? "" : "none", backgroundColor: "var(--lightest-gray)"}}>
                                            <td colSpan="6" style={{padding: 10}}>
                                                <div className="horizontalStack">

                                                    <div className="verticalStack flex">
                                                        <span className="fontPoppinsRegular13Gray">{"Registration Details:"}</span>
                                                        <span className="fontPoppinsRegular13" style={{overflowWrap: "break-word", marginTop: 20}}>{"Email: "}{item.email}</span>
                                                        <span className="fontPoppinsRegular13" style={{overflowWrap: "break-word"}}>{"First Name: "}{item.first_name}</span>
                                                        <span className="fontPoppinsRegular13" style={{overflowWrap: "break-word"}}>{"Last Name: "}{item.last_name}</span>
                                                        <span className="fontPoppinsRegular13" style={{overflowWrap: "break-word"}}>{"Group: "}{item.grp_name} {"("}{item.reg_price}{")"}</span>
                                                        <span className="fontPoppinsRegular13" style={{overflowWrap: "break-word"}}>{"Date of Birth: "}{item.birth_dt}</span>
                                                    </div>

                                                    <div style={{width: 20, height: 20}}></div>

                                                    <div className="verticalStack flex">
                                                        <span className="fontPoppinsRegular13Gray">{"Additional Details:"}</span>
                                                        <span className="fontPoppinsRegular13" style={{overflowWrap: "break-word", marginTop: 20}}>{"Registered By Email: "}{item.reg_email}</span>
                                                        <span className="fontPoppinsRegular13" style={{overflowWrap: "break-word"}}>{"Comment: "}{item.comment}</span>
                                                        <span className="fontPoppinsRegular13" style={{overflowWrap: "break-word"}}>{"Yes, you can use my personal data for these purposes: "}{item.is_gdpr_informed == 1 ? "Yes" : "No"}</span>

                                                        <div className="horizontalStack" style={{marginTop: 10}}>
                                                            <div className="flex"></div>
                                                            { item.cont_id ? <RoundButton title={"View Contact Profile"} className={"roundButtonOrange"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.openContactProfile(item.cont_id)} /> : null }

                                                            { !item.cont_id ? <div className="horizontalStackCenter universalPopupContainer">
                                                                <div className="universalPopupVisible clickable">
                                                                    <RoundButton title={"View Contact Profile"} className={"roundButtonLightGray"} style={{marginRight: "10px"}} whiteText={true} />
                                                                </div>

                                                                <div className="universalPopupHidden clickable">
                                                                    <PopupButtonDescription title={"This contact has not been connected to a Profile"} from={"left"} marginLeft={"-379px"} blackArrow={true} />
                                                                </div>
                                                            </div> : null }

                                                        </div>
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    )
                            }.bind(this))}

                        </table>

                </div>

            </div>
        );
    }
}

export default AttendeesTile;