import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles_m/tiles_m.css';
import '../styles/other.css';
import Axios from 'axios';
import { Link } from "react-router-dom";
import { navigate } from "@reach/router";
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';


// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import assets
import icon_church from '../assets/icon_church.png';
import icon_expand from '../assets/icon_expand.png';

class EventStatisticsTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            churchesList: ([{id: 1, org_name: "CB Brno", leadPastor: "Pavel Melotík", jvEngagement: "#4 Partnership"},
                         {id: 2, org_name: "AC Bohumín", leadPastor: "Karel Písek", jvEngagement: "#4 Partnership"},
                         {id: 2, org_name: "CB Frýdlant n. O.", leadPastor: "Tomáš Kolman", jvEngagement: "#4 Partnership"}]),
            mainContactId: 0,
            mainContactName: "",
            needUpdateVar: true, // I am saving here value from last updateVar I received and then in render if this variable changes from what I get from parent, then I update data
            /*dataTypesArray: ([{"fi_id":1,"fi_label":"First Name"},{"id":2,"fi_label":"City"},{"id":3,"fi_label":"Gender"}]),*/
            dataTypesArray: ([]),
            showPopupDataType: false,
            selectedDataType: -1,
            selectedDataTypeName: "",
            /*statisticsDataArray: ([{value: 5, label: "Yes"},{value: 4, label: "No"}]),*/
            statisticsDataArray: ([]),
        }
        this.refPopupDataType = React.createRef();
    }

    componentDidMount(){
        this.loadData();
        this.setState({ needUpdateVar: this.props.updateVar });
        window.addEventListener('click', this.onGlobalClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onGlobalClick);
    }

    /* This method is triggered on every click on screen and is checking if some popup is opened nad user clicked out of this popup so it would close that popup in that situation */
    onGlobalClick = (e) => {
        if ( (!this.refPopupDataType.current.contains(e.target)) && this.state.showPopupDataType) {
            // user clicked out of the PopupWithWhatTeam and that popup is now opened
            this.setState({ showPopupDataType: false })
        }
    }

    loadData = async () => {
        await this.setState({ mainContactId: 0 });
        await this.setState({ mainContactName: "" });

        const pathname = window.location.href;
        var contact_id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(contact_id.includes("?")){ contact_id = contact_id.substring(0, contact_id.indexOf("?")); }

        if(Number.isInteger(Number(contact_id))){ // if there is number received from URL

            await Axios.get('/api/rpt/evnt_fi/get?evnt_id='+contact_id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

                //alert(JSON.stringify(response.data));
                this.setState({ dataTypesArray: response.data.result });

                if(response.data.result.length > 0){
                    this.selectDataType(response.data.result[0].fi_id);
                }
            });
        }

    }

    loadStatisticsDataByType = (dataTypeId) => {

        Axios.get('/api/rpt/evnt_fv/get?fi_id='+dataTypeId).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

            //alert(JSON.stringify(response.data));
            //return;

            // PieChart needs different names of attributes
            var workingList = ([]);
            response.data.result.forEach((element, index) => {
                workingList.push({"value": element.cnt ? element.cnt : 0, "label": element.val ? element.val : "", "color": ""+this.getColor(index, response.data.result.length)});
            });
            this.setState({ statisticsDataArray: workingList });

        });

    }

    selectDataType = async (dataTypeId) => {
        this.setState({ selectedDataType: dataTypeId });

        for(let oneRow of this.state.dataTypesArray){
            if(oneRow.fi_id == dataTypeId){
                this.setState({ selectedDataTypeName: oneRow.fi_label });
            }
        }

        this.setState({ showPopupDataType: false });

        // load statistics by the selected type
        this.loadStatisticsDataByType(dataTypeId);
    }

    getColor = (index, size) => {
        if(index != undefined && size != undefined){
            if(size <= 2){
                if(index == 0)return "rgb(142, 159, 234)";
                else if(index == 1)return "rgb(212, 218, 249)";
            }
            else if(size <= 5) {
                const shortList = ["rgb(255,102,0)", "rgb(234,213,49)", "rgb(33,213,147)", "rgb(85,110,230)", "rgb(250,80,179)"];
                return shortList[index];
            }
            else {
                const longList = ["rgb(255,102,0)", "rgb(255,174,119)", "rgb(234,213,49)", "rgb(240,228,137)", "rgb(33,213,147)", "rgb(34,230,158)", "rgb(85,110,230)", "rgb(142,159,234)", "rgb(250,80,179)", "rgb(255,173,221)"];
                return longList[index%10];
            }
        }
        else {
            var color = '#';
            let min = 75;
            let max = 200;
            var red = min + (Math.random() * (max - min));
            var green = min + (Math.random() * (max - min));
            var blue = min + (Math.random() * (max - min));

            return "rgb("+red+","+green+","+blue+")";
        }
    }

    getPercentage = (value) => {
        var sum = 0;
        for(let oneRow of this.state.statisticsDataArray){ sum += oneRow.value; }

        var percentage = (100*value)/sum;
        var rounded = Math.round(percentage * 10) / 10;

        return rounded;
    }

    render() {
        if(this.state.needUpdateVar != this.props.updateVar){
            this.setState({ needUpdateVar: this.props.updateVar });
            this.loadData();
        }

        return (
            <div className={"churchInfoTile"}>

                <div className="verticalStack">
                    <div className="horizontalStackCenter flex">

                        <span className="fontPoppinsSemiBold15">Statistics / Reports:</span>

                        <div style={{flex: 1}}></div>

                        <div className="universalPopupSelectContainer" ref={this.refPopupDataType} style={{display: this.state.dataTypesArray.length > 0 ? "" : "none"}}>

                            <div className="universalPopupSelectVisible horizontalStackCenter flex">
                                <span className="fontPoppinsRegular13 clickable" style={{color: this.state.selectedDataType == -1 ? "var(--jv-orange)" : "var(--jv-orange)"}} onClick={()=>this.setState({showPopupDataType: !this.state.showPopupDataType})}>{"Choose Data Type"}</span>
                                <img className="circleButton colorToOrange" style={{marginLeft: 8, width: "8px", height: "8px", transform: "rotate(90deg)"}} src={icon_expand}/>
                            </div>

                            <div className="universalPopupSelectHidden" style={{display: this.state.showPopupDataType ? "" : "none"}}>
                                <div className="verticalStack" onClick={() => this.setState({showPopupDataType: false})}>
                                    <span className="fontPoppinsRegular13">Select Data Type</span>

                                    <div style={{height: 1, backgroundColor: "var(--border-light-gray)", marginTop: 10, marginBottom: 10}}></div>

                                    <div className="verticalStack" onChange={event => this.selectDataType(event.target.value)}>

                                        {this.state.dataTypesArray.map(function (item) {
                                            return (
                                                <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3}}>
                                                    <input className="clickable" type="radio" id={item.fi_id+""+item.fi_label} name="team_type" value={item.fi_id} checked={item.fi_id == this.state.selectedDataType ? true : false}/>
                                                    <label for={item.fi_id+""+item.fi_label} className="fontPoppinsRegular13 clickable" style={{marginLeft: 5}}>{item.fi_label}</label>
                                                </div>
                                            )
                                        }.bind(this))}

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="verticalStack" style={{marginTop: 20}}>
                        { this.state.dataTypesArray.length == 0 ?
                            <span className="fontPoppinsRegular13Gray">{"This event doesn\'t have any available statistics or reports."}</span>
                        :
                            <span className="fontPoppinsRegular13Gray">{this.state.selectedDataTypeName}</span>
                        }
                    </div>

                    <div className="horizontalStackCenter" style={{marginTop: 10, display: this.state.dataTypesArray.length > 0 ? "" : "none"}}>
                        <PieChart
                          series={[
                            {
                              data: this.state.statisticsDataArray,
                              /*arcLabel: (item) => `${item.label}`,*/
                              innerRadius: 2,
                              outerRadius: 60,
                              paddingAngle: 4,
                              cornerRadius: 0,
                              startAngle: 0,
                              endAngle: 360,
                              cx: 60,
                              cy: 60,
                            }
                          ]}
                          sx={{
                            [`& .${pieArcLabelClasses.root}`]: {
                                fill: 'black',
                                fontSize: 13,
                            },
                          }}
                          width={140}
                          height={140}
                          slotProps={{
                            legend: { hidden: true },
                          }}
                        />
                    </div>

                    <div className="verticalStack">
                        {this.state.statisticsDataArray.map(function (item) {
                            return (
                                <span className="fontPoppinsRegular13">{item.label+", "+item.value+" Registered Attendees, "+this.getPercentage(item.value)+" %"}</span>
                            )
                        }.bind(this))}
                    </div>
                </div>
            </div>
        );
    }
}

export default EventStatisticsTile;
