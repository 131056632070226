import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles_m/tiles_m.css';
import '../styles/other.css';
import Axios from 'axios';
import { Link } from "react-router-dom";
import { navigate } from "@reach/router";

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import assets
import icon_church from '../assets/icon_church.png';

class EventChurchesTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            /*churchesList: ([{id: 1, short_name: "CB Brno", leadPastor: "Pavel Melotík", jvEngagement: "#4 Partnership"},
                         {id: 2, short_name: "AC Bohumín", leadPastor: "Karel Písek", jvEngagement: "#4 Partnership"},
                         {id: 2, short_name: "CB Frýdlant n. O.", leadPastor: "Tomáš Kolman", jvEngagement: "#4 Partnership"}]),*/
            churchesList: ([]),
            mainContactId: 0,
            mainContactName: "",
            needUpdateVar: true, // I am saving here value from last updateVar I received and then in render if this variable changes from what I get from parent, then I update data
        }
    }

    componentDidMount(){
        this.loadData();
        this.setState({ needUpdateVar: this.props.updateVar });
    }

    loadData = async () => {
        await this.setState({ mainContactId: 0 });
        await this.setState({ mainContactName: "" });

        const pathname = window.location.href;
        var evnt_id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(evnt_id.includes("?")){ evnt_id = evnt_id.substring(0, evnt_id.indexOf("?")); }

        if(Number.isInteger(Number(evnt_id))){ // if there is number received from URL

            Axios.get('/api/evnt_part/get?part_tp=3&evnt_id='+evnt_id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

                this.setState({ churchesList: response.data.result });
                //alert(JSON.stringify(response.data));
            });

           /* await Axios.get('/api/cont/get/'+contact_id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                church_id = response.data.result[0].church_id;
                this.setState({ mainContactId: response.data.result[0].id });
                this.setState({ mainContactName: response.data.result[0].first_name + " " + response.data.result[0].last_name });
            });*/

            /*var church_id = -1;

            await Axios.get('/api/cont/get/'+contact_id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                church_id = response.data.result[0].church_id;
                this.setState({ mainContactId: response.data.result[0].id });
                this.setState({ mainContactName: response.data.result[0].first_name + " " + response.data.result[0].last_name });
            });


            if(church_id == -1 || church_id == null){ return; }

            Axios.get('/api/cprof/get/'+church_id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                //alert(response.data.result[0].cont_nm);
            });*/

        }

    }

    render() {
        if(this.state.needUpdateVar != this.props.updateVar){
            this.setState({ needUpdateVar: this.props.updateVar });
            this.loadData();
        }

        return (
            <div className={"churchInfoTile"}>

                <div className="horizontalStack">
                    <div className="verticalStack flex">

                        <span className="fontPoppinsSemiBold15">Churches Participating:</span>

                        {this.state.churchesList.length == 0 ? <span className="fontPoppinsRegular13DarkGray">{"No participating churches"}</span> : null}

                        {this.state.churchesList.map(function (item) {
                            return (
                                <div className="verticalStack" style={{marginTop: 20}}>
                                    <span className="fontPoppinsSemiBold15" style={{color: "var(--blue-button-link)"}}>{item.short_name}</span>
                                    <span className="fontPoppinsRegular13DarkGray">{"Lead Pastor"}: <span className="fontPoppinsRegular13Gray clickable">{item.pastor}</span></span>
                                    <span className="fontPoppinsSemiBold13Gray jvEngagementButton">JV Engagement: <span className="fontPoppinsSemiBold13DarkGray jvEngagementValue" onClick={() => alert("JvEngagement")}>{item.jve_cd}</span></span>

                                </div>
                            )
                        }.bind(this))}

                    </div>
                    <div className="verticalStack" style={{alignItems: "center"}}>
                        <img className="churchInfoTileIcon" src={icon_church}/>
                        <div className="flex"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EventChurchesTile;
