import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { Link, Navigate, useLocation } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { goToPrevEventUtilities, goToNextEventUtilities, goToFirstEventUtilities, goToLastEventUtilities } from '../utilities/PagingUtilities.js';

// import components
import ProfileEventTile from '../tiles/ProfileEventTile.js';
import ClosedTile from '../tiles/ClosedTile.js';
import ReturnToSearchResultsButton from '../components/ReturnToSearchResultsButton.js';
import EmptyChurchInfoTile from '../tiles/EmptyChurchInfoTile.js';
import EmptyClosedTile from '../tiles/EmptyClosedTile.js';
import AttendeesTile from '../tiles/AttendeesTile.js';
import EventChurchesTile from '../tiles/EventChurchesTile.js';
import EventStatisticsTile from '../tiles/EventStatisticsTile.js';

class EventProfile extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);
        this.refAttendeesScroll = React.createRef();

        this.state = {
            actualOpenedTile: "", // attendees
            updateVar: true, // to update change its value
            updateAttendeesTileVar: true, // to update change its value
            updateEventChurchesTileVar: true, // to update change its value
            tileMenuOpened: false,
            mainContactId: 0,
            contactIdPosition: 0,
            contactIdsArray: ([]),
        }
    }

    componentDidMount(){
        //this.setActualSection("events");

        const { tile } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(tile == "attendees"){ this.openTile("attendees"); }

        const pathname = this.props.location.pathname;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }
        if(Number.isInteger(Number(id)))this.setState({ mainContactId: parseInt(id) });

        try {
            const getIdsArray = localStorage.getItem('idsArray');
            const idsArray = JSON.parse(getIdsArray);
            this.setState({ contactIdPosition: idsArray.indexOf(parseInt(id))+1 });
            this.setState({ contactIdsArray: idsArray });
        }
        catch(err){
            alert("Error");
        }
    }

    setActualOpenedTile = (tile) => {
        this.setState({ actualOpenedTile: tile });
    }

    update = () => {
        if(this.state.updateVar){
            this.setState({ updateVar: false });
        }
        else {
            this.setState({ updateVar: true });
        }
    }

    updateAttendeesTile = () => {
        this.setState({ updateAttendeesTileVar: !this.state.updateAttendeesTileVar });
    }

    updateEventChurchesTile = () => {
        this.setState({ updateEventChurchesTileVar: !this.state.updateEventChurchesTileVar });
    }

    openTile = async (tile) => {
        await this.setActualOpenedTile(tile);
        await new Promise(resolve => setTimeout(resolve, 100));
        if(tile == "attendees"){ this.refAttendeesScroll.current.scrollIntoView({ behavior: "smooth", block: "start" }); }
    }

    render() {
        return(
                <div className="verticalStack flex" style={{height: "calc(100vh - 80px)", overflowY: "scroll"}}>
                    <div className="horizontalStackCenter myPeopleTableListingBar">
                        <ReturnToSearchResultsButton position={this.state.contactIdPosition} />
                        <div style={{display: this.state.contactIdPosition != 0 ? "" : "none", marginLeft: "40px"}}>
                            <span className="fontPoppinsSemiBold16 clickable onHoverToOrange" onClick={() => goToFirstEventUtilities(this.state.contactIdPosition-1, this.state.contactIdsArray)}>{"<<"}</span>
                            <span className="fontPoppinsSemiBold16 clickable onHoverToOrange" onClick={() => goToPrevEventUtilities(this.state.contactIdPosition-1, this.state.contactIdsArray)} style={{paddingLeft: "20px"}}>{"<"}</span>
                            <span className="fontPoppinsSemiBold16" style={{paddingLeft: "20px"}}>{this.state.contactIdPosition}{"/"}{this.state.contactIdsArray.length}</span>
                            <span className="fontPoppinsSemiBold16 clickable onHoverToOrange" onClick={() => goToNextEventUtilities(this.state.contactIdPosition-1, this.state.contactIdsArray)} style={{paddingLeft: "20px", paddingRight: "20px"}}>{">"}</span>
                            <span className="fontPoppinsSemiBold16 clickable onHoverToOrange" onClick={() => goToLastEventUtilities(this.state.contactIdPosition-1, this.state.contactIdsArray)}>{">>"}</span>
                        </div>
                        <div className="flex"/>
                        {this.state.tileMenuOpened ? <div>
                                <span className="fontPoppinsRegular13Gray onHoverToOrange clickable" style={{marginRight: 24}} onClick={() => this.openTile("attendees")}>{"Attendees"}</span>
                                <span className="fontPoppinsRegular13Orange clickable" onClick={() => this.setState({ tileMenuOpened: false })}>{"Hide Tile Menu"}</span>
                            </div>
                        : <span className="fontPoppinsRegular13Gray onHoverToOrange clickable" onClick={() => this.setState({ tileMenuOpened: true })}>{"Show Tile Menu"}</span> }
                    </div>

                    <div className="horizontalStack">

                        <div className="verticalStack flex">
                            <ProfileEventTile onCloseOrOpen={this.setActualOpenedTile} openAlertMessage={this.props.openAlertMessage} />

                            <div style={{marginTop: "20px"}}></div>

                            <div ref={this.refAttendeesScroll} className="verticalStack">

                                {/*<EmptyClosedTile />*/}

                                <AttendeesTile isOpen={this.state.actualOpenedTile == "attendees" ? true : false} setActualOpenedTile={this.setActualOpenedTile} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} updateVar={this.state.updateAttendeesTileVar} />

                                {/*<ClosedTile title={"Attendees"} description={"Use this tools for seeing a preview of registered attendees and a preview of their entry information. You can only add or edit each user in the EMS System."} />*/}

                                <div style={{marginTop: "20px"}}></div>

                            </div>
                        </div>

                        <div className="verticalStack">
                            <EventChurchesTile updateVar={this.state.updateEventChurchesTileVar} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />
                            <div style={{marginTop: "20px"}}></div>
                            <EventStatisticsTile updateVar={this.state.updateEventChurchesTileVar} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />
                            <div style={{marginTop: "20px"}}></div>
                        </div>

                    </div>
                </div>
        );
    }
}

const withLocation = Component => props => {
    const location = useLocation();

    return <Component {...props} location={location} />;
};

export default withLocation(EventProfile);