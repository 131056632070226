import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/components.css';
import Axios from 'axios';
import { navigate } from "@reach/router";

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { deviceWidthLowerThan } from '../utilities/OtherUtilities.js';

class SearchBoxEvents extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            foundEvents: ([]),
        };
    }

    handleKeyPress = (e) => {
        if(e.key === 'Enter'){
            //this.props.onEnter();
            var localSearchText = e.target.value;
            this.props.setSearchText(localSearchText);
            if(localSearchText == undefined)return;
            localStorage.setItem("actualPageEvents", 0);
            localStorage.setItem("sortByEvents", "");
            localStorage.setItem("sortTpEvents", "asc");
            //navigate("/Events?search="+localSearchText); // I dont need this line because I am sending it through localStorage
            window.location.reload();
        }
    }

    onChange = (event) => {
        this.props.setSearchText(event.target.value);
        if(event.target.value.length >= 3){
            Axios.get('/api/evnt/get?search='+event.target.value).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                this.setState({ foundEvents: response.data.result });
                //alert(JSON.stringify(response.data.result[0]))
            });
        }
        else {
            this.setState({ foundEvents: [] });
        }
    }

    onEventClick = (event, id) => {
        if(event.ctrlKey || event.metaKey){ window.open("/EventProfile/"+id, '_blank', 'noreferrer'); }
        else { navigate("/EventProfile/"+id); window.location.reload(); }
    }

    timeout = (delay) => {
        return new Promise( res => setTimeout(res, delay) );
    }

    hideAfterDelay = async () => {
        await this.timeout(200); // when I do it immediately the click in dropdown does not work because the dropdown disappears
        this.props.setSearchText("");
    }

    render(){
        return(
            <div className="" style={{minWidth: "140px", maxWidth: "380px", width: "100%", position: "relative"}}>
                <div className="topMenuSearchBox horizontalStackCenter" style={{position: "relative", zIndex: 5}}>
                    <input type="text" className="topMenuSearchBoxInput fontPoppinsRegular13" placeholder={deviceWidthLowerThan(400) ? "Events..." : "Search Events..."} value={this.props.searchText} onChange={(event) => this.onChange(event)} onKeyUp={this.handleKeyPress} onBlur={() => this.hideAfterDelay()} />
                </div>
                <div style={{display: this.state.foundEvents.length != 0 && this.props.searchText != "" ? "" : "none", width: "100%", position: "absolute", backgroundColor: "white", border: "0px solid white", borderRadius: "0px 0px 20px 20px", zIndex: 4, marginTop: -25, paddingTop: 25, boxShadow: "2px 10px 50px -20px var(--search-box-shadow)"}}>
                    {this.state.foundEvents.map(function (item, index) {
                        if (index > 10) { return; }
                        if (index == 10) {
                            return (
                                <div className="flex verticalStack" style={{width: "100%", paddingLeft: 10, paddingRight: 10}}>
                                    {index != 0 ? <div style={{height: 1, backgroundColor: "var(--lightest-gray)", marginLeft: -10, marginRight: 10}}></div> : null }
                                    <div style={{height: 12}}></div>
                                    <span className="fontPoppinsRegular13DarkGray" style={{paddingLeft: 30}}>{"And more..."}</span>
                                    <div style={{height: 12}}></div>
                                </div>
                            )
                        }
                        return (
                            <div className="flex verticalStack clickable" style={{width: "100%", paddingLeft: 10, paddingRight: 10}} onClick={(event) => this.onEventClick(event, item.id)}>
                                {index != 0 ? <div style={{height: 1, backgroundColor: "var(--lightest-gray)", marginLeft: -10, marginRight: 10}}></div> : null }
                                <div style={{height: 12}}></div>
                                <span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange oneLineThreeDotsText" style={{paddingLeft: 30, paddingRight: 30}}>{item.evnt_nm}</span>
                                <div style={{height: 12}}></div>
                            </div>
                        )
                    }.bind(this))}
                </div>
            </div>
        );
	}
}

export default SearchBoxEvents;