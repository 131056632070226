import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { Link, Navigate, useLocation } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { goToPrevContactUtilities, goToNextContactUtilities, goToFirstContactUtilities, goToLastContactUtilities, openContactUtilities } from '../utilities/PagingUtilities.js';

// import components
import ProfileTilePrivate from '../tiles/ProfileTilePrivate.js';
import ConversationsTile from '../tiles/ConversationsTile.js';
import DiagnosticTilePrivate from '../tiles/DiagnosticTilePrivate.js';
import ReturnToSearchResultsButton from '../components/ReturnToSearchResultsButton.js';

class PeoplePrivateProfile extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);
        this.refConvScroll = React.createRef();

        this.state = {
            actualOpenedTile: "", //profiledata, conversations, roles, relationships, diagnosticlens
            updateVar: true, // to update change its value
            tileMenuOpened: false,
            mainContactId: 0,
            contactIdPosition: 0,
            contactIdsArray: ([]),
        }
    }

    componentDidMount(){
        //this.setActualSection("people");

        const { tile } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(tile == "conversations"){ this.openTile("conversations"); }

        const pathname = this.props.location.pathname;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }
        if(Number.isInteger(Number(id)))this.setState({ mainContactId: parseInt(id) });

        try {
            const getIdsArray = localStorage.getItem('idsArray');
            const idsArray = JSON.parse(getIdsArray);
            this.setState({ contactIdPosition: idsArray.indexOf(parseInt(id))+1 });
            this.setState({ contactIdsArray: idsArray });
        }
        catch(err){
            alert("Error");
        }
    }

    setActualOpenedTile = (tile) => {
        this.setState({ actualOpenedTile: tile });
    }

    update = () => {
        if(this.state.updateVar){
            this.setState({ updateVar: false });
        }
        else {
            this.setState({ updateVar: true });
        }
    }

    openTile = async (tile) => {
        await this.setActualOpenedTile(tile);
        await new Promise(resolve => setTimeout(resolve, 100));
        if(tile == "conversations"){ this.refConvScroll.current.scrollIntoView({ behavior: "smooth", block: "start" }); }
    }

    render() {
        return(
                <div className="verticalStack flex" style={{height: "calc(100vh - 80px)", overflowY: "scroll"}}>
                    <div className="horizontalStackCenter myPeopleTableListingBar">
                        <ReturnToSearchResultsButton position={this.state.contactIdPosition} />
                        <div style={{display: this.state.contactIdPosition != 0 ? "" : "none", marginLeft: "40px"}}>
                            <span className="fontPoppinsSemiBold16 clickable onHoverToOrange" onClick={() => goToFirstContactUtilities(this.state.contactIdPosition-1, this.state.contactIdsArray)}>{"<<"}</span>
                            <span className="fontPoppinsSemiBold16 clickable onHoverToOrange" onClick={() => goToPrevContactUtilities(this.state.contactIdPosition-1, this.state.contactIdsArray)} style={{paddingLeft: "20px"}}>{"<"}</span>
                            <span className="fontPoppinsSemiBold16" style={{paddingLeft: "20px"}}>{this.state.contactIdPosition}{"/"}{this.state.contactIdsArray.length}</span>
                            <span className="fontPoppinsSemiBold16 clickable onHoverToOrange" onClick={() => goToNextContactUtilities(this.state.contactIdPosition-1, this.state.contactIdsArray)} style={{paddingLeft: "20px", paddingRight: "20px"}}>{">"}</span>
                            <span className="fontPoppinsSemiBold16 clickable onHoverToOrange" onClick={() => goToLastContactUtilities(this.state.contactIdPosition-1, this.state.contactIdsArray)}>{">>"}</span>
                        </div>
                        <div className="flex"/>
                        {this.state.tileMenuOpened ? <div>
                                <span className="fontPoppinsRegular13Gray onHoverToOrange clickable" style={{marginRight: 24}} onClick={() => this.openTile("conversations")}>{"Conversations"}</span>
                                <span className="fontPoppinsRegular13Orange clickable" onClick={() => this.setState({ tileMenuOpened: false })}>{"Hide Tile Menu"}</span>
                            </div>
                        : <span className="fontPoppinsRegular13Gray onHoverToOrange clickable" onClick={() => this.setState({ tileMenuOpened: true })}>{"Show Tile Menu"}</span> }
                    </div>

                    <div className="horizontalStack">

                        <div className="verticalStack flex">
                            <ProfileTilePrivate onCloseOrOpen={this.setActualOpenedTile} openAlertMessage={this.props.openAlertMessage} />

                            <div style={{marginTop: "20px"}}></div>

                            <div ref={this.refConvScroll} className="verticalStack">

                                <ConversationsTile isOpen={this.state.actualOpenedTile == "conversations" ? true : false} setActualOpenedTile={this.setActualOpenedTile} contactMode={"person"} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />

                                <div style={{marginTop: "20px"}}></div>

                            </div>
                        </div>

                        <div className="verticalStack">
                            <DiagnosticTilePrivate mode={"person"} updateVar={this.state.updateVar} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />
                            <div style={{marginTop: "20px"}}></div>
                        </div>

                    </div>
                </div>
        );
    }
}

const withLocation = Component => props => {
    const location = useLocation();

    return <Component {...props} location={location} />;
};

export default withLocation(PeoplePrivateProfile);