import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { useLocation, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';
import Resizer from "react-image-file-resizer";

// import assets
import person_avatar from '../assets/person_avatar.png';
import background from '../assets/background.jpg';
import icon_expand from '../assets/icon_expand.png';
import icon_add_white from '../assets/icon_add_white.png';
import icon_done_white from '../assets/icon_done_white.png';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { getInitials } from '../utilities/OtherUtilities.js';

// import components
import RoundButton from '../components/RoundButton.js';
import PopupButtonDescription from '../components/PopupButtonDescription.js';
import DeleteDialog from '../components/DeleteDialog.js';
import ExpandButton from '../components/ExpandButton.js';
import ProfileImage from '../components/ProfileImage.js';
import RelationshipsModal from '../modals/RelationshipsModal.js';

class ProfileTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            updateVar: true, // I am saving here value from last updateVar I received and then in render if this variable changes from what I get from parent, then I update data
            mainContactId: 0,
            updateExisting: true, // if I am in create new contact mode it would be false
            isMyContact: false, // default should be false
            toolOpened: false,
            showDeleteDialog: false,
            sectionNameOpened: false,
            sectionContactDetailsOpened: false,
            sectionLocationOpened: false,
            sectionPersonalDetailsOpened: false,
            actualProfile: ([{id:1,first_name:"",last_name:"",email:"",phone:"",church:"Error",city:"",country:"",country_id:"",nick_name:"",is_staff:0}]),
            actualProfileEmails: ([{email: "", email_tp_id: 0, is_primary: 1},{email: "", email_tp_id: 0, is_primary: 0}]),
            actualProfilePhones: ([{phone: "", phone_tp_id: 0, is_primary: 1},{phone: "", phone_tp_id: 0, is_primary: 0}]),
            actualProfileWebsites: ([{website: "", website_tp_id: 0},{website: "", website_tp_id: 0}]),
            actualProfileSocnets: ([{socnet: "", platform_id: 0},{socnet: "", platform_id: 0}]),
            actualProfileMsgApps: ([{msgapp: "", msgapp_tp_id: 0, is_primary: 1},{msgapp: "", msgapp_tp_id: 0, is_primary: 0}]),
            fullAddress: "",
            googleDataFullAddress: "unknown",
            googleDataRegion: "unknown",
            listOfRegions: ([{"id":1,"label":"East"}]),
            listOfCountries: ([{"id":1,"label":"ALL"},{"id":2,"label":"Afghanistan"},{"id":3,"label":"Aland Islands"}]),
            listOfPrefix: ([{id:1, label: "Bc."}]),
            listOfSuffix: ([{id:1, label: "Ph.D."}]),
            array_email_types: ([{id:1, label: "Loading..."}]),
            array_msgapp_types: ([{id:1, label: "Loading..."}]),
            array_phone_locations: ([{id:1, label: "Loading..."}]),
            array_website_type: ([{id:1, label: "Loading..."}]),
            array_social_media_platform: ([{id:1, label: "Loading..."}]),
            uploadedImageBase64: "",
            prepareToShowModalAddRelationship: false,
            showModalAddRelationship: false,
        }

        this.hiddenFileInput = React.createRef();
        this.refBirthDay = React.createRef();
    }

    async componentDidMount(){
        this.setState({ updateVar: this.props.updateVar });
        //const queryParams = new URLSearchParams(window.location.search);
        //const id = queryParams.get('id');
        this.loadData();
    }

    loadData = async () => {
        this.setState({ uploadedImageBase64: "" });
        // I need to load this before loading person, because than I am setting the default country when creating new person
        // get all countries for country select
        await Axios.get('/api/perm_cntry/get/').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ listOfCountries: response.data.result })
        });

        const pathname = this.props.location.pathname;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))){ // if there is number received from URL
            if(Number(id) == 0){
                if(window.history.length > 1) navigate(-1);
                else { navigate("/People"); window.location.reload(); }
                return;
            }
            await Axios.get('/api/cont/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    this.props.openAlertMessage("You don't have rights to this contact.","OK");
                    navigate(-1);
                }
                else {
                    this.setState({ actualProfile: response.data.result });
                    this.setState({ isMyContact: response.data.result[0].my_cont });
                }
            });
            this.setState({ updateExisting: true });
            this.setState({ mainContactId: id });
            this.loadRegionsForCountry();

            // get full address for user
            Axios.get('/api/addr/get?cont_id='+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(typeof(response.data.result[0].addr) === "undefined")return;
                this.setState({ fullAddress: response.data.result[0].addr });
                this.setState({ googleDataRegion: response.data.result[0].adm_lvl_1 });
                this.setState({ googleDataFullAddress: response.data.result[0].fmtd_addr });
            });

            // get avatar image of contact
            Axios.get('/api/file/load?file_prefix=avatar&cont_id='+id).then(response => {
                //if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.stat && response.data.stat == "ERR"){
                    // avatar image was not loaded
                }
                else this.setState({ uploadedImageBase64: response.data });
                //alert(JSON.stringify(response.data))
            });

            // get array of emails
            Axios.get('/api/email/get?cont_id='+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    response.data.result.push({email: "", email_tp_id: 0, is_primary: 1});
                    response.data.result.push({email: "", email_tp_id: 0, is_primary: 0});
                }
                else if(response.data.result.length == 1){
                    response.data.result.push({email: "", email_tp_id: 0, is_primary: 0});
                }
                this.setState({ actualProfileEmails: response.data.result })
            });

            // get array of phones
            Axios.get('/api/phone/get?cont_id='+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    response.data.result.push({phone: "", phone_tp_id: 0, is_primary: 1});
                    response.data.result.push({phone: "", phone_tp_id: 0, is_primary: 0});
                }
                else if(response.data.result.length == 1){
                    response.data.result.push({phone: "", phone_tp_id: 0, is_primary: 0});
                }
                this.setState({ actualProfilePhones: response.data.result })
            });

            // get array of websites
            Axios.get('/api/website/get?cont_id='+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    response.data.result.push({website: "", website_tp_id: 0});
                    response.data.result.push({website: "", website_tp_id: 0});
                }
                else if(response.data.result.length == 1){
                    response.data.result.push({website: "", website_tp_id: 0});
                }
                this.setState({ actualProfileWebsites: response.data.result })
            });

            // get array of social networks
            Axios.get('/api/socnet/get?cont_id='+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    response.data.result.push({socnet: "", platform_id: 0});
                    response.data.result.push({socnet: "", platform_id: 0});
                }
                else if(response.data.result.length == 1){
                    response.data.result.push({socnet: "", platform_id: 0});
                }
                this.setState({ actualProfileSocnets: response.data.result })
            });

            // get array of message apps
            Axios.get('/api/msgapp/get?cont_id='+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    response.data.result.push({msgapp: "", msgapp_tp_id: 0, is_primary: 1});
                    response.data.result.push({msgapp: "", msgapp_tp_id: 0, is_primary: 0});
                }
                else if(response.data.result.length == 1){
                    response.data.result.push({msgapp: "", msgapp_tp_id: 0, is_primary: 0});
                }
                this.setState({ actualProfileMsgApps: response.data.result })
            });

            this.setState({ sectionNameOpened: true });
        }
        else {
            // not editing but creating new contact
            this.setState({ updateExisting: false });
            this.setState({ toolOpened: true });
            this.setState({ sectionNameOpened: true });
            this.setState({ sectionLocationOpened: true });

            Axios.get('/api/perm_cntry/get').then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                var permissionCountryId = response.data.result[0].id;
                if(permissionCountryId == 1)permissionCountryId = 61; // if has right to ALL, set Czech republic
                this.changeCountryNameById(permissionCountryId);
            });
        }

        // get all prefix for prefix select
        Axios.get('/api/prefix/get/').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ listOfPrefix: response.data.result })
        });

        // get all suffix for suffix select
        Axios.get('/api/suffix/get/').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ listOfSuffix: response.data.result })
        });

        // get email types
        Axios.get('/api/email_tp/get?cont_tp=1').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ array_email_types: response.data.result })
        });

        // get messenger app types
        Axios.get('/api/msgapp_tp/get?cont_tp=1').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ array_msgapp_types: response.data.result })
        });

        // get phone locations
        Axios.get('/api/phone_tp/get?cont_tp=1').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ array_phone_locations: response.data.result })
        });

        // get website types
        Axios.get('/api/website_tp/get/').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ array_website_type: response.data.result })
        });

        // get social media platforms
        Axios.get('/api/platform/get/').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ array_social_media_platform: response.data.result })
        });
    }

    loadRegionsForCountry = () => {
        // get all countries for selected country
        var countryId = this.state.actualProfile[0].country_id;
        var url = "/api/region/get/";
        if(countryId != 0 && countryId > 0){url = "/api/region/get?cntry_id="+countryId;}

        Axios.get(url).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            var workingList = [];
            for(let oneRow of response.data.result){ workingList.push({"id":oneRow.id, "label": oneRow.descr}); }
            this.setState({ listOfRegions: workingList });
        });
    }

    createNewAction = () => {
        if(this.checkIfDataAreOk() == false){ return; }

        // prepare array of emails
        var finalEmailsArray = [];
        for(let oneRow of this.state.actualProfileEmails){
            if(oneRow.email == "")continue;
            finalEmailsArray.push({"Email":oneRow.email, "EmailTpId": oneRow.email_tp_id, "IsPrimary": oneRow.is_primary});
        }

        // prepare array of phones
        var finalPhonesArray = [];
        for(let oneRow of this.state.actualProfilePhones){
            if(oneRow.phone == "")continue;
            finalPhonesArray.push({"Phone":oneRow.phone, "PhoneTpId": oneRow.phone_tp_id, "IsPrimary": oneRow.is_primary});
        }

        // prepare array of websites
        var finalWebsitesArray = [];
        for(let oneRow of this.state.actualProfileWebsites){
            if(oneRow.website == "")continue;
            finalWebsitesArray.push({"Website":oneRow.website, "WebsiteTpId": oneRow.website_tp_id});
        }

        // prepare array of social networks
        var finalSocnetsArray = [];
        for(let oneRow of this.state.actualProfileSocnets){
            if(oneRow.socnet == "")continue;
            finalSocnetsArray.push({"SocNet":oneRow.socnet, "PlatformId": oneRow.platform_id});
        }

        // prepare array of message apps
        var finalMsgAppsArray = [];
        for(let oneRow of this.state.actualProfileMsgApps){
            if(oneRow.msgapp == "")continue;
            finalMsgAppsArray.push({"MsgApp":oneRow.msgapp, "MsgAppTpId": oneRow.msgapp_tp_id, "IsPrimary": oneRow.is_primary});
        }

        Axios.post('/api/cont/ins', {
            ContTpId: 1, // idType=1 is for person idType=4 is for church
            FirstName: this.state.actualProfile[0].first_name,
            MiddleName: this.state.actualProfile[0].middle_name,
            LastName: this.state.actualProfile[0].last_name,
            NickName: this.state.actualProfile[0].nick_name,
            Email: finalEmailsArray,
            Phone:  finalPhonesArray,
            Website: finalWebsitesArray,
            SocNet: finalSocnetsArray,
            MsgApp: finalMsgAppsArray,
            City:  this.state.actualProfile[0].city,
            CountryId: this.state.actualProfile[0].country_id,
            BirthDt: this.state.actualProfile[0].birth_dt,
            GenderId: this.state.actualProfile[0].gender_id,
            PrefixId: this.state.actualProfile[0].prefix_id,
            SuffixId: this.state.actualProfile[0].suffix_id,
            RegionId: this.state.actualProfile[0].region_id,
            IsStaff: this.state.actualProfile[0].is_staff,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }

            if(this.state.fullAddress != undefined && this.state.fullAddress != ""){
                Axios.post('/api/addr/inup', {
                    ContId: response.data.msg.id,
                    Addr: this.state.fullAddress,
                }).then((response) => {
                    if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                });
            }
            if(this.state.uploadedImageBase64 != ""){
                this.uploadAvatarImage(response.data.msg.id);
            }

            const { from } = Object.fromEntries(new URLSearchParams(window.location.search));
            if(from == "MyDiscipling"){
                // add created contact to the MY_DSC
                Axios.post("/api/my_dsc/ins", {
                    ContId: response.data.msg.id,
                }).then((responseTwo) => {
                    if(checkForErrorsInRequest(responseTwo.data.msg, this.props.openAlertMessage) == 1){ return; }
                    // show profile of created contact where user can add D_LEVEL
                    this.setState({ redirectTo: "/PeopleProfile/"+response.data.msg.id+"?from=MyDiscipling" });
                });
            }
            else {
                this.setState({ redirectTo: "/PeopleProfile/"+response.data.msg.id });
            }

        });
    };

    saveChangesAction = () => {
        if(this.checkIfDataAreOk() == false){ return; }

        // prepare array of emails
        var finalEmailsArray = [];
        for(let oneRow of this.state.actualProfileEmails){
            if(oneRow.email == "")continue;
            finalEmailsArray.push({"Email":oneRow.email, "EmailTpId": oneRow.email_tp_id, "IsPrimary": oneRow.is_primary});
        }

        // prepare array of phones
        var finalPhonesArray = [];
        for(let oneRow of this.state.actualProfilePhones){
            if(oneRow.phone == "")continue;
            finalPhonesArray.push({"Phone":oneRow.phone, "PhoneTpId": oneRow.phone_tp_id, "IsPrimary": oneRow.is_primary});
        }

        // prepare array of websites
        var finalWebsitesArray = [];
        for(let oneRow of this.state.actualProfileWebsites){
            if(oneRow.website == "")continue;
            finalWebsitesArray.push({"Website":oneRow.website, "WebsiteTpId": oneRow.website_tp_id});
        }

        // prepare array of social networks
        var finalSocnetsArray = [];
        for(let oneRow of this.state.actualProfileSocnets){
            if(oneRow.socnet == "")continue;
            finalSocnetsArray.push({"SocNet":oneRow.socnet, "PlatformId": oneRow.platform_id});
        }

        // prepare array of message apps
        var finalMsgAppsArray = [];
        for(let oneRow of this.state.actualProfileMsgApps){
            if(oneRow.msgapp == "")continue;
            finalMsgAppsArray.push({"MsgApp":oneRow.msgapp, "MsgAppTpId": oneRow.msgapp_tp_id, "IsPrimary": oneRow.is_primary});
        }

        //alert(this.state.actualProfile[0].prefix_id);
        Axios.post('/api/cont/upd/'+this.state.mainContactId, {
            ContTpId: 1, // idType=1 is for person idType=4 is for church
            FirstName: this.state.actualProfile[0].first_name,
            MiddleName: this.state.actualProfile[0].middle_name,
            LastName: this.state.actualProfile[0].last_name,
            NickName: this.state.actualProfile[0].nick_name,
            Email: finalEmailsArray,
            Phone:  finalPhonesArray,
            Website: finalWebsitesArray,
            SocNet: finalSocnetsArray,
            MsgApp: finalMsgAppsArray,
            City:  this.state.actualProfile[0].city,
            CountryId: this.state.actualProfile[0].country_id,
            BirthDt: this.state.actualProfile[0].birth_dt,
            GenderId: this.state.actualProfile[0].gender_id,
            PrefixId: this.state.actualProfile[0].prefix_id,
            SuffixId: this.state.actualProfile[0].suffix_id,
            RegionId: this.state.actualProfile[0].region_id,
            IsStaff: this.state.actualProfile[0].is_staff,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }

            if(this.state.fullAddress != undefined && this.state.fullAddress != ""){
                Axios.post('/api/addr/inup', {
                    ContId: this.state.mainContactId,
                    Addr: this.state.fullAddress,
                }).then((response) => {
                    if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                    this.loadData();
                });
            }

            this.openCloseTool();
            this.loadData();
            //this.setState({ redirectTo: "/People" });
        });
    };

    checkIfDataAreOk = () => {
        if(this.state.actualProfile[0].first_name == "" || this.state.actualProfile[0].first_name == undefined){
            this.props.openAlertMessage("You didn't fill first name.","OK");
            this.setState({ toolOpened: true });
            this.setState({ sectionNameOpened: true });
            this.setState({ sectionLocationOpened: false });
            this.setState({ sectionContactDetailsOpened: false });
            this.setState({ sectionPersonalDetailsOpened: false });
            return false;
        }
        else if(this.state.actualProfile[0].last_name == "" || this.state.actualProfile[0].last_name == undefined){
            this.props.openAlertMessage("You didn't fill last name.","OK");
            this.setState({ toolOpened: true });
            this.setState({ sectionNameOpened: true });
            this.setState({ sectionLocationOpened: false });
            this.setState({ sectionContactDetailsOpened: false });
            this.setState({ sectionPersonalDetailsOpened: false });
            return false;
        }
        else if(this.state.actualProfile[0].city == "" || this.state.actualProfile[0].city == undefined){
            this.props.openAlertMessage("You didn't fill city.","OK");
            this.setState({ toolOpened: true });
            this.setState({ sectionNameOpened: false });
            this.setState({ sectionLocationOpened: true });
            this.setState({ sectionContactDetailsOpened: false });
            this.setState({ sectionPersonalDetailsOpened: false });
            return false;
        }
        else if(this.state.actualProfile[0].country_id == "" || this.state.actualProfile[0].country_id == undefined){
            this.props.openAlertMessage("You didn't fill country.","OK");
            this.setState({ toolOpened: true });
            this.setState({ sectionNameOpened: false });
            this.setState({ sectionLocationOpened: true });
            this.setState({ sectionContactDetailsOpened: false });
            this.setState({ sectionPersonalDetailsOpened: false });
            return false;
        }
        // check year validity
        const objectBirthDate = new Date(this.state.actualProfile[0].birth_dt);
        if(objectBirthDate.getFullYear() < 1900){
            this.props.openAlertMessage("Year cannot be lower than 1900.","OK");
            this.setState({ toolOpened: true });
            this.setState({ sectionNameOpened: false });
            this.setState({ sectionLocationOpened: false });
            this.setState({ sectionContactDetailsOpened: false });
            this.setState({ sectionPersonalDetailsOpened: true });
            return false;
        }

        return true;
    }

    openSpecificPartOfProfileTile = (openTool, openNameSection, openLocationSection, openContactDetailsSection, openPersonalDetailsSection) => {
        this.setState({ toolOpened: openTool });
        this.setState({ sectionNameOpened: openNameSection });
        this.setState({ sectionLocationOpened: openLocationSection });
        this.setState({ sectionContactDetailsOpened: openContactDetailsSection });
        this.setState({ sectionPersonalDetailsOpened: openPersonalDetailsSection });
    }

    deleteAction = () => {
        Axios.delete('/api/cont/del/'+this.state.mainContactId, {
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ redirectTo: "/People" });
        });
    };

    askToDelete = () => {
        this.setState({ showDeleteDialog: true });
    }

    cancelDeleting = () => {
        this.setState({ showDeleteDialog: false });
    }

    openCloseTool = () => {
        if(this.state.toolOpened){
            this.setState({ toolOpened: false });
            this.props.onCloseOrOpen("");
        }
        else {
            this.setState({ toolOpened: true });
            this.props.onCloseOrOpen("profiledata");
        }
    }

    addToMyContacts = () => {
        if(this.state.isMyContact){
            // delete
            Axios.delete('/api/my_cont/del/', {
                data: {ContId: this.state.mainContactId},
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ isMyContact: false });
            });
        }
        else {
            // add
            Axios.post('/api/my_cont/ins/', {
                ContId: this.state.mainContactId,
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ isMyContact: true });
            });
        }
    }

    changeCountryNameById = (id) => {
        var countryName = "";

        // find name of country by country_id
        this.state.listOfCountries.forEach((row)=>{
            if(row.id == id){
                countryName = row.label;
            }
        })

        // change country name in the object
        this.setState(prevState => {
            let actualProfile = Object.assign({}, prevState.actualProfile);
            actualProfile[0].country_id = id;
            actualProfile[0].country = countryName;
            return {actualProfile}; }
        )

        // delete selected region (because country was changed)
        this.setState(prevState => {
            let actualProfile = Object.assign({}, prevState.actualProfile);
            actualProfile[0].region_id = "";
            return {actualProfile};
        });
        this.changeRegionNameById(-1);

        this.loadRegionsForCountry();
    }

    changeRegionNameById = (id) => {
        var regionName = "";

        // find name of country by country_id
        this.state.listOfRegions.forEach((row)=>{
            if(row.id == id){
                regionName = row.label;
            }
        })

        // change regionName name in the object
        this.setState(prevState => {
            let actualProfile = Object.assign({}, prevState.actualProfile);
            actualProfile[0].region = regionName;
            return {actualProfile}; }
        )
    }

    /* START OF METHODS FOR AVATAR IMAGE */

    toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    uploadImageClick = () => { this.hiddenFileInput.current.click(); };

    deleteProfileImage = () => {
        Axios.post('/api/file/delete', {
            FilePrefix: "avatar",
            ContId: this.state.mainContactId,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ uploadedImageBase64: "" });
            // reload page so that the change would be shown in TopMenu
            window.location.reload();
        });
    }

    imageIsSelected = async (event) => {
        const fileUploaded = event.target.files[0];
        if(fileUploaded.size > 5000000){
            this.props.openAlertMessage("Error! Maximum size of picture is 5 MB.","OK");
            return;
        }
        var base64 = await this.toBase64(fileUploaded);
        var prefix = fileUploaded.name.substring(fileUploaded.name.lastIndexOf(".")+1);
        //this.setState({ uploadedImageBase64: base64 });

        try {
            Resizer.imageFileResizer(fileUploaded, 300, 300, "JPEG", 100, 0,
                (uri) => {
                    //console.log(uri);
                    this.setState({ uploadedImageBase64: uri });
                    if(this.state.updateExisting){
                        this.uploadAvatarImage(this.state.mainContactId);
                    }
                },
                "base64", 200,  200
            );
        }
        catch (err) {
            //console.log(err);
            this.props.openAlertMessage("Error while uploading image.","OK");
        }
        //var improvedBase64 = base64.substring(base64.lastIndexOf(";base64,")+8);
    };

    uploadAvatarImage = (contactId) => {
        if(this.state.uploadedImageBase64 == "")return;
        Axios.post('/api/file/upload', {
            FilePrefix: "avatar",
            ContId: contactId,
            content: this.state.uploadedImageBase64,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            // reload page so that the change would be shown in TopMenu
            window.location.reload();
        });
    }

    /* END OF METHODS FOR AVATAR IMAGE */

    selectUnselectIsStaff = () => {
        if(this.state.actualProfile[0].is_staff == 1) this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile);actualProfile[0].is_staff = 0; return {actualProfile}; });
        else this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile);actualProfile[0].is_staff = 1; return {actualProfile}; });
    }

    nameSectionWasEdited = (actualProfile) => {
        if((typeof(actualProfile.prefix_id) === "undefined" || actualProfile.prefix_id == 0)
        && (typeof(actualProfile.suffix_id) === "undefined" || actualProfile.suffix_id == 0)
        && (typeof(actualProfile.first_name) === "undefined" || actualProfile.first_name == "")
        && (typeof(actualProfile.middle_name) === "undefined" || actualProfile.middle_name == "")
        && (typeof(actualProfile.last_name) === "undefined" || actualProfile.last_name == "")
        && (typeof(actualProfile.nick_name) === "undefined" || actualProfile.nick_name == "")){
            return false;
        }
        else {
            return true;
        }
    }

    contactSectionWasEdited = (emails, phones, websites, socnets, msgapps) => {
        var wasEdited = false;

        for(let oneRow of emails){ if(oneRow.email != "")wasEdited = true; }
        for(let oneRow of phones){ if(oneRow.phone != "")wasEdited = true; }
        for(let oneRow of websites){ if(oneRow.website != "")wasEdited = true; }
        for(let oneRow of socnets){ if(oneRow.socnet != "")wasEdited = true; }
        for(let oneRow of msgapps){ if(oneRow.msgapp != "")wasEdited = true; }

        return wasEdited;
    }

    locationSectionWasEdited = (actualProfile, fullAddress) => {
        if((typeof(actualProfile.country_id) === "undefined" || actualProfile.country_id == 0)
        && (typeof(actualProfile.region_id) === "undefined" || actualProfile.region_id == 0)
        && (typeof(actualProfile.city) === "undefined" || actualProfile.city == "")
        && (typeof(fullAddress) === "undefined" || fullAddress == "")){
            return false;
        }
        else {
            return true;
        }
    }

    personalDetailsSectionWasEdited = (actualProfile, fullAddress) => {
        if((typeof(actualProfile.gender_id) === "undefined" || actualProfile.gender_id == 0)
        && (typeof(actualProfile.birth_dt) === "undefined" || actualProfile.birth_dt == "")){
            return false;
        }
        else {
            return true;
        }
    }

    /* Methods for array of emails */

    updateOneEmail = (event , index) => {
        let profileEmails = this.state.actualProfileEmails;
        for(var i = 0; i < this.state.actualProfileEmails.length; i++) {
            if(i == index)profileEmails[i].email = event.target.value;
        }
        this.setState({ actualProfileEmails: profileEmails });
    }

    updateOneEmailType = (event , index) => {
        let profileEmails = this.state.actualProfileEmails;
        for(var i = 0; i < this.state.actualProfileEmails.length; i++) {
            if(i == index)profileEmails[i].email_tp_id = event.target.value;
        }
        this.setState({ actualProfileEmails: profileEmails });
    }

    updateOneEmailPrimary = (index) => {
        let profileEmails = this.state.actualProfileEmails;
        for(var i = 0; i < this.state.actualProfileEmails.length; i++) {
            if(i == index)profileEmails[i].is_primary = 1;
            else profileEmails[i].is_primary = 0;
        }
        this.setState({ actualProfileEmails: profileEmails });
    }

    getPrimaryEmail = () => {
        let primaryEmail = "";
        for(var i = 0; i < this.state.actualProfileEmails.length; i++) {
            if(this.state.actualProfileEmails[i].is_primary == 1){
                primaryEmail = this.state.actualProfileEmails[i].email;
            }
        }
        return primaryEmail;
    }

    /* Methods for array of phones */

    updateOnePhone = (event , index) => {
        let profilePhones = this.state.actualProfilePhones;
        for(var i = 0; i < this.state.actualProfilePhones.length; i++) {
            if(i == index)profilePhones[i].phone = event.target.value;
        }
        this.setState({ actualProfilePhones: profilePhones });
    }

    updateOnePhoneType = (event , index) => {
        let profilePhones = this.state.actualProfilePhones;
        for(var i = 0; i < this.state.actualProfilePhones.length; i++) {
            if(i == index)profilePhones[i].phone_tp_id = event.target.value;
        }
        this.setState({ actualProfilePhones: profilePhones });
    }

    updateOnePhonePrimary = (index) => {
        let profilePhones = this.state.actualProfilePhones;
        for(var i = 0; i < this.state.actualProfilePhones.length; i++) {
            if(i == index)profilePhones[i].is_primary = 1;
            else profilePhones[i].is_primary = 0;
        }
        this.setState({ actualProfilePhones: profilePhones });
    }

    getPrimaryPhone = () => {
        let primaryPhone = "";
        for(var i = 0; i < this.state.actualProfilePhones.length; i++) {
            if(this.state.actualProfilePhones[i].is_primary == 1){
                primaryPhone = this.state.actualProfilePhones[i].phone;
            }
        }
        return primaryPhone;
    }

    /* Methods for array of phones */

    updateOneWebsite = (event , index) => {
        let profileWebsites = this.state.actualProfileWebsites;
        for(var i = 0; i < this.state.actualProfileWebsites.length; i++) {
            if(i == index)profileWebsites[i].website = event.target.value;
        }
        this.setState({ actualProfileWebsites: profileWebsites });
    }

    updateOneWebsiteType = (event , index) => {
        let profileWebsites = this.state.actualProfileWebsites;
        for(var i = 0; i < this.state.actualProfileWebsites.length; i++) {
            if(i == index)profileWebsites[i].website_tp_id = event.target.value;
        }
        this.setState({ actualProfileWebsites: profileWebsites });
    }

    /* Methods for array of socnets */

    updateOneSocnet = (event , index) => {
        let profileSocnets = this.state.actualProfileSocnets;
        for(var i = 0; i < this.state.actualProfileSocnets.length; i++) {
            if(i == index)profileSocnets[i].socnet = event.target.value;
        }
        this.setState({ actualProfileSocnets: profileSocnets });
    }

    updateOneSocnetType = (event , index) => {
        let profileSocnets = this.state.actualProfileSocnets;
        for(var i = 0; i < this.state.actualProfileSocnets.length; i++) {
            if(i == index)profileSocnets[i].platform_id = event.target.value;
        }
        this.setState({ actualProfileSocnets: profileSocnets });
    }

    /* Methods for array of msg apps */

    updateOneMsgApp = (event , index) => {
        let profileMsgApp = this.state.actualProfileMsgApps;
        for(var i = 0; i < this.state.actualProfileMsgApps.length; i++) {
            if(i == index)profileMsgApp[i].msgapp = event.target.value;
        }
        this.setState({ actualProfileMsgApps: profileMsgApp });
    }

    updateOneMsgAppType = (event , index) => {
        let profileMsgApp = this.state.actualProfileMsgApps;
        for(var i = 0; i < this.state.actualProfileMsgApps.length; i++) {
            if(i == index)profileMsgApp[i].msgapp_tp_id = event.target.value;
        }
        this.setState({ actualProfileMsgApps: profileMsgApp });
    }

    updateOneMsgAppPrimary = (index) => {
        let profileMsgApp = this.state.actualProfileMsgApps;
        for(var i = 0; i < this.state.actualProfileMsgApps.length; i++) {
            if(i == index)profileMsgApp[i].is_primary = 1;
            else profileMsgApp[i].is_primary = 0;
        }
        this.setState({ actualProfileMsgApps: profileMsgApp });
    }

    getPrimaryMsgApp = () => {
        let primaryMsgApp = "";
        for(var i = 0; i < this.state.actualProfileMsgApps.length; i++) {
            if(this.state.actualProfileMsgApps[i].is_primary == 1){
                primaryMsgApp = this.state.actualProfileMsgApps[i].msgapp;
            }
        }
        return primaryMsgApp;
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "AddRelationship"){
            await this.setState({ prepareToShowModalAddRelationship: true });
            this.setState({ showModalAddRelationship: true });
        }
    }

    closeModal = async (modalWindow) => {
        if(modalWindow == "AddRelationship"){
            this.setState({ showModalAddRelationship: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalAddRelationship: false });
        }
        this.loadData();
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }
        else if(this.state.updateVar != this.props.updateVar){
            this.setState({ updateVar: this.props.updateVar });
            this.loadData();
        }

        return (
            <div className="profileTile">
                <div className="flex" style={{flex: 1, height: 203, position: "relative"}}>

                    <div style={{width: "calc(100% + 40px)", backgroundColor: "var(--full-black)", height: "calc(50% + 20px)", position: "absolute", zIndex: 0, marginLeft: "-20px", marginTop: "-20px", borderRadius: "20px 20px 0px 0px"}}>
                        <img style={{width: "100%", height: "100%", borderRadius: "25px 25px 0px 0px", objectFit: "cover", opacity: 1}} src={background}/>
                    </div>

                    <div className="horizontalStack" style={{height: "100%"}}>

                        <div className="verticalStack" style={{zIndex: "1"}}>

                            { this.state.isMyContact == false ? <div className="horizontalStackCenter universalPopupContainer">
                                <div className="universalPopupVisible">
                                    <div className="circleButtonContainer circleButtonContainerGray clickable" onClick={this.addToMyContacts}>
                                        <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                                    </div>
                                </div>

                                <div className="universalPopupHidden">
                                    <PopupButtonDescription title={"Add to My Contacts"} blackArrow={false} />
                                </div>
                            </div> :
                            <div className="horizontalStackCenter universalPopupContainer">
                                <div className="universalPopupVisible">
                                    <div className="circleButtonContainer circleButtonContainerGray clickable" onClick={this.addToMyContacts}>
                                        <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_done_white}/>
                                    </div>
                                </div>

                                <div className="universalPopupHidden">
                                    <PopupButtonDescription title={"Remove from My Contacts"} blackArrow={false} />
                                </div>
                            </div> }

                            <div className="flex"></div>

                            <div className="horizontalStackCenter universalPopupContainer">
                                <div className="universalPopupVisible">
                                    <ExpandButton isOpen={this.state.toolOpened} style={{marginTop: 5}} onClick={this.openCloseTool} />
                                </div>

                                <div className="universalPopupHidden">
                                    <PopupButtonDescription title={"Add and Edit Contact Info"} blackArrow={true} />
                                </div>
                            </div>

                        </div>

                        <div className="verticalStack" style={{zIndex: "0", marginLeft: "-10px", marginRight: "23px"}}>
                            <div className="flex"></div>

                            <ProfileImage
                                initials={getInitials(this.state.actualProfile[0].first_name+" "+this.state.actualProfile[0].last_name)}
                                uploadedImageBase64={this.state.uploadedImageBase64}
                                toolOpened={this.state.toolOpened}
                                uploadImageClick={this.uploadImageClick}
                                deleteImageClick={this.deleteProfileImage}
                                contactType={"person"}
                            />

                            <input type="file"
                                ref={this.hiddenFileInput}
                                style={{display:'none'}}
                                accept={"image/png, image/jpg, image/jpeg"}
                                onChange={(event) => this.imageIsSelected(event)}
                            />

                            <div className="flex"></div>
                        </div>

                        <div className="verticalStack flex" style={{zIndex: "0", height: "calc(100% + 20px)"}}>
                            <div className="verticalStackCenter flex" style={{height: "100%", marginTop: "-20px"}}>
                                <div className="horizontalStack" style={{height: "100%"}}>
                                    <div className="verticalStackCenter" style={{flex: 4.5, minWidth: 50, height: "100%"}}>
                                        <div className="flex"></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsMedium40White responsiveThreeDots"><b>{this.state.actualProfile[0].first_name} {this.state.actualProfile[0].last_name}</b></span>
                                        </div>
                                        <div className="flex"></div>
                                    </div>
                                    <div style={{width: 20}}></div>
                                    <div className="verticalStackCenter clickable" style={{flex: 3, minWidth: 50}} onClick={() => this.props.openTile("roles")}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsSemiBold15Orange onHoverTextToWhite responsiveThreeDots"><b>{this.state.actualProfile[0].prim_role} {this.state.actualProfile[0].other_roles > 0 ? "+"+this.state.actualProfile[0].other_roles: ""}</b></span>
                                        </div>
                                        <div style={{height: "12px"}}></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13 responsiveThreeDots" style={{color: "var(--white)"}}>{this.state.actualProfile[0].rel_yrs} {this.state.actualProfile[0].rel_yrs != null ? "Years" : ""} {this.state.actualProfile[0].rel_start_dt} {this.state.actualProfile[0].rel_end_dt != null ? " to "+this.state.actualProfile[0].rel_end_dt : this.state.actualProfile[0].rel_start_dt != null ? "till now" : ""}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="verticalStackCenter flex">
                                <div className="horizontalStack" style={{height: "100%"}}>

                                    <div className="verticalStackCenter" style={{flex: 3, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            {/*<span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfileEmails[0].email == null || this.state.actualProfileEmails[0].email == "" ? "Email" : this.state.actualProfileEmails[0].email}</span>*/}
                                            {this.getPrimaryEmail() != "" ? <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.getPrimaryEmail()}</span> : null }
                                            {this.getPrimaryEmail() == "" ? <span className="fontPoppinsItalic13Gray responsiveThreeDots clickable onHoverToOrange" onClick={() => this.openSpecificPartOfProfileTile(true, false, false, true, false)}>{"+ Add Email"}</span> : null }
                                        </div>
                                        <div style={{height:18}}></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].city == null || this.state.actualProfile[0].city == "" ? "City" : this.state.actualProfile[0].city}{this.state.actualProfile[0].addr_country == null || this.state.actualProfile[0].addr_country == "" ? "" : ", "+this.state.actualProfile[0].addr_country}</span>
                                        </div>
                                    </div>

                                    <div style={{width: 20}}></div>

                                    <div className="verticalStackCenter" style={{flex: 1.5, tableLayout: "fixed", minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            {/*<span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].phone == null || this.state.actualProfile[0].phone == "" ? "Phone" : this.state.actualProfile[0].phone}</span>*/}
                                            {this.getPrimaryPhone() != "" ? <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.getPrimaryPhone()}</span> : null }
                                            {this.getPrimaryPhone() == "" ? <span className="fontPoppinsItalic13Gray responsiveThreeDots clickable onHoverToOrange" onClick={() => this.openSpecificPartOfProfileTile(true, false, false, true, false)}>{"+ Add Phone"}</span> : null }
                                        </div>
                                        <div style={{height:18}}></div>
                                        <div className="responsiveThreeDotsContainer">
                                            {this.state.actualProfile[0].region != null && this.state.actualProfile[0].region != "" ? <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].region}</span> : null }
                                            {this.state.actualProfile[0].region == null || this.state.actualProfile[0].region == "" ? <span className="fontPoppinsItalic13Gray responsiveThreeDots clickable onHoverToOrange" onClick={() => this.openSpecificPartOfProfileTile(true, false, true, false, false)}>{"+ Add Region"}</span> : null }
                                        </div>
                                    </div>

                                    <div style={{width: 20}}></div>

                                    <div className="verticalStackCenter" style={{flex: 1, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            {this.state.actualProfile[0].gender_id == 1 || this.state.actualProfile[0].gender_id == 2 ? <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].gender_id == 1 ? "Male": this.state.actualProfile[0].gender_id == 2 ? "Female" : "Gender"}</span> : null }
                                            {this.state.actualProfile[0].gender_id != 1 && this.state.actualProfile[0].gender_id != 2 ? <span className="fontPoppinsItalic13Gray responsiveThreeDots clickable onHoverToOrange" onClick={() => this.openSpecificPartOfProfileTile(true, false, false, false, true)}>{"+ Add Gender"}</span> : null }
                                        </div>
                                        <div style={{height:18}}></div>
                                        <div className="responsiveThreeDotsContainer">
                                            {this.state.actualProfile[0].age != null && this.state.actualProfile[0].age != "" ? <span className="fontPoppinsRegular13Gray responsiveThreeDots">{"Age: "}{this.state.actualProfile[0].age}</span> : null }
                                            {this.state.actualProfile[0].age == null || this.state.actualProfile[0].age == ""  ? <span className="fontPoppinsItalic13Gray responsiveThreeDots clickable onHoverToOrange" onClick={() => this.openSpecificPartOfProfileTile(true, false, false, false, true)}>{"+ Add Age"}</span> : null }
                                        </div>
                                    </div>

                                    <div style={{width: 20}}></div>

                                    <div className="verticalStackCenter">
                                        <div style={{width: 1, height: "50%", backgroundColor: "var(--light-purple-gray)", marginRight: 20}}></div>
                                    </div>

                                    <div className="verticalStackCenter" style={{flex: 2, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsSemiBold13Gray responsiveThreeDots">JV Staff Contact:</span>
                                        </div>
                                        <div style={{height:18}}></div>
                                        <div className="responsiveThreeDotsContainer">
                                            {this.state.actualProfile[0].reg_cont_nm != null ? <span onClick={() => {navigate("/PeopleProfile/"+this.state.actualProfile[0].reg_cont_id); window.location.reload();}} className="fontPoppinsSemiBold13DarkGray responsiveThreeDots clickable">{this.state.actualProfile[0].reg_cont_nm}</span> : null }
                                            {this.state.actualProfile[0].reg_cont_nm == null ? <span onClick={() => this.openModal("AddRelationship")} className="fontPoppinsItalic13Orange responsiveThreeDots clickable onHoverToBlue">+Add Relationship</span> : null }
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Editing data part */}

                <div className="flex" style={{flex: 1, position: "relative", display: this.state.toolOpened ? "inline" : "none"}}>

                    <div className="verticalStack" style={{marginTop: 20, marginBottom: 20}}>

                        {/* Name */}

                        <div className="horizontalStackCenter clickable" style={{height: 42, backgroundColor: "var(--lightest-gray)"}} onClick={ () => this.setState({ sectionNameOpened: !this.state.sectionNameOpened })}>
                            <img className="circleButton" style={{marginLeft: 12, width: "8px", height: "8px", transform: this.state.sectionNameOpened ? "rotate(90deg)" : ""}} src={icon_expand}/>
                            { this.nameSectionWasEdited(this.state.actualProfile[0]) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--blue-button-link)"}}>Edit Name*</span> : null }
                            { !this.nameSectionWasEdited(this.state.actualProfile[0]) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--jv-orange)", fontStyle: "italic"}}>+ Add Name*</span> : null }
                        </div>

                        <div className="horizontalStack" style={{display: this.state.sectionNameOpened ? "" : "none", width: "inherit", marginTop: 1, paddingTop: 10, paddingLeft: 12, paddingRight: 12, paddingBottom: 10, backgroundColor: "var(--lightest-gray)", alignItems: "flex-end"}}>

                            <div className="verticalStack">
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Title 1:</span>
                                <select className="profileTileInputText topMenuSelectBox" name="prefix" id="prefix" style={{width: 70}} value={this.state.actualProfile[0].prefix_id} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].prefix_id = e.target.value; return {actualProfile}; })}>
                                    <option value=""></option>
                                    {this.state.listOfPrefix.map(function (item) {
                                        return (
                                            <option value={item.id}>{item.label}</option>
                                        )
                                    }.bind(this))}
                                </select>
                            </div>

                            <div className="verticalStack" style={{flex: 1, marginLeft: 12}}>
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>First Name:*</span>
                                <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputFirstName" name="inputFirstName" value={this.state.actualProfile[0].first_name} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].first_name = e.target.value; return {actualProfile}; })}/>
                            </div>

                            <div className="verticalStack" style={{flex: 1, marginLeft: 12}}>
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Middle Name:</span>
                                <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputMiddleName" name="inputMiddleName" value={this.state.actualProfile[0].middle_name} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].middle_name = e.target.value; return {actualProfile}; })} />
                            </div>

                            <div className="verticalStack" style={{flex: 1, marginLeft: 12}}>
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Last Name:*</span>
                                <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputLastName" name="inputLastName" value={this.state.actualProfile[0].last_name} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].last_name = e.target.value; return {actualProfile}; })} />
                            </div>

                            <div className="verticalStack" style={{marginLeft: 12}}>
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Title 2:</span>
                                <select className="profileTileInputText topMenuSelectBox" name="suffix" id="suffix" style={{width: 70}} value={this.state.actualProfile[0].suffix_id} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].suffix_id = e.target.value; return {actualProfile}; })}>
                                    <option value=""></option>
                                    {this.state.listOfSuffix.map(function (item) {
                                        return (
                                            <option value={item.id}>{item.label}</option>
                                        )
                                    }.bind(this))}
                                </select>
                            </div>


                            <div className="verticalStack" style={{flex: 1, marginLeft: 12}}>
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Nickname:</span>
                                <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputNickname" name="ínputNickname" value={this.state.actualProfile[0].nick_name} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].nick_name = e.target.value; return {actualProfile}; })} />
                            </div>
                        </div>

                        {/* Location */}

                        <div className="horizontalStackCenter clickable" style={{marginTop: 1, height: 42, backgroundColor: "var(--lightest-gray)"}} onClick={ () => this.setState({ sectionLocationOpened: !this.state.sectionLocationOpened })}>
                            <img className="circleButton" style={{marginLeft: 12, width: "8px", height: "8px", transform: this.state.sectionLocationOpened ? "rotate(90deg)" : ""}} src={icon_expand}/>
                            { this.locationSectionWasEdited(this.state.actualProfile[0],this.state.fullAddress) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--blue-button-link)"}}>Edit Location*</span> : null }
                            { !this.locationSectionWasEdited(this.state.actualProfile[0],this.state.fullAddress) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--jv-orange)", fontStyle: "italic"}}>+ Add Location*</span> : null }
                        </div>

                        <div className="verticalStack" style={{display: this.state.sectionLocationOpened ? "" : "none", width: "inherit", marginTop: 1, paddingTop: 10, paddingLeft: 12, paddingRight: 12, paddingBottom: 10, backgroundColor: "var(--lightest-gray)"}}>

                            <div className="horizontalStack" style={{alignItems: "flex-start"}}>
                                <div className="verticalStack" style={{flex: 1, marginLeft: 0}}>
                                    <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>City:*</span>
                                    <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputCity" name="inputCity" value={this.state.actualProfile[0].city} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].city = e.target.value; return {actualProfile}; })}/>
                                    <span className="fontPoppinsItalic13Gray" style={{marginTop: 10, marginBottom: 10}}>{"Type in the City that this person is living in or working in."}</span>
                                </div>

                                <div className="verticalStack" style={{flex: 1, marginLeft: 12}}>
                                    <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>JV Country Visibility:*</span>
                                    <select className="profileTileInputText topMenuSelectBox" name="country" id="country" value={this.state.actualProfile[0].country_id} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].country_id = e.target.value; this.changeCountryNameById(e.target.value); return {actualProfile}; })}>
                                        <option value=""></option>
                                        {this.state.listOfCountries.map(function (item) {
                                            return (
                                                <option value={item.id} selected={this.state.actualProfile[0].country_id == item.id ? "selected" : ""}>{item.label}</option>
                                            )
                                        }.bind(this))}
                                    </select>
                                    <span className="fontPoppinsItalic13Gray" style={{marginTop: 10, marginBottom: 10}}>{"Select the country teams who will be able to view this profile."}</span>
                                </div>

                                <div className="verticalStack" style={{flex: 1, marginLeft: 12}}>
                                    <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>JV Region:</span>
                                    <select className="profileTileInputText topMenuSelectBox" name="region" id="region" value={this.state.actualProfile[0].region_id} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].region_id = e.target.value; this.changeRegionNameById(e.target.value); return {actualProfile}; })}>
                                        <option value=""></option>
                                        {this.state.listOfRegions.map(function (item) {
                                            return (
                                                <option value={item.id}>{item.label}</option>
                                            )
                                        }.bind(this))}
                                    </select>
                                    <span className="fontPoppinsItalic13Gray" style={{marginTop: 10, marginBottom: 10}}>{"Select the JV Ministry Region that this person is living in or working in."}</span>
                                </div>
                            </div>

                            <div className="horizontalStack" style={{marginTop: 0, alignItems: "flex-end"}}>
                                <div className="verticalStack" style={{flex: 1, marginLeft: 0}}>
                                    <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Full Address:</span>
                                    <input className="profileTileInputText" type="text" style={{width: "100%"}} id="inputFullAddress" name="inputFullAddress" value={this.state.fullAddress} onChange={e => this.setState({ fullAddress: e.target.value })} />
                                </div>
                            </div>

                            <div className="horizontalStack" style={{marginTop: 10}}>
                                <span className="fontPoppinsRegular13Gray" style={{marginBottom: 10}}>{"Region: "+this.state.googleDataRegion}</span>
                                <div style={{width: 20}}></div>
                                <span className="fontPoppinsRegular13Gray" style={{marginBottom: 10}}>{"Google's address: "+this.state.googleDataFullAddress}</span>
                            </div>

                        </div>

                        {/* Contact details */}

                        <div className="horizontalStackCenter clickable" style={{marginTop: 1, height: 42, backgroundColor: "var(--lightest-gray)"}} onClick={ () => this.setState({ sectionContactDetailsOpened: !this.state.sectionContactDetailsOpened })}>
                            <img className="circleButton" style={{marginLeft: 12, width: "8px", height: "8px", transform: this.state.sectionContactDetailsOpened ? "rotate(90deg)" : ""}} src={icon_expand}/>
                            { this.contactSectionWasEdited(this.state.actualProfileEmails,this.state.actualProfilePhones,this.state.actualProfileWebsites,this.state.actualProfileSocnets,this.state.actualProfileMsgApps) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--blue-button-link)"}}>Edit Contact Details</span> : null }
                            { !this.contactSectionWasEdited(this.state.actualProfileEmails,this.state.actualProfilePhones,this.state.actualProfileWebsites,this.state.actualProfileSocnets,this.state.actualProfileMsgApps) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--jv-orange)", fontStyle: "italic"}}>+ Add Contact Details</span> : null }
                        </div>

                        <div className="horizontalStack" style={{display: this.state.sectionContactDetailsOpened ? "" : "none",width: "inherit", marginTop: 1, paddingTop: 10, paddingLeft: 12, paddingRight: 12, paddingBottom: 10, backgroundColor: "var(--lightest-gray)"}}>

                            <div className="flex">
                                <table style={{width: "100%"}}>
                                    <tr>
                                        <td className="flex">
                                            <span className="fontPoppinsRegular13">Email:</span>
                                            {/*<span className="fontPoppinsRegular13" style={{color: "var(--jv-orange)", fontStyle: "italic", marginLeft: 6}}>+ Add</span>*/}
                                        </td>
                                        <td>
                                            <span className="fontPoppinsRegular13">Type:</span>
                                        </td>
                                        <td style={{textAlign: "center"}}>
                                            <span className="fontPoppinsRegular13">Primary</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="horizontalStackCenter" style={{}}>
                                            <input className="profileTileInputText flex" type="text" id="searchInputEmail1" name="searchInputEmail1" autoComplete="something-random" value={this.state.actualProfileEmails[0].email} onChange={(e) => this.updateOneEmail(e,0)} />
                                        </td>
                                        <td>
                                            <select className="profileTileInputText topMenuSelectBox" style={{width: "100%"}} onChange={(e) => this.updateOneEmailType(e,0)}>
                                                <option value=""></option>
                                                {this.state.array_email_types.map(function (item) {
                                                    return (
                                                        <option value={item.id} selected={item.id == this.state.actualProfileEmails[0].email_tp_id ? "selected" : ""}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                        </td>
                                        <td>
                                            <div className="profileTileContactRadioContainer">
                                                <input className="profileTileContactRadioButton" type="radio" id="radioEmail" name="radioEmail" value="kk" checked={this.state.actualProfileEmails[0].is_primary == 1 ? "checked" : ""} onChange={(e) => this.updateOneEmailPrimary(0)} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="horizontalStackCenter" style={{}}>
                                            <input className="profileTileInputText flex" type="text" id="searchInputEmail2" name="searchInputEmail2" autoComplete="something-random" value={this.state.actualProfileEmails[1].email} onChange={(e) => this.updateOneEmail(e,1)}  />
                                        </td>
                                        <td>
                                            <select className="profileTileInputText topMenuSelectBox" style={{width: "100%"}} onChange={(e) => this.updateOneEmailType(e,1)}>
                                                <option value=""></option>
                                                {this.state.array_email_types.map(function (item) {
                                                    return (
                                                        <option value={item.id} selected={item.id == this.state.actualProfileEmails[1].email_tp_id ? "selected" : ""}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                        </td>
                                        <td>
                                            <div className="profileTileContactRadioContainer">
                                                <input className="profileTileContactRadioButton" type="radio" id="radioEmail" name="radioEmail" value="kk" checked={this.state.actualProfileEmails[1].is_primary == 1 ? "checked" : ""} onChange={(e) => this.updateOneEmailPrimary(1)} />
                                            </div>
                                        </td>
                                    </tr>
                                    {/*<tr>
                                        <td className="flex">
                                            <span className="fontPoppinsRegular13">Website:</span>
                                        </td>
                                        <td>
                                            <span className="fontPoppinsRegular13">Type:</span>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="horizontalStack">
                                            <input className="profileTileInputText flex" type="text" id="searchInputWebsite1" name="searchInputWebsite1" autoComplete="something-random" value={this.state.actualProfileWebsites[0].website} onChange={(e) => this.updateOneWebsite(e,0)}  />
                                        </td>
                                        <td>
                                            <select className="profileTileInputText topMenuSelectBox" style={{width: "100%"}} onChange={(e) => this.updateOneWebsiteType(e,0)}>
                                                <option value=""></option>
                                                {this.state.array_website_type.map(function (item) {
                                                    return (
                                                        <option value={item.id} selected={item.id == this.state.actualProfileWebsites[0].website_tp_id ? "selected" : ""}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="horizontalStack">
                                            <input className="profileTileInputText flex" type="text" id="searchInputWebsite2" name="searchInputWebsite2" autoComplete="something-random" value={this.state.actualProfileWebsites[1].website} onChange={(e) => this.updateOneWebsite(e,1)}  />
                                        </td>
                                        <td>
                                            <select className="profileTileInputText topMenuSelectBox" style={{width: "100%"}} onChange={(e) => this.updateOneWebsiteType(e,1)}>
                                                <option value=""></option>
                                                {this.state.array_website_type.map(function (item) {
                                                    return (
                                                        <option value={item.id} selected={item.id == this.state.actualProfileWebsites[1].website_tp_id ? "selected" : ""}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>*/}
                                    <tr>
                                        <td className="flex">
                                            <span className="fontPoppinsRegular13">Messaging App Name:</span>
                                            {/*<span className="fontPoppinsRegular13" style={{color: "var(--jv-orange)", fontStyle: "italic", marginLeft: 6}}>+ Add</span>*/}
                                        </td>
                                        <td>
                                            <span className="fontPoppinsRegular13">Type:</span>
                                        </td>
                                        <td style={{textAlign: "center"}}>
                                            <span className="fontPoppinsRegular13">Primary</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="horizontalStackCenter" style={{}}>
                                            <input className="profileTileInputText flex" type="text" id="searchInputMessenger1" name="searchInputMessenger1" autoComplete="something-random" value={this.state.actualProfileMsgApps[0].msgapp} onChange={(e) => this.updateOneMsgApp(e,0)} />
                                        </td>
                                        <td>
                                            <select className="profileTileInputText topMenuSelectBox" style={{width: "100%"}} onChange={(e) => this.updateOneMsgAppType(e,0)}>
                                                <option value=""></option>
                                                {this.state.array_msgapp_types.map(function (item) {
                                                    return (
                                                        <option value={item.id} selected={item.id == this.state.actualProfileMsgApps[0].msgapp_tp_id ? "selected" : ""}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                        </td>
                                        <td>
                                            <div className="profileTileContactRadioContainer">
                                                <input className="profileTileContactRadioButton" type="radio" id="radioMsgapp" name="radioMsgapp" value="kk" checked={this.state.actualProfileMsgApps[0].is_primary == 1 ? "checked" : ""} onChange={(e) => this.updateOneMsgAppPrimary(0)} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="horizontalStackCenter" style={{}}>
                                            <input className="profileTileInputText flex" type="text" id="searchInputMessenger2" name="searchInputMessenger2" autoComplete="something-random" value={this.state.actualProfileMsgApps[1].msgapp} onChange={(e) => this.updateOneMsgApp(e,1)}  />
                                        </td>
                                        <td>
                                            <select className="profileTileInputText topMenuSelectBox" style={{width: "100%"}} onChange={(e) => this.updateOneMsgAppType(e,1)}>
                                                <option value=""></option>
                                                {this.state.array_msgapp_types.map(function (item) {
                                                    return (
                                                        <option value={item.id} selected={item.id == this.state.actualProfileMsgApps[1].msgapp_tp_id ? "selected" : ""}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                        </td>
                                        <td>
                                            <div className="profileTileContactRadioContainer">
                                                <input className="profileTileContactRadioButton" type="radio" id="radioMsgapp" name="radioMsgapp" value="kk" checked={this.state.actualProfileMsgApps[1].is_primary == 1 ? "checked" : ""} onChange={(e) => this.updateOneMsgAppPrimary(1)} />
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <div className="verticalStack flex">
                                <table style={{width: "100%"}}>
                                    <tr>
                                        <td className="flex">
                                            <span className="fontPoppinsRegular13">Phone:</span>
                                            {/*<span className="fontPoppinsRegular13" style={{color: "var(--jv-orange)", fontStyle: "italic", marginLeft: 6}}>+ Add</span>*/}
                                        </td>
                                        <td>
                                            <span className="fontPoppinsRegular13">Location:</span>
                                        </td>
                                        <td style={{textAlign: "center"}}>
                                            <span className="fontPoppinsRegular13">Primary</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="horizontalStack">
                                            <input className="profileTileInputText flex" type="text" id="searchInputPhone1" name="searchInputPhone1" autoComplete="something-random" value={this.state.actualProfilePhones[0].phone} onChange={(e) => this.updateOnePhone(e,0)}  />
                                        </td>
                                        <td>
                                            <select className="profileTileInputText topMenuSelectBox" style={{width: "100%"}} onChange={(e) => this.updateOnePhoneType(e,0)}>
                                                <option value=""></option>
                                                {this.state.array_phone_locations.map(function (item) {
                                                    return (
                                                        <option value={item.id} selected={item.id == this.state.actualProfilePhones[0].phone_tp_id ? "selected" : ""}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                        </td>
                                        <td>
                                            <div className="profileTileContactRadioContainer">
                                                <input className="profileTileContactRadioButton" type="radio" id="radioPhone" name="radioPhone" value="kk" checked={this.state.actualProfilePhones[0].is_primary == 1 ? "checked" : ""} onChange={(e) => this.updateOnePhonePrimary(0)} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="horizontalStack">
                                            <input className="profileTileInputText flex" type="text" id="searchInputPhone2" name="searchInputPhone2" autoComplete="something-random" value={this.state.actualProfilePhones[1].phone} onChange={(e) => this.updateOnePhone(e,1)}  />
                                        </td>
                                        <td>
                                            <select className="profileTileInputText topMenuSelectBox" style={{width: "100%"}} onChange={(e) => this.updateOnePhoneType(e,1)}>
                                                <option value=""></option>
                                                {this.state.array_phone_locations.map(function (item) {
                                                    return (
                                                        <option value={item.id} selected={item.id == this.state.actualProfilePhones[1].phone_tp_id ? "selected" : ""}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                        </td>
                                        <td>
                                            <div className="profileTileContactRadioContainer">
                                                <input className="profileTileContactRadioButton" type="radio" id="radioPhone" name="radioPhone" value="kk" checked={this.state.actualProfilePhones[1].is_primary == 1 ? "checked" : ""} onChange={(e) => this.updateOnePhonePrimary(1)} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="flex">
                                            <span className="fontPoppinsRegular13">Social Media/Site URL:</span>
                                            {/*<span className="fontPoppinsRegular13" style={{color: "var(--jv-orange)", fontStyle: "italic", marginLeft: 6}}>+ Add</span>*/}
                                        </td>
                                        <td>
                                            <span className="fontPoppinsRegular13">Platform:</span>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="horizontalStack">
                                            <input className="profileTileInputText flex" type="text" id="searchInputSocnet1" name="searchInputSocnet1" autoComplete="something-random" value={this.state.actualProfileSocnets[0].socnet} onChange={(e) => this.updateOneSocnet(e,0)}  />
                                        </td>
                                        <td>
                                            <select className="profileTileInputText topMenuSelectBox" style={{width: "100%"}} onChange={(e) => this.updateOneSocnetType(e,0)}>
                                                <option value=""></option>
                                                {this.state.array_social_media_platform.map(function (item) {
                                                    return (
                                                        <option value={item.id} selected={item.id == this.state.actualProfileSocnets[0].platform_id ? "selected" : ""}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="horizontalStack">
                                            <input className="profileTileInputText flex" type="text" id="searchInputSocnet2" name="searchInputSocnet2" autoComplete="something-random" value={this.state.actualProfileSocnets[1].socnet} onChange={(e) => this.updateOneSocnet(e,1)}  />
                                        </td>
                                        <td>
                                            <select className="profileTileInputText topMenuSelectBox" style={{width: "100%"}} onChange={(e) => this.updateOneSocnetType(e,1)}>
                                                <option value=""></option>
                                                {this.state.array_social_media_platform.map(function (item) {
                                                    return (
                                                        <option value={item.id} selected={item.id == this.state.actualProfileSocnets[1].platform_id ? "selected" : ""}>{item.label}</option>
                                                    )
                                                }.bind(this))}
                                            </select>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        {/* Personal Details */}

                        <div className="horizontalStackCenter clickable" style={{marginTop: 1, height: 42, backgroundColor: "var(--lightest-gray)"}} onClick={ () => this.setState({ sectionPersonalDetailsOpened: !this.state.sectionPersonalDetailsOpened })}>
                            <img className="circleButton" style={{marginLeft: 12, width: "8px", height: "8px", transform: this.state.sectionPersonalDetailsOpened ? "rotate(90deg)" : ""}} src={icon_expand}/>
                            { this.personalDetailsSectionWasEdited(this.state.actualProfile[0]) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--blue-button-link)"}}>Edit Personal Details</span> : null }
                            { !this.personalDetailsSectionWasEdited(this.state.actualProfile[0]) ? <span className="fontPoppinsRegular13Gray" style={{marginLeft: 32, color: "var(--jv-orange)", fontStyle: "italic"}}>+ Add Personal Details</span> : null }
                        </div>

                        <div className="horizontalStack" style={{display: this.state.sectionPersonalDetailsOpened ? "" : "none", width: "inherit", marginTop: 1, paddingTop: 10, paddingLeft: 12, paddingRight: 12, paddingBottom: 10, backgroundColor: "var(--lightest-gray)", alignItems: "flex-end"}}>

                            <div className="verticalStack" style={{flex: 1, marginLeft: 0}}>
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Birth Day:</span>
                                <input ref={this.refBirthDay} className="modalDatePicker fontPoppinsRegular13" type="date" id="start_date" name="start_date" min="1900-01-01" max="9999-01-01" value={this.state.actualProfile[0].birth_dt} onFocus={() => this.refBirthDay.current.showPicker()} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].birth_dt = e.target.value; return {actualProfile}; })}/>
                            </div>

                            <div className="verticalStack" style={{marginLeft: 12}}>
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Gender:</span>
                                <select className="profileTileInputText topMenuSelectBox" name="gender" id="gender" style={{width: 70}} value={this.state.actualProfile[0].gender_id} onChange={e => this.setState(prevState => {let actualProfile = Object.assign({}, prevState.actualProfile); actualProfile[0].gender_id = e.target.value; return {actualProfile}; })}>
                                    <option value=""></option>
                                    <option value="1">Male</option>
                                    <option value="2">Female</option>
                                </select>
                            </div>

                            <div className="verticalStack" style={{flex: 1, marginLeft: 12}}>
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>JV Staff Member:</span>
                                <div className="horizontalStackCenter profileTileInputText">
                                    <input className="" type="checkbox" id="jv_staff" name="jv_staff" checked={this.state.actualProfile[0].is_staff == 1 ? "checked" : ""} onClick={() => this.selectUnselectIsStaff()} />
                                    <label className="fontPoppinsRegular13" for="jv_staff"> Full-Time Missionary</label>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="horizontalStack">
                        {!this.state.updateExisting ? <RoundButton title={"Save"} className={"roundButtonOrange"} onClick={this.createNewAction} whiteText={true} /> : null }
                        {this.state.updateExisting ? <RoundButton title={"Save"} className={"roundButtonOrange"} onClick={this.saveChangesAction} whiteText={true} /> : null }
                        {this.state.updateExisting ? <RoundButton title={"Cancel"} className={"roundButtonGray"} style={{marginLeft: 12}} onClick={() => this.openCloseTool()} whiteText={true} /> : null }
                        {!this.state.updateExisting ? <RoundButton title={"Cancel"} className={"roundButtonGray"} style={{marginLeft: 12}} onClick={() => navigate(-1)} whiteText={true} /> : null }
                        {this.state.updateExisting ? <div style={{border: "none", overflow: "hidden"}}>
                            <div className="horizontalStack" style={{position: "relative"}}>
                                <RoundButton title={"Delete"} className={"roundButtonGray"} style={{marginLeft: 12}} onClick={this.askToDelete} whiteText={true} />
                            </div>
                            <DeleteDialog showHide={this.state.showDeleteDialog} onDelete={this.deleteAction} deleteValue={""} onClose={this.cancelDeleting} text={"Are you sure want to delete this person?"} />
                        </div> : null }
                    </div>

                </div>

                {this.state.prepareToShowModalAddRelationship ? <RelationshipsModal showModal={this.state.showModalAddRelationship} closeModal={this.closeModal} openModal={this.openModal} contactId={this.state.mainContactId} contactName={this.state.actualProfile[0].first_name+" "+this.state.actualProfile[0].last_name} actionType={"AddRelationship"} relationshipId={-1} openAlertMessage={this.props.openAlertMessage} /> : null }

            </div>
        );
    }
}

const withLocation = Component => props => {
    const location = useLocation();

    return <Component {...props} location={location} />;
};

export default withLocation(ProfileTile);
