import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/components.css';
import { Link, Navigate, useLocation } from "react-router-dom";

// import assets
import btn_back_gray from '../assets/btn_back_gray.png';

class ReturnToSearchResultsButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
        }
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
    }

    render(){
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

        return(
            <div className="horizontalStackCenter onHoverToOrange clickable">
                <img src={btn_back_gray} style={{height: 14, marginRight: 7}} />
                {/* if the actual contact/event/something else is found in list of saved items then it means I probably came from the page where I saved it so it would show "Return to Search Results" */}
                {this.props.position != 0 ? <span className="fontPoppinsRegular13Gray" onClick={() => this.setState({ redirectTo: -1 })}>{"Return to Search Results"}</span> : null}
                {this.props.position == 0 ? <span className="fontPoppinsRegular13Gray" onClick={() => this.setState({ redirectTo: -1 })}>{"Return to Previous Page"}</span> : null}
            </div>
        );
	}
}

export default ReturnToSearchResultsButton;