import React, { useState, useEffect, useMemo } from "react";
import '../styles/other.css';
import '../styles/components.css';
import Axios from 'axios';
import { navigate } from "@reach/router";

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";

// assets
import map_marker from '../assets/map_marker.png';

const marker_icon = { url: map_marker, scaledSize: { width: 19.3, height: 30 } };

const MapWithAMarker = withScriptjs(withGoogleMap(props =>
    <GoogleMap
        zoom={props.mapZoom}
        center={props.mapCenter}
        options={{
            styles: require("../styles/maps_styles.json"),
            fullscreenControl: false,
            zoomControl: true,
            mapTypeControl: false,
            disableDefaultUI: true,
            keyboardShortcuts: false,
        }}>

        {props.markersList.map(function (item) {
            return (
                <Marker
                    id={item.id}
                    icon={props.activeMarker == item.id ? marker_icon : marker_icon} /* I can change icon on hover */
                    onMouseOver={() => props.setActiveMarker(item.id)}
                    onMouseOut={() => props.setActiveMarker(0)}
                    onClick={() => props.onClickMarker(item.id)}
                    position={{ lat: item.lat, lng: item.lng }}>

                    {props.activeMarker == item.id ?
                        <InfoWindow onCloseClick={null} options={{disableAutoPan: true}}>
                            <div className="verticalStack">
                            <span>{item.short_name}</span>
                            <span>{item.city}</span>
                            </div>
                        </InfoWindow> : null}
                </Marker>
            )
        }.bind(this))}

    </GoogleMap>
));

export default class MapComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeMarker: 0,
            /*markersListOld: ([{id: 1, lat: 49.026174, lng: 17.575363},
                {id: 2, lat: 50.026174, lng: 12.575363},
                {id: 5, lat: 49.826174, lng: 15.575363}]),*/
            markersList: ([{}]),
            mapZoom: 6.6, /* it needs to be little bit different than real number for CZ, so it would update */
            mapCenter: { lat: 49.7, lng: 15.6 }, /* it needs to be little bit different than real number for CZ, so it would update */
            countryId: -1,
        }
    }

    componentDidMount(){
        // get map points
        // today June 17th 2024 I realized I send to this API "cntry" for Czechia, but it returns markers for different countries (it probably takes the perm_cntry from the token) - so sending the "cntry" is probably useless but I am leaving it here for future generations :D
        Axios.get('/api/rpt/map/get?tp=4&cntry=61').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.setState({ markersList: response.data.result })
        });

        Axios.get('/api/perm_cntry/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            //alert(JSON.stringify(response.data.result[0].id, null, 4));
            var permissionCountryId = response.data.result[0].id;
            var permissionCountryName = response.data.result[0].label;
            //permissionCountryId = 178; // Poland
            //permissionCountryId = 203; // Slovakia
            //permissionCountryId = 61; // Czech Republic
            //permissionCountryId = 204; // Slovenia
            //permissionCountryId = 102; // Hungary
            //permissionCountryId = 4; // Albania
            //permissionCountryId = 57; // Croatia
            //permissionCountryId = 36; // Bulgaria
            //permissionCountryId = 71; // Estonia
            //permissionCountryId = 84; // Germany
            //permissionCountryId = 124; // Latvia
            //permissionCountryId = 147; // Moldova
            //permissionCountryId = 150; // Montenegro
            //permissionCountryId = 183; // Romania
            //permissionCountryId = 198; // Serbia
            //permissionCountryId = 234; // Ukraine

            this.setState({ countryId: permissionCountryId });
            this.setState({ mapCenter: this.getLatAndLongForCountry(permissionCountryId) });
            this.setState({ mapZoom: this.getZoomForCountry(permissionCountryId) });

            // save it to show change on Dashboard
            if(permissionCountryId == 1){ // if rights to ALL, show Czech republic (map itself has another same condition)
                this.props.saveCountry(61, "Czech Republic");
            }
            else {
                this.props.saveCountry(permissionCountryId, permissionCountryName);
            }
        });
    }

    setActiveMarker = (id) => {
        this.setState({ activeMarker: id });
    }

    onClickMarker = (id) => {
        navigate("/ChurchProfile/"+id);
        window.location.reload();
    }

    getZoomForCountry = (countryId) => {
        if(countryId == 61 || countryId == -1 || countryId == 0){ // if Czech republic, or not loaded or right to ALL then show czech republic
            return 6.7;
        }
        else if(countryId == 203){ // Slovakia
            return 7;
        }
        else if(countryId == 178){ // Poland
            return 5.55;
        }
        else if(countryId == 204){ // Slovenia
            return 7.7;
        }
        else if(countryId == 102){ // Hungary
            return 6.7;
        }
        else if(countryId == 4){ // Albania
            return 6.7;
        }
        else if(countryId == 57){ // Croatia
            return 6.3;
        }
        else if(countryId == 36){ // Bulgaria
            return 6.7;
        }
        else if(countryId == 71){ // Estonia
            return 6.59;
        }
        else if(countryId == 84){ // Germany
            return 5.1;
        }
        else if(countryId == 124){ // Latvia
            return 6.59;
        }
        else if(countryId == 147){ // Moldova
            return 6.59;
        }
        else if(countryId == 150){ // Montenegro
            return 7.51;
        }
        else if(countryId == 183){ // Romania
            return 6;
        }
        else if(countryId == 198){ // Serbia
            return 6.1;
        }
        else if(countryId == 234){ // Ukraine
            return 5.1;
        }
        return 6.7;
    }

    getLatAndLongForCountry = (countryId) => {
        if(countryId == 61 || countryId == -1 || countryId == 0){
            // if czech republic, or not loaded or right to ALL then show czech republic
            return { lat: 49.8, lng: 15.5 };
        }
        else if(countryId == 203){ // Slovakia
            return { lat: 48.6, lng: 19.7 };
        }
        else if(countryId == 178){ // Poland
            return { lat: 51.95, lng: 19 };
        }
        else if(countryId == 204){ // Slovenia
            return { lat: 46.15, lng: 15 };
        }
        else if(countryId == 102){ // Hungary
            return { lat: 47.2, lng: 19.4 };
        }
        else if(countryId == 4){ // Albania
            return { lat: 41.15, lng: 20.10 };
        }
        else if(countryId == 57){ // Croatia
            return { lat: 44.5, lng: 16.5 };
        }
        else if(countryId == 36){ // Bulgaria
            return { lat: 42.766, lng: 25.24 };
        }
        else if(countryId == 71){ // Estonia
            return { lat: 58.6, lng: 25 };
        }
        else if(countryId == 84){ // Germany
            return { lat: 51, lng: 10.447 };
        }
        else if(countryId == 124){ // Latvia
            return { lat: 56.85, lng: 24.7 };
        }
        else if(countryId == 147){ // Moldova
            return { lat: 47.01, lng: 28.2 };
        }
        else if(countryId == 150){ // Montenegro
            return { lat: 42.7, lng: 19.3 };
        }
        else if(countryId == 183){ // Romania
            return { lat: 46, lng: 25 };
        }
        else if(countryId == 198){ // Serbia
            return { lat: 44, lng: 20.5 };
        }
        else if(countryId == 234){ // Ukraine
            return { lat: 48.3, lng: 31 };
        }
        return { lat: 49.8, lng: 15.5 };
    }

    render() {
        return (
            <MapWithAMarker
                // old not working key: AIzaSyCzFI1LkEV1yumNDTBf6_uBn3MnmgVFqyM
                // new key: AIzaSyChwycdOnD-KKfxHzaedHu3hw06MrmZcE8
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.25&libraries=geometry,drawing,places&key=AIzaSyChwycdOnD-KKfxHzaedHu3hw06MrmZcE8"
                loadingElement={<div style={{ height: "100%" }} />}
                activeMarker={this.state.activeMarker}
                setActiveMarker={this.setActiveMarker}
                onClickMarker={this.onClickMarker}
                markersList={this.state.markersList}
                mapZoom={this.state.mapZoom}
                mapCenter={this.state.mapCenter}
                containerElement={<div style={{ height: "330px", width: "100%" }} />}
                mapElement={<div style={{ height: "100%" }} />}
            />
        );
    }
}